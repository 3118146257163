import { AddressStore } from '../types/Address';
import {
  GraphqlAddress,
  GraphqlAdmin,
  GraphqlDimensions,
  GraphqlItem,
  GraphqlItemDescription,
  GraphqlItemOptions,
  GraphqlPackage,
  GraphqlPackageItemDescription,
  GraphqlPaymentMethod,
  GraphqlRegularUser,
  GraphqlShippingRate,
  GraphqlUser,
} from '../types/Graphql';
import {
  AdminStore,
  RegularUserStore,
  UserableType,
  UserStore,
} from '../types/User';
import { ItemStore } from '../types/Item';
import { PackageStore } from '../types/Package';
import { LithiumBattery } from '../graphql/API';

type MapGraphqlRegularUserToStore = (u: GraphqlRegularUser) => RegularUserStore;

export const mapGraphqlRegularUserToStore: MapGraphqlRegularUserToStore = (
  u
) => ({
  id: u.id,
    type: 'RegularUser',
  locker: u.locker,
  addresses: u.addresses
    ? u.addresses.map((address) => mapGraphqlAddressToStore(address))
    : [],
  phone: u.phone,
  unPackOnArrival: u.unPackOnArrival,
  credit: u.credit,
  items: u.items ? u.items.map((item) => mapGraphqlItemToStore(item)) : [],
  packages: u.packages
    ? u.packages.map((p) => mapGraphqlPackageToStore(p))
    : [],
  user: u.user ? mapGraphqlUserToStore(u.user) : undefined,
});

type MapGraphqlAddressToStore = (a: GraphqlAddress) => AddressStore;

export const mapGraphqlAddressToStore: MapGraphqlAddressToStore = (a) => ({
  id: a.id,
  firstName: a.firstName,
  lastName: a.lastName,
  addressLine1: a.addressLine1,
  addressLine2: a.addressLine2,
  city: a.city,
  state: a.state,
  zipCode: a.zipCode,
  countryCode: a.countryCode,
  phone: a.phone,
  email: a.email,
});

type MapGraphqlAdminToStore = (u: GraphqlAdmin) => AdminStore;

export const mapGraphqlAdminToStore: MapGraphqlAdminToStore = (u) => ({
  id: u.id,
    type: 'Admin',
  adminPermissions: u.adminPermissions,
  userId: u.userId,
  user: u.user ? mapGraphqlUserToStore(u.user) : undefined,
});

type MapGraphqlUserToStore = (u?: GraphqlUser) => UserStore;

export const mapGraphqlUserToStore: MapGraphqlUserToStore = (u) => ({
  id: u?.id || '',
    type: 'User',

  firstName: u?.firstName || '',
  lastName: u?.lastName || '',
  userName: u?.userName || '',
  email: u?.email || '',
  userSub: u?.userSub || '',
  avatarUrl: u?.avatarUrl || '',
  countryCode: u?.countryCode || '',
  languageCode: u?.languageCode || '',
  userableId: u?.userableId || '',
  userableType: u?.userableType || UserableType.RegularUser,
  disabled: u?.disabled || false,
  regularUser: u?.regularUser
    ? mapGraphqlRegularUserToStore(u?.regularUser)
    : undefined,
  admin: u?.admin ? mapGraphqlAdminToStore(u?.admin) : undefined,
});

type MapGraphqlItemToStore = (i: GraphqlItem) => ItemStore;

export const mapGraphqlItemToStore: MapGraphqlItemToStore = (i) => ({
  id: i.id,
    type: 'Item',
  userSub: i.userSub,
  status: i.status,
  locker: i.locker,
  barcode: i.barcode,
  sender: i.sender,
  carrier: i.carrier,
  weight: i.weight,
  dimensions: i.dimensions ? mapDimensions(i.dimensions) : undefined,
  imageUrls: i.imageUrls,
  description: i.description
    ? i.description.map((d) => mapItemDescription(d))
    : [],
  arrivedOn: i.arrivedOn,
  restriction: i.restriction,
  options: i.options ? mapGraphqlItemOptions(i.options) : undefined,
  note: i.note || '',
  packageId: i.packageId || '',
  createdAt: i.createdAt || '',
  userId: i.userId,
  user: i.user ? mapGraphqlUserToStore(i.user) : undefined,
  package: i.package ? mapGraphqlPackageToStore(i.package) : undefined,
  lithiumBattery: i.lithiumBattery ? i.lithiumBattery : LithiumBattery.NO,
});

const mapDimensions = (d: GraphqlDimensions) => ({
  length: d.length,
  width: d.width,
  height: d.height,
});

const mapItemDescription = (d: GraphqlItemDescription) => ({
  description: d.description,
  price: d.price,
  quantity: d.quantity,
});

const mapGraphqlItemOptions = (o: GraphqlItemOptions) => ({
  removeShoeBox: o.removeShoeBox,
  bubbleWrap: o.bubbleWrap,
});

type MapGraphqlPackageToStore = (p: GraphqlPackage) => PackageStore;

export const mapGraphqlPackageToStore: MapGraphqlPackageToStore = (p) => ({
  id: p.id,
  type: 'Package',
  status: p.status,
  options: p.options,
  requestedOn: p.requestedOn || '',
  packedOn: p.packedOn || '',
  dimension: p.dimension ? mapDimensions(p.dimension) : undefined,
  weight: p.weight || 0,
  imageUrls: p.imageUrls || [],
  shippingMethod: p.shippingMethod
    ? mapGraphqlShippingMethodToStore(p.shippingMethod)
    : undefined,
  address: p.address ? mapGraphqlAddressToStore(p.address) : undefined,
  trackingNumber: p.trackingNumber || '',
  payment: p.payment ? mapPaymentMethod(p.payment) : undefined,
  locker: p.locker || '',
  userId: p.userId || '',
  items: p.items
    ? p.items.items?.map((item) => mapGraphqlItemToStore(item))
    : [],
  user: p.user ? mapGraphqlUserToStore(p.user) : undefined,
  description: p.description
    ? p.description.map((d) => mapPackageItemDescription(d))
    : [],
});

const mapPackageItemDescription = (d: GraphqlPackageItemDescription) => ({
  description: d.description,
  price: d.price,
  quantity: d.quantity,
  sender: d.sender,
  barcode: d.barcode,
});

type MapGraphqlShippingMethodToStore = (
  rate: GraphqlShippingRate
) => GraphqlShippingRate;

const mapGraphqlShippingMethodToStore: MapGraphqlShippingMethodToStore = (
  rate
) => {
  return {
    rate: rate.rate,
    info: {
      carrier: rate.info?.carrier,
      deliveryTime: rate.info?.deliveryTime,
      dimensionalWeight: rate.info?.dimensionalWeight,
      frequencyOfDeparture: rate.info?.frequencyOfDeparture,
      maxSize: rate.info?.maxSize,
      maxWeight: rate.info?.maxWeight,
      name: rate.info?.name,
      tracking: rate.info?.tracking,
    },
  };
};

const mapPaymentMethod = (p: GraphqlPaymentMethod) => ({
  id: p.id,
  name: p.name,
  information: p.information,
  userId: p.userId,
});