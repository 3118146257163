import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';

type AlertProps = {
  type: 'info' | 'warning' | 'success' | 'error';
  show: boolean;
  setShow: (show: boolean) => void;
  children: React.ReactNode;
  timeout: number;
};

const Alert: React.FC<AlertProps> = ({
  type,
  show,
  setShow,
  timeout,
  children,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    let timeId: NodeJS.Timeout | undefined;
    if (show) {
      timeId = setTimeout(() => {
        setShow(false);
      }, timeout);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [show]);

  let color;

  switch (type) {
    case 'info':
      color = 'blue';
      break;
    case 'warning':
      color = 'yellow';
      break;
    case 'success':
      color = 'green';
      break;
    case 'error':
      color = 'red';
      break;
    default:
      color = 'blue';
  }

  return (
    <Transition
      show={show}
      enter="transform transition ease-out duration-700"
      enterFrom="opacity-0 transform-none"
      enterTo="opacity-100 transform-none"
      leave="transform transition ease-out duration-500"
      leaveFrom="opacity-100 transform-none"
      leaveTo="opacity-0 transform-none"
      className="fixed top-16 right-1 z-50 flex items-end px-4 py-6  sm:p-6 sm:items-start sm:justify-center"
    >
      <div
        id="alert-border-3"
        className={`drop-shadow-md flex p-4 mb-4 border-t-4 text-${color}-800 border-${color}-300 bg-${color}-50 dark:text-${color}-400 dark:bg-gray-800 dark:border-${color}-800`}
        role="alert"
      >
        <svg
          className={`flex-shrink-0 w-5 h-5 text-${color}-700 dark:text-${color}-400`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
        <div
          className={`ml-3 text-sm font-medium text-${color}-700 dark:text-${color}-400`}
        >
          {children}
        </div>
        <button
          type="button"
          className={`ml-auto -mx-1.5 -my-1.5 bg-${color}-50 text-${color}-500 rounded-lg focus:ring-2 focus:ring-${color}-400 p-1.5 hover:bg-${color}-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-${color}-300 dark:hover:bg-gray-700`}
          data-dismiss-target="#alert-border-3"
          aria-label="Close"
          onClick={() => setShow(false)}
        >
          <span className="sr-only">{t('Dismiss')}</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </Transition>
  );
};

export default Alert;
