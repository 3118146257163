import { Auth } from 'aws-amplify';

const signOut = async () => {
  let result = null;
  try {
    await Auth.signOut();
  } catch (error) {
    result = error;
  }
  return result;
};

export default signOut;
