import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import moment from 'moment';

import { ItemStatus, PackageStatus } from '../../library/graphql/API';
import updateItem from '../../library/services/Item/updateItem';
import createPackage from '../../library/services/package/createPackage';
import { AddressStore } from '../../library/types/Address';
import {
  GraphqlPackageItemDescription,
  GraphqlPackageOptions,
} from '../../library/types/Graphql';
import { ItemStore } from '../../library/types/Item';
import { PackingState } from '../../library/types/Package';
import { State } from '../../library/types/User';

const initialState: PackingState = {
  loading: 'idle',
  step: 0,
  package: {
    id: '',
    status: PackageStatus.Submitted,
    options: {
      removeBills: false,
      plasticBag: false,
      stretchWrap: false,
      doubleWallBox: false,
      priority: false,
    },
    requestedOn: '',
    packedOn: '',
    dimension: {
      width: 0,
      height: 0,
      length: 0,
    },
    weight: 0,
    imageUrls: [],
    shippingMethod: {
      rate: 0,
      info: {
        carrier: '',
        deliveryTime: '',
        dimensionalWeight: undefined,
        frequencyOfDeparture: '',
        insurable: undefined,
        insurance: '',
        maxSize: '',
        maxWeight: '',
        name: '',
        recommended: undefined,
        requiresDutiesInAdvance: undefined,
        tracking: undefined,
      },
    },
    address: {
      id: '',
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      countryCode: '',
      phone: '',
      email: '',
    },
    description: [],
    locker: '',
    userId: '',
    items: [],
  },
};

const packingSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    packageItems: (state, action: PayloadAction<ItemStore[]>) => {
      return {
        ...state,
        step: 1,
        package: {
          ...state.package,
          items: action.payload,
        },
      };
    },
    packageAddress: (state, action: PayloadAction<AddressStore>) => {
      return {
        ...state,
        package: {
          ...state.package,
          address: action.payload,
        },
      };
    },
    packageOptions: (
      state,
      action: PayloadAction<Partial<GraphqlPackageOptions>>
    ) => {
      return {
        ...state,
        package: {
          ...state.package,
          options: {
            ...state.package.options,
            ...action.payload,
          },
        },
      };
    },
    removeItem: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        package: {
          ...state.package,
          items: state?.package?.items?.filter(
            (item) => item.id !== action.payload
          ),
        },
      };
    },
    nextStep: (state) => {
      return {
        ...state,
        step: state.step + 1,
      };
    },
    previousStep: (state) => {
      return {
        ...state,
        step: state.step - 1,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(itemUpdate.pending, () => {});
    builder.addCase(itemUpdate.rejected, () => {});
    builder.addCase(
      itemUpdate.fulfilled,
      (state, action: PayloadAction<Partial<ItemStore>>) => {
        state.package.items = state?.package?.items?.map((item) => {
          if (item.id === action.payload.id) {
            return merge(item, action.payload);
          }
          return item;
        });
      }
    );
  },
});

export const itemUpdate = createAsyncThunk(
  'packing/itemUpdate',
  async (item: Partial<ItemStore>, { rejectWithValue }) => {
    try {
      await updateItem(item);
      return item;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const create = createAsyncThunk(
  'package/create',
  async (_, { rejectWithValue, getState }) => {
    const { packing } = getState() as { packing: PackingState };
    const { user } = getState() as { user: State };
    if (
      packing.package.items === undefined ||
      packing.package.items.length <= 0
    )
      return rejectWithValue('No items to pack');

    const packageDescription: GraphqlPackageItemDescription[] = [];
    packing.package.items?.forEach((item) => {
      if (item.description === undefined || item.description.length <= 0)
        return;
      item.description.forEach((description) => {
        packageDescription.push({
          description: description.description,
          quantity: description.quantity,
          price: description.price,
          sender: item.sender,
          barcode: item.barcode,
        });
      });
    });
    const currentPackage = {
      ...packing.package,
      userId: user.user.id,
      requestedOn: moment().format(),
      locker: user.user.regularUser?.locker || '',
      description: packageDescription.flat(),
    };
    try {
      const id = await createPackage(currentPackage);
      const updateItemPromises = packing.package.items.map((item) => {
        return updateItem({
          ...item,
          packageId: id,
          status: ItemStatus.HaveBeenPacked,
        });
      });
      await Promise.all(updateItemPromises);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default packingSlice.reducer;
export const {
  packageItems,
  packageAddress,
  packageOptions,
  removeItem,
  nextStep,
  previousStep,
} = packingSlice.actions;
