import { Auth } from 'aws-amplify';

import { AuthUser, UserStore } from '../../types/User';
import getUser from './getUser';

export type Result = {
  authUser: Partial<AuthUser> | null;
  user: Partial<UserStore> | null;
  error: string | undefined;
};

type Login = (email: string, password: string) => Promise<Result>;

const login: Login = async (email, password) => {
  const result: Result = {
    authUser: null,
    user: null,
    error: undefined,
  };

  try {
    const { attributes } = await Auth.signIn(email, password);
    result.authUser = {
      email: attributes.email,
      verified: attributes.email_verified,
    };

    if (attributes?.email) {
      result.user = await getUser(attributes.email);
    }
  } catch (err: any) {
    if (err.code === 'UserNotConfirmedException') {
      result.authUser = {
        email,
        verified: false,
      };
      result.user = {
        email,
        password,
      };
    } else {
      result.error = err.code;
    }
  }

  return result;
};

export default login;
