import { Disclosure } from '@headlessui/react';
import { GraphqlShippingRate } from '../../types/Graphql';

import ShippingMethodBody from './ShippingMethodBody';
import ShippingMethodHeader from './ShippingMethodHeader';

type ShippingMethodProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedRate: string | undefined;
  rate: GraphqlShippingRate;
  quote: boolean;
};

const ShippingMethod: React.FC<ShippingMethodProps> = ({
  rate,
  quote,
  handleChange,
  selectedRate,
}) => {
  return (
    <Disclosure as="div" className="mb-2 shadow-sm ">
      <div className="ui-open:rounded-t-lg ui-not-open:rounded-lg p-2 flex flex-row border text-gray-500 dark:text-gray-400 dark:border-gray-700">
        <Disclosure.Button
          as="div"
          className=" cursor-pointer flex flex-col md:flex-row items-center justify-between w-full  font-medium text-left"
        >
          <ShippingMethodHeader
            name={rate.info?.name || ''}
            carrier={rate.info?.carrier || ''}
            deliveryTime={rate.info?.deliveryTime || ''}
            price={rate.rate || 0}
          />
        </Disclosure.Button>
        {!quote && (
          <div className="flex items-center mb-1 mx-2">
            <label htmlFor={rate.info?.carrier}>
              <input
                type="radio"
                name="shipping-method"
                id={rate.info?.carrier}
                value={rate.info?.name?.toLocaleLowerCase()}
                checked={selectedRate === rate.info?.name?.toLocaleLowerCase()}
                onChange={handleChange}
                className=" text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </label>
          </div>
        )}
      </div>
      <Disclosure.Panel className="text-gray-500 rounded-b-lg border-t-0 border p-2 dark:border-gray-700">
        <ShippingMethodBody
          deliveryTime={rate.info?.deliveryTime || ''}
          maxWeight={rate.info?.maxWeight || ''}
          dimensionalWeight={rate.info?.dimensionalWeight || false}
          maxSize={rate.info?.maxSize || ''}
          tracking={rate.info?.tracking || false}
          frequencyOfDeparture={rate.info?.frequencyOfDeparture || ''}
        />
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default ShippingMethod;
