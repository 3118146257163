import { useTranslation } from 'react-i18next';

type Props = {
  handlePreview: (id: string) => void;
  image: string;
  id: string;
  handleAdd: (id: string) => void;
  selected: boolean;
};

const ItemCard: React.FC<Props> = ({
  handlePreview,
  handleAdd,
  image,
  id,
  selected,
}) => {
  const { t } = useTranslation();

  const onKeyPressHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handlePreview(id);
    }
  };

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleAdd(id);
  };

  return (
    <div
      onClick={() => handlePreview(id)}
      onKeyPress={onKeyPressHandler}
      role="button"
      tabIndex={0}
      className={`my-6 relative w-80 h-52 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 ${
        selected &&
        `dark:text-blue-500 border-blue-600 text-blue-600 dark:border-blue-600 border-2`
      }`}
    >
      <img
        className="rounded-lg w-full h-full object-cover p-1"
        src={image}
        alt="Item"
      />
      <button
        type="button"
        onClick={handleAddClick}
        className="absolute bottom-2 right-2 cursor-pointer inline-flex items-center px-3 py-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        {selected ? t('Remove') : t('Add')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="mx-1 w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d={
              selected
                ? 'M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
                : 'M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
            }
          />
        </svg>
      </button>
    </div>
  );
};

export default ItemCard;
