/* eslint-disable jsx-a11y/label-has-associated-control */

type PackageOptionCardProps = {
  id: string;
  src: string;
  price: string;
  title: string;
  description: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PackageOptionCard: React.FC<PackageOptionCardProps> = ({
  id,
  src,
  price,
  title,
  description,
  value,
  onChange,
}) => {
  return (
    <div className="mt-4">
      <input
        type="checkbox"
        id={id}
        value={id}
        className="hidden peer"
        checked={value}
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className="inline-flex justify-between items-center p-5 w-full text-gray-500 bg-white rounded-lg border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
      >
        <div className="flex flex-row w-full justify-between">
          <div>
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
              {`$${price}`}
            </span>
            <div className="w-full text-lg font-semibold">{title}</div>

            <div className="w-full">{description}</div>
          </div>
          <img
            src={src}
            alt={title}
            className="w-36 h-36 rounded-lg object-cover"
          />
        </div>
      </label>
    </div>
  );
};

export default PackageOptionCard;
