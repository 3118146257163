import { useState } from 'react';
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { packageAddress } from '../../app/features/packingSlice';
import AddressPreviewCardOption from './AddressPreviewCardOption';

type ModalProps = {
  onClose: () => void;
  show: boolean;
};

const AddressPreviewModal: React.FC<ModalProps> = ({ show, onClose }) => {
  const {
    user: { regularUser },
  } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const {t} = useTranslation();

  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);

  const addresses = regularUser?.addresses || [];

  const handleSelectAddress = (id: string) => {
    setSelectedAddress(id);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedAddress) return;

    const address = addresses.find((a) => a.id === selectedAddress);

    if (!address) return;

    dispatch(packageAddress(address));
    onClose();
  };

  if (addresses.length === 0) return null;

  return (
    <Modal size="4xl" show={show} onClose={onClose} position="center">
      <Modal.Header className="bg-white dark:bg-gray-800">
        {t('Select an address')}
      </Modal.Header>
      <form onSubmit={handleSave}>
        <Modal.Body className="h-96 overflow-y-auto bg-white dark:bg-gray-800">
          <div className="flex w-full flex-row flex-wrap justify-around md:items-start">
            {addresses.map((address) => (
              <AddressPreviewCardOption
                key={address.id}
                address={address}
                onSelect={handleSelectAddress}
                checked={selectedAddress === address.id}
              />
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer className="bg-white dark:bg-gray-800">
          <div className="flex w-full items-center justify-between">
            <button
              type="submit"
              className="inline-flex items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {t('Save')}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddressPreviewModal;
