/* eslint-disable consistent-return */
import { Auth } from 'aws-amplify';

import { User, UserableType, UserStore } from '../../types/User';
import createUser from './createUser';

import updateRegularUser from './updateRegularUser';

export const ERRORS: { [key: string]: Errors } = {
  UsernameExistsException: {
    email: 'An account with the given email already exists.',
  },
  InvalidPasswordException: {
    password: 'Password is invalid',
  },
  CodeMismatchException: {
    verificationCode: 'Invalid verification code provided, please try again.',
  },
};

export type SignupUser = {
  email: string | undefined;
  password: string | undefined;
};

export type Errors = {
  email?: string;
  password?: string;
  verificationCode?: string;
};

type Signup = (user: SignupUser) => Promise<Errors | string | undefined>;

export const signup: Signup = async (user) => {
  if (!user.email || !user.password) {
    return;
  }
  try {
    const { userSub } = await Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        email: user.email,
      },
    });
    return userSub;
  } catch (err: any) {
    return ERRORS[err.code as string];
  }
};

type VerifySignup = (
  user: UserStore,
  code: string
) => Promise<Errors | undefined>;

export const verifySignup: VerifySignup = async (user, code) => {
  if (!user.email || !user.password) {
    return;
  }
  try {
    await Auth.confirmSignUp(user.email, code);
    await Auth.signIn(user.email, user.password);
  } catch (err: any) {
    return ERRORS[err.code as string];
  }
};

export const resendConfirmationCode = async (user: UserStore) => {
  if (!user.email) {
    return;
  }

  try {
    await Auth.resendSignUp(user.email);
  } catch (err: any) {
    return ERRORS[err.code as string];
  }
};

export const updateEmail = async (user: User, email: string) => {
  if (!user.email || !email) {
    return;
  }

  try {
    await Auth.updateUserAttributes(user, { email });
  } catch (err: any) {
    return ERRORS[err.code as string];
  }
};

type CompleteSignup = (user: UserStore, userableId: string) => Promise<string>;

export const completeSignup: CompleteSignup = async (user, userableId) => {
  try {
    const regularUserId = await createUser(user, userableId);
    if (user.regularUser && user.userableType === UserableType.RegularUser) {
      const regularUser = {
        ...user.regularUser,
        userId: regularUserId,
      };
      await updateRegularUser(regularUser);
    }
    return regularUserId;
  } catch (err: any) {
    return err;
  }
};
