import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, Auth } from 'aws-amplify';

import { GraphqlShippingRate } from '../../types/Graphql';

const getQuoteQuery = /* GraphQL */ `
  query GetQuote($args: String) {
    getQuote(args: $args) {
      list {
        rate
        info {
          carrier
          deliveryTime
          dimensionalWeight
          frequencyOfDeparture
          maxSize
          maxWeight
          name
          tracking
        }
      }
    }
  }
`;

type GetQuoteResponse = {
  getQuote: {
    list: [GraphqlShippingRate];
  };
};

type MapGraphqlShippingRateToStore = (
  rate: GraphqlShippingRate
) => GraphqlShippingRate;

const mapGraphqlShippingRateToStore: MapGraphqlShippingRateToStore = (rate) => {
  return {
    rate: rate.rate,
    info: {
      carrier: rate.info?.carrier,
      deliveryTime: rate.info?.deliveryTime,
      dimensionalWeight: rate.info?.dimensionalWeight,
      frequencyOfDeparture: rate.info?.frequencyOfDeparture,
      maxSize: rate.info?.maxSize,
      maxWeight: rate.info?.maxWeight,
      name: rate.info?.name,
      tracking: rate.info?.tracking,
    },
  };
};

type GetQuote = (args: string) => Promise<GraphqlShippingRate[]>;

const getQuote: GetQuote = async (args) => {
  let isLoggedIn;
  try {
    isLoggedIn = await Auth.currentAuthenticatedUser();
  } catch (error) {
    isLoggedIn = false;
  }

  const res = (await API.graphql({
    query: getQuoteQuery,
    variables: { args },
    authMode: isLoggedIn ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
  })) as GraphQLResult<GetQuoteResponse>;

  return res.data?.getQuote?.list?.map(mapGraphqlShippingRateToStore) || [];
};

export default getQuote;
