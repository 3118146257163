import { Navigate, useLocation } from 'react-router-dom';

import { Status } from '../../library/types/User';
import { useAppSelector } from '../../app/hooks';
import SignupStep from './SignupStep';
import VerifySignupStep from './VerifySignupStep';
import { LocationType } from '../../library/utils/ReactRouter';

const getStep = (status: Status) => {
  switch (status) {
    case 'pending_signup':
    case 'signing_up': {
      return <SignupStep />;
    }
    case 'pending_verify':
    case 'verifying': {
      return <VerifySignupStep />;
    }
    default: {
      return null;
    }
  }
};

const SignupFlow: React.FC = () => {
  const { status, user } = useAppSelector((state) => state.user);
  const location = useLocation();
  const state = location.state as LocationType;

  if (user.isLoggedIn)
    return <Navigate to={{ pathname: state?.from?.pathname || '/' }} />;

  return <>{getStep(status)}</>;
};

export default SignupFlow;
