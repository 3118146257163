import { useTranslation } from 'react-i18next';
import { AddressStore } from '../../library/types/Address';

type PackageAddressProps = {
  address: AddressStore;
};

const PackageAddress: React.FC<PackageAddressProps> = ({ address }) => {
  const { t } = useTranslation();
  
  return (
    <div className="grid gap-4 mb-4 sm:grid-cols-3">
      <h5 className="text-lg tracking-tight font-extrabold text-gray-900 dark:text-white">
        {t('Delivering Address')}
      </h5>
      <div className="col-span-2 grid gap-4 mb-4 sm:grid-cols-2">
        <div>
          <p className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
            {t('Name')}
          </p>
          <p className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
            {t('Address')}
          </p>
          <p className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
            {t('City')}
          </p>
          <p className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
            {t('Postal Code')}
          </p>
          <p className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
            {t('Country')}
          </p>
          <p className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
            {t('Phone Number')}
          </p>
        </div>
        <div>
          <p className="font-light text-gray-500 dark:text-gray-400">{`${address.firstName} ${address.lastName}`}</p>
          <p className="font-light text-gray-500 dark:text-gray-400">
            {address.addressLine1}, {address.addressLine2}
          </p>
          <p className="font-light text-gray-500 dark:text-gray-400">
            {address.city}, {address.state}
          </p>
          <p className="font-light text-gray-500 dark:text-gray-400">
            {address.zipCode}
          </p>
          <p className="font-light text-gray-500 dark:text-gray-400">
            {address.countryCode}
          </p>
          <p className="font-light text-gray-500 dark:text-gray-400">
            {address.phone}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PackageAddress;
