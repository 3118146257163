import { useLocation, Navigate, Outlet } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { LOGIN_ROUTE } from '../../routes';

const RequireAuth = () => {
  const location = useLocation();
  const { authorized } = useAuth();

  return authorized ? (
    <Outlet />
  ) : (
    <Navigate
      to={{ pathname: `/${LOGIN_ROUTE}` }}
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
