type ShippingMethodDescriptionRowProps = {
  title: string;
  description: string;
};

const ShippingMethodDescriptionRow: React.FC<
  ShippingMethodDescriptionRowProps
> = ({ title, description }) => {
  return (
    <div className="mx-2 grid p-2 sm:grid-cols-2 sm:gap-6 border-b mb-2">
      <p className="tracking-tight font-bold text-gray-900 dark:text-white">
        {title}
      </p>

      <p className="font-light sm:justify-self-end text-gray-500 dark:text-gray-400">
        {description}
      </p>
    </div>
  );
};

export default ShippingMethodDescriptionRow;
