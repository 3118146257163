import { useTranslation } from 'react-i18next';
import { FieldErrorsImpl } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import { FormInputs } from './index';

type ContactFormProps = {
  handleSubmit: (callback: (data: any) => void) => (e: any) => void;
  onSubmit: (data: any) => void;
  register: UseFormRegister<FormInputs>;
  errors: FieldErrorsImpl<FormInputs>;
};

const ContactFom: React.FC<ContactFormProps> = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <form action="#" className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label
          htmlFor="email"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {t('Your email')}
          <input
            type="email"
            id="email"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light dark:focus:border-primary-500 dark:focus:ring-primary-500"
            placeholder="name@example.com"
            {...register('email')}
          />
          {errors.email && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.email?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>
      <div>
        <label
          htmlFor="subject"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {t('Subject')}
          <input
            type="text"
            id="subject"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light dark:focus:border-primary-500 dark:focus:ring-primary-500"
            placeholder="Let us know how we can help you"
            {...register('subject')}
          />
          {errors.subject && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.subject?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>
      <div className="sm:col-span-2">
        <label
          htmlFor="message"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
        >
          {t('Your message')}
          <textarea
            id="message"
            rows={6}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            placeholder="Leave a comment..."
            {...register('message')}
          />
          {errors.message && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.message?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>
      <button
        type="submit"
        className="rounded-lg bg-primary-700 py-3 px-5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:w-fit"
      >
        {t('Send message')}
      </button>
    </form>
  );
};

export default ContactFom;
