import { Auth } from 'aws-amplify';

type Result = {
  error: string | null;
};

export type ForgotPasswordSubmit = (
  email: string,
  code: string,
  password: string
) => Promise<Result>;

const forgotPasswordSubmit: ForgotPasswordSubmit = async (
  email,
  code,
  password
) => {
  const result: Result = {
    error: null,
  };

  try {
    await Auth.forgotPasswordSubmit(email, code, password);
  } catch (err: any) {
    if (err.code === 'UserNotFoundException') {
      result.error = 'The provided email does not match any account';
    }
    if (err.code === 'InvalidPasswordException') {
      result.error = 'Password is not long enough';
    }
    if (err.code === 'ExpiredCodeException') {
      result.error = 'Verification code is expired, please request another one';
    } else {
      result.error = err.message;
    }
  }

  return result;
};

export default forgotPasswordSubmit;
