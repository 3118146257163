/* eslint-disable react/style-prop-object */
import { Tabs } from 'flowbite-react';
import { Suspense, useEffect } from 'react';

import { load } from '../../app/features/packagesSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PackageStatus } from '../../library/graphql/API';
import RequestedPackageDetail from './RequestedPackageDetail';
import ShippedPackageDetails from './ShippedPackageDetails';
import PackedPackagesDetail from './PackedPackagesDetail';
import Loading from '../../library/components/Loading';

const Packages = () => {
  const { packages, loading } = useAppSelector((state) => state.packages);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadPackages = async () => {
      await dispatch(load());
    };
    loadPackages();
  }, []);

  const requestedPackages = packages.filter(
    (item) => item.status === PackageStatus.Submitted
  );

  const readyPackages = packages.filter(
    (item) => item.status === PackageStatus.Packed
  );

  const shippedPackages = packages.filter(
    (item) =>
      item.status === PackageStatus.Shipped ||
      item.status === PackageStatus.Paid
  );

  if (loading === 'failed') return <div>Failed to load items</div>;

  return (
    <section className="flex-grow bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-auto lg:py-8">
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0 xl:p-0">
          <Tabs.Group aria-label="Tabs with underline" style="underline">
            <Tabs.Item active title="Requested">
              <Suspense fallback={<Loading className="h-screen" />}>
                {requestedPackages.map((p) => {
                  if (!p.address || !p.options) return null;
                  return (
                    <RequestedPackageDetail
                      key={p.id}
                      address={p.address}
                      options={p.options}
                    />
                  );
                })}
              </Suspense>
            </Tabs.Item>
            <Tabs.Item title="Packed">
              <Suspense fallback={<Loading className="h-screen" />}>
                {readyPackages.map((p) => {
                  return <PackedPackagesDetail key={p.id} shipment={p} />;
                })}
              </Suspense>
            </Tabs.Item>
            <Tabs.Item title="Shipped">
              <Suspense fallback={<Loading className="h-screen" />}>
                {shippedPackages.map((p) => {
                  return <ShippedPackageDetails key={p.id} shipment={p} />;
                })}
              </Suspense>
            </Tabs.Item>
          </Tabs.Group>
        </div>
      </div>
    </section>
  );
};

export default Packages;
