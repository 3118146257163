import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import { UpdateItemInput, UpdateItemMutation } from '../../graphql/API';
import { ItemStore } from '../../types/Item';

export const updateItemQuery = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
    }
  }
`;

type MapItemToInput = (i: Partial<ItemStore>) => UpdateItemInput;

const mapItemToInput: MapItemToInput = (item) => ({
  id: item.id || '',
  status: item.status,
  locker: item.locker || '',
  barcode: item.barcode,
  sender: item.sender,
  carrier: item.carrier,
  weight: item.weight,
  dimensions: {
    width: item.dimensions?.width || 0,
    height: item.dimensions?.height || 0,
    length: item.dimensions?.length || 0,
  },
  imageUrls: item.imageUrls,
  description: item.description,
  restriction: item.restriction,
  options: item.options,
  note: item.note,
  userId: item.userId,
  packageId: item.packageId || undefined,
  lithiumBattery: item.lithiumBattery,
});

type UpdateItem = (i: Partial<ItemStore>) => Promise<string>;

const updateItem: UpdateItem = async (i: Partial<ItemStore>) => {
  const res = (await API.graphql(
    graphqlOperation(updateItemQuery, { input: mapItemToInput(i) })
  )) as GraphQLResult<UpdateItemMutation>;

  return res.data?.updateItem?.id || '';
};

export default updateItem;
