import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { cloneDeep } from 'lodash';

import AddressForm, {
  AddressInput,
} from '../../library/components/AddressForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateRegular } from '../../app/features/userSlice';
import { RegularUserStore } from '../../library/types/User';

const languageRegex = /^[^-\s0-9_][a-zA-Z\s]+[^-\s0-9_]+$/;

const schema = yup.object({
  firstName: yup
    .string()
    .matches(
      languageRegex,
      'This contains incorrect characters please, use English only'
    )
    .min(2)
    .required('First name is required'),
  lastName: yup
    .string()
    .matches(
      languageRegex,
      'This contains incorrect characters please, use English only'
    )
    .min(2)
    .required('Last name is required'),
  country: yup.string().required('Country is required'),
  addressLineOne: yup.string().required('Address 1 can not be blank'),
  addressLineTwo: yup.string(),
  city: yup.string().required('City can not be blank'),
  state: yup.string(),
  zipCode: yup
    .number()
    .typeError('Postal Code must be a number, use latin numbers only')
    .required(),
  phoneNumber: yup
    .number()
    .typeError('Phone Number must be a number, use latin numbers only')
    .min(5, 'Phone Number is too short (minimum is 5 characters)')
    .required('Phone Number can not be blank'),
  email: yup
    .string()
    .email('Must be a valid email address')
    .lowercase()
    .trim()
    .required('Email is required'),
});

const NewAddress: React.FC = () => {
  const { regularUser } = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { id: addressId } = useParams();
  const { t } = useTranslation();

  const currentAddress = regularUser?.addresses?.find(
    (address) => address.id === addressId
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressInput>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: currentAddress?.firstName,
      lastName: currentAddress?.lastName,
      addressLineOne: currentAddress?.addressLine1,
      addressLineTwo: currentAddress?.addressLine2 || '',
      city: currentAddress?.city,
      state: currentAddress?.state,
      zipCode: currentAddress?.zipCode,
      country: currentAddress?.countryCode,
      phoneNumber: currentAddress?.phone,
      email: currentAddress?.email,
    },
  });

  const onSubmit: SubmitHandler<AddressInput> = async (data) => {
    const user: RegularUserStore = cloneDeep(regularUser || {});
    const currentList = user.addresses || [];
    const address = {
      id: addressId || uuidv4(),
      firstName: data.firstName,
      lastName: data.lastName,
      addressLine1: data.addressLineOne,
      addressLine2: data.addressLineTwo,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      countryCode: data.country,
      phone: data.phoneNumber,
      email: data.email,
    };

    if (addressId) {
      const index = currentList.findIndex((a) => a.id === addressId);
      if (index !== undefined) {
        currentList.splice(index, 1, address);
        user.addresses = currentList;
      }
    } else {
      currentList.push(address);
      user.addresses = currentList;
    }

    await dispatch(updateRegular(user));
    navigate(-1);
  };

  return (
    <section className="flex-grow bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-auto lg:py-8">
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0 xl:p-0">
          <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              {addressId ? t('Edit Address') : t('Add New Address')}
            </h1>
            <AddressForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
              onCancel={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewAddress;
