import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import VerifySignupForm, {
  VerificationInputs,
} from '../../library/components/VerifySignupForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  Errors,
  resendConfirmationCode,
} from '../../library/services/User/signup';
import {
  createRegular,
  createUser,
  verifyUser,
} from '../../app/features/userSlice';
import Alert from '../../library/components/Alert';
import { ADDRESS_ROUTE } from '../../routes';

const schema = yup
  .object({
    code: yup
      .string()
      .max(6)
      .min(6)
      .required(
        'Code is required, please check your email address for the code'
      ),
  })
  .required();

const VerifySignupStep: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const state = useAppSelector(({ user }) => user);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<VerificationInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<VerificationInputs> = async (data) => {
    if (!data.code) {
      return;
    }

    const { payload }: PayloadAction<Errors | undefined | any> = await dispatch(
      verifyUser(data.code)
    );

    if (payload) {
      setError('code', { type: 'manual', message: payload.verificationCode });
    } else if (payload === undefined) {
      await dispatch(createRegular());
      await dispatch(createUser());
      navigate(`/${ADDRESS_ROUTE}`);
    }
  };

  const onResendVerificationCode = async () => {
    try {
      await resendConfirmationCode(state.user);
      setShowAlert(true);
    } catch (error: any) {
      // TODO: handle error
      throw new Error(error.message);
    }
  };

  return (
    <>
      <Alert
        type="success"
        show={showAlert}
        setShow={setShowAlert}
        timeout={3000}
      >
        {t('Verification code has been sent. Please check your email.')}
      </Alert>

      <VerifySignupForm
        handleSubmit={handleSubmit}
        onResendVerificationCode={onResendVerificationCode}
        onSubmit={onSubmit}
        register={register}
        errors={errors}
        state={state}
      />
    </>
  );
};

export default VerifySignupStep;
