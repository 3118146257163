/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  type: string,
  firstName: string,
  lastName: string,
  email: string,
  userSub: string,
  avatarUrl: string,
  countryCode: string,
  languageCode: string,
  userableId: string,
  userableType: UserableType,
  disabled: boolean,
  createdAt?: string | null,
  owner?: string | null,
};

export enum UserableType {
  Admin = "Admin",
  RegularUser = "RegularUser",
}


export type ModelUserConditionInput = {
  type?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  userSub?: ModelStringInput | null,
  avatarUrl?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  languageCode?: ModelStringInput | null,
  userableId?: ModelIDInput | null,
  userableType?: ModelUserableTypeInput | null,
  disabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelUserableTypeInput = {
  eq?: UserableType | null,
  ne?: UserableType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id: string,
  type: string,
  firstName: string,
  lastName: string,
  email: string,
  userSub: string,
  avatarUrl: string,
  countryCode: string,
  languageCode: string,
  userableId: string,
  userableType: UserableType,
  disabled: boolean,
  createdAt?: string | null,
  owner?: string | null,
  admin?: Admin | null,
  regularUser?: RegularUser | null,
  updatedAt: string,
};

export type Admin = {
  __typename: "Admin",
  id: string,
  type: string,
  adminPermissions?: AdminPermissions | null,
  userId?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  user?: User | null,
  updatedAt: string,
};

export enum AdminPermissions {
  SeniorAdmin = "SeniorAdmin",
  Admin = "Admin",
  Moderator = "Moderator",
}


export type RegularUser = {
  __typename: "RegularUser",
  id: string,
  type: string,
  locker: string,
  addresses?:  Array<Address | null > | null,
  phone?: string | null,
  unPackOnArrival: boolean,
  credit: number,
  userId?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  items?: ModelItemConnection | null,
  packages?: ModelPackageConnection | null,
  user?: User | null,
  updatedAt: string,
};

export type Address = {
  __typename: "Address",
  id: string,
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2?: string | null,
  city: string,
  state: string,
  zipCode: string,
  countryCode: string,
  phone: string,
  email: string,
};

export type ModelItemConnection = {
  __typename: "ModelItemConnection",
  items:  Array<Item | null >,
  nextToken?: string | null,
};

export type Item = {
  __typename: "Item",
  id: string,
  type: string,
  status: ItemStatus,
  description?:  Array<ItemDescription > | null,
  dimensions: Dimensions,
  weight?: number | null,
  sender: string,
  carrier: string,
  lithiumBattery?: LithiumBattery | null,
  barcode: string,
  sub?: string | null,
  imageUrls: Array< string >,
  restriction?: string | null,
  options?: ItemOptions | null,
  locker: string,
  note?: string | null,
  createdAt?: string | null,
  packageId?: string | null,
  userId?: string | null,
  user?: User | null,
  package?: Package | null,
  updatedAt: string,
};

export enum ItemStatus {
  InWarehouse = "InWarehouse",
  HaveBeenPacked = "HaveBeenPacked",
  CanNotBePacked = "CanNotBePacked",
  RequestedToBeUnPacked = "RequestedToBeUnPacked",
  ReturnToSender = "ReturnToSender",
}


export type ItemDescription = {
  __typename: "ItemDescription",
  description: string,
  price: number,
  quantity: number,
};

export type Dimensions = {
  __typename: "Dimensions",
  length: number,
  width: number,
  height: number,
};

export enum LithiumBattery {
  YES = "YES",
  NO = "NO",
  YES_REMOVABLE = "YES_REMOVABLE",
  YES_PERMANENT = "YES_PERMANENT",
}


export type ItemOptions = {
  __typename: "ItemOptions",
  removeShoeBox?: boolean | null,
  bubbleWrap?: boolean | null,
};

export type Package = {
  __typename: "Package",
  id: string,
  type: string,
  status: PackageStatus,
  options?: PackageOptions | null,
  requestedOn?: string | null,
  packedOn?: string | null,
  dimension?: Dimensions | null,
  weight?: number | null,
  imageUrls?: Array< string > | null,
  shippingMethod?: ShippingRate | null,
  description:  Array<PackageItemDescription >,
  address?: Address | null,
  payment?: PaymentMethod | null,
  trackingNumber?: string | null,
  locker: string,
  createdAt?: string | null,
  owner?: string | null,
  userId?: string | null,
  items?: ModelItemConnection | null,
  user?: User | null,
  updatedAt: string,
};

export enum PackageStatus {
  Submitted = "Submitted",
  Packed = "Packed",
  Paid = "Paid",
  Shipped = "Shipped",
  Delivered = "Delivered",
}


export type PackageOptions = {
  __typename: "PackageOptions",
  removeBills?: boolean | null,
  plasticBag?: boolean | null,
  stretchWrap?: boolean | null,
  doubleWallBox?: boolean | null,
  priority?: boolean | null,
};

export type ShippingRate = {
  __typename: "ShippingRate",
  info?: ShippingMethod | null,
  rate?: number | null,
};

export type ShippingMethod = {
  __typename: "ShippingMethod",
  carrier?: string | null,
  deliveryTime?: string | null,
  dimensionalWeight?: boolean | null,
  frequencyOfDeparture?: string | null,
  insurable?: boolean | null,
  insurance?: string | null,
  maxSize?: string | null,
  maxWeight?: string | null,
  name?: string | null,
  recommended?: boolean | null,
  requiresDutiesInAdvance?: boolean | null,
  tracking?: boolean | null,
};

export type PackageItemDescription = {
  __typename: "PackageItemDescription",
  description: string,
  sender: string,
  price: number,
  quantity: number,
  barcode: string,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  id?: string | null,
  name: PaymentName,
  information?: string | null,
  userId?: string | null,
};

export enum PaymentName {
  PayPal = "PayPal",
  Other = "Other",
}


export type ModelPackageConnection = {
  __typename: "ModelPackageConnection",
  items:  Array<Package | null >,
  nextToken?: string | null,
};

export type UpdateUserInput = {
  id: string,
  type?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  userSub?: string | null,
  avatarUrl?: string | null,
  countryCode?: string | null,
  languageCode?: string | null,
  userableId?: string | null,
  userableType?: UserableType | null,
  disabled?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateAdminInput = {
  id?: string | null,
  type: string,
  adminPermissions?: AdminPermissions | null,
  userId?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelAdminConditionInput = {
  type?: ModelStringInput | null,
  adminPermissions?: ModelAdminPermissionsInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAdminConditionInput | null > | null,
  or?: Array< ModelAdminConditionInput | null > | null,
  not?: ModelAdminConditionInput | null,
};

export type ModelAdminPermissionsInput = {
  eq?: AdminPermissions | null,
  ne?: AdminPermissions | null,
};

export type UpdateAdminInput = {
  id: string,
  type?: string | null,
  adminPermissions?: AdminPermissions | null,
  userId?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteAdminInput = {
  id: string,
};

export type CreateRegularUserInput = {
  id?: string | null,
  type: string,
  locker: string,
  addresses?: Array< AddressInput | null > | null,
  phone?: string | null,
  unPackOnArrival: boolean,
  credit: number,
  userId?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type AddressInput = {
  id: string,
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2?: string | null,
  city: string,
  state: string,
  zipCode: string,
  countryCode: string,
  phone: string,
  email: string,
};

export type ModelRegularUserConditionInput = {
  type?: ModelStringInput | null,
  locker?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  unPackOnArrival?: ModelBooleanInput | null,
  credit?: ModelFloatInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelRegularUserConditionInput | null > | null,
  or?: Array< ModelRegularUserConditionInput | null > | null,
  not?: ModelRegularUserConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateRegularUserInput = {
  id: string,
  type?: string | null,
  locker?: string | null,
  addresses?: Array< AddressInput | null > | null,
  phone?: string | null,
  unPackOnArrival?: boolean | null,
  credit?: number | null,
  userId?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteRegularUserInput = {
  id: string,
};

export type CreateItemInput = {
  id?: string | null,
  type: string,
  status: ItemStatus,
  description?: Array< ItemDescriptionInput > | null,
  dimensions: DimensionsInput,
  weight?: number | null,
  sender: string,
  carrier: string,
  lithiumBattery?: LithiumBattery | null,
  barcode: string,
  sub?: string | null,
  imageUrls: Array< string >,
  restriction?: string | null,
  options?: ItemOptionsInput | null,
  locker: string,
  note?: string | null,
  createdAt?: string | null,
  packageId?: string | null,
  userId?: string | null,
};

export type ItemDescriptionInput = {
  description: string,
  price: number,
  quantity: number,
};

export type DimensionsInput = {
  length: number,
  width: number,
  height: number,
};

export type ItemOptionsInput = {
  removeShoeBox?: boolean | null,
  bubbleWrap?: boolean | null,
};

export type ModelItemConditionInput = {
  type?: ModelStringInput | null,
  status?: ModelItemStatusInput | null,
  weight?: ModelFloatInput | null,
  sender?: ModelStringInput | null,
  carrier?: ModelStringInput | null,
  lithiumBattery?: ModelLithiumBatteryInput | null,
  barcode?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  imageUrls?: ModelStringInput | null,
  restriction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  packageId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelItemConditionInput | null > | null,
  or?: Array< ModelItemConditionInput | null > | null,
  not?: ModelItemConditionInput | null,
};

export type ModelItemStatusInput = {
  eq?: ItemStatus | null,
  ne?: ItemStatus | null,
};

export type ModelLithiumBatteryInput = {
  eq?: LithiumBattery | null,
  ne?: LithiumBattery | null,
};

export type UpdateItemInput = {
  id: string,
  type?: string | null,
  status?: ItemStatus | null,
  description?: Array< ItemDescriptionInput > | null,
  dimensions?: DimensionsInput | null,
  weight?: number | null,
  sender?: string | null,
  carrier?: string | null,
  lithiumBattery?: LithiumBattery | null,
  barcode?: string | null,
  sub?: string | null,
  imageUrls?: Array< string > | null,
  restriction?: string | null,
  options?: ItemOptionsInput | null,
  locker: string,
  note?: string | null,
  createdAt?: string | null,
  packageId?: string | null,
  userId?: string | null,
};

export type DeleteItemInput = {
  id: string,
  locker: string,
};

export type CreatePackageInput = {
  id?: string | null,
  type: string,
  status: PackageStatus,
  options?: PackageOptionsInput | null,
  requestedOn?: string | null,
  packedOn?: string | null,
  dimension?: DimensionsInput | null,
  weight?: number | null,
  imageUrls?: Array< string > | null,
  shippingMethod?: ShippingRateInput | null,
  description: Array< PackageItemDescriptionInput >,
  address?: AddressInput | null,
  payment?: PaymentMethodInput | null,
  trackingNumber?: string | null,
  locker: string,
  createdAt?: string | null,
  owner?: string | null,
  userId?: string | null,
};

export type PackageOptionsInput = {
  removeBills?: boolean | null,
  plasticBag?: boolean | null,
  stretchWrap?: boolean | null,
  doubleWallBox?: boolean | null,
  priority?: boolean | null,
};

export type ShippingRateInput = {
  info?: ShippingMethodInput | null,
  rate?: number | null,
};

export type ShippingMethodInput = {
  carrier?: string | null,
  deliveryTime?: string | null,
  dimensionalWeight?: boolean | null,
  frequencyOfDeparture?: string | null,
  insurable?: boolean | null,
  insurance?: string | null,
  maxSize?: string | null,
  maxWeight?: string | null,
  name?: string | null,
  recommended?: boolean | null,
  requiresDutiesInAdvance?: boolean | null,
  tracking?: boolean | null,
};

export type PackageItemDescriptionInput = {
  description: string,
  sender: string,
  price: number,
  quantity: number,
  barcode: string,
};

export type PaymentMethodInput = {
  id?: string | null,
  name: PaymentName,
  information?: string | null,
  userId?: string | null,
};

export type ModelPackageConditionInput = {
  type?: ModelStringInput | null,
  status?: ModelPackageStatusInput | null,
  requestedOn?: ModelStringInput | null,
  packedOn?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  imageUrls?: ModelStringInput | null,
  trackingNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelPackageConditionInput | null > | null,
  or?: Array< ModelPackageConditionInput | null > | null,
  not?: ModelPackageConditionInput | null,
};

export type ModelPackageStatusInput = {
  eq?: PackageStatus | null,
  ne?: PackageStatus | null,
};

export type UpdatePackageInput = {
  id: string,
  type?: string | null,
  status?: PackageStatus | null,
  options?: PackageOptionsInput | null,
  requestedOn?: string | null,
  packedOn?: string | null,
  dimension?: DimensionsInput | null,
  weight?: number | null,
  imageUrls?: Array< string > | null,
  shippingMethod?: ShippingRateInput | null,
  description?: Array< PackageItemDescriptionInput > | null,
  address?: AddressInput | null,
  payment?: PaymentMethodInput | null,
  trackingNumber?: string | null,
  locker: string,
  createdAt?: string | null,
  owner?: string | null,
  userId?: string | null,
};

export type DeletePackageInput = {
  id: string,
  locker: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  userSub?: ModelStringInput | null,
  avatarUrl?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  languageCode?: ModelStringInput | null,
  userableId?: ModelIDInput | null,
  userableType?: ModelUserableTypeInput | null,
  disabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelAdminFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  adminPermissions?: ModelAdminPermissionsInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAdminFilterInput | null > | null,
  or?: Array< ModelAdminFilterInput | null > | null,
  not?: ModelAdminFilterInput | null,
};

export type ModelAdminConnection = {
  __typename: "ModelAdminConnection",
  items:  Array<Admin | null >,
  nextToken?: string | null,
};

export type ModelRegularUserFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  locker?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  unPackOnArrival?: ModelBooleanInput | null,
  credit?: ModelFloatInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelRegularUserFilterInput | null > | null,
  or?: Array< ModelRegularUserFilterInput | null > | null,
  not?: ModelRegularUserFilterInput | null,
};

export type ModelRegularUserConnection = {
  __typename: "ModelRegularUserConnection",
  items:  Array<RegularUser | null >,
  nextToken?: string | null,
};

export type ModelItemFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  status?: ModelItemStatusInput | null,
  weight?: ModelFloatInput | null,
  sender?: ModelStringInput | null,
  carrier?: ModelStringInput | null,
  lithiumBattery?: ModelLithiumBatteryInput | null,
  barcode?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  imageUrls?: ModelStringInput | null,
  restriction?: ModelStringInput | null,
  locker?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  packageId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelItemFilterInput | null > | null,
  or?: Array< ModelItemFilterInput | null > | null,
  not?: ModelItemFilterInput | null,
};

export type ModelPackageFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  status?: ModelPackageStatusInput | null,
  requestedOn?: ModelStringInput | null,
  packedOn?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  imageUrls?: ModelStringInput | null,
  trackingNumber?: ModelStringInput | null,
  locker?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelPackageFilterInput | null > | null,
  or?: Array< ModelPackageFilterInput | null > | null,
  not?: ModelPackageFilterInput | null,
};

export type getQuoteResponse = {
  __typename: "getQuoteResponse",
  list?:  Array<ShippingRate | null > | null,
};

export type EmailResponse = {
  __typename: "EmailResponse",
  status?: number | null,
  response?: string | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  userSub?: ModelSubscriptionStringInput | null,
  avatarUrl?: ModelSubscriptionStringInput | null,
  countryCode?: ModelSubscriptionStringInput | null,
  languageCode?: ModelSubscriptionStringInput | null,
  userableId?: ModelSubscriptionIDInput | null,
  userableType?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionAdminFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  adminPermissions?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminFilterInput | null > | null,
};

export type ModelSubscriptionRegularUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  locker?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  unPackOnArrival?: ModelSubscriptionBooleanInput | null,
  credit?: ModelSubscriptionFloatInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRegularUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegularUserFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  weight?: ModelSubscriptionFloatInput | null,
  sender?: ModelSubscriptionStringInput | null,
  carrier?: ModelSubscriptionStringInput | null,
  lithiumBattery?: ModelSubscriptionStringInput | null,
  barcode?: ModelSubscriptionStringInput | null,
  imageUrls?: ModelSubscriptionStringInput | null,
  restriction?: ModelSubscriptionStringInput | null,
  locker?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  packageId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionItemFilterInput | null > | null,
};

export type ModelSubscriptionPackageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  requestedOn?: ModelSubscriptionStringInput | null,
  packedOn?: ModelSubscriptionStringInput | null,
  weight?: ModelSubscriptionFloatInput | null,
  imageUrls?: ModelSubscriptionStringInput | null,
  trackingNumber?: ModelSubscriptionStringInput | null,
  locker?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPackageFilterInput | null > | null,
  or?: Array< ModelSubscriptionPackageFilterInput | null > | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    userSub: string,
    avatarUrl: string,
    countryCode: string,
    languageCode: string,
    userableId: string,
    userableType: UserableType,
    disabled: boolean,
    createdAt?: string | null,
    owner?: string | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    regularUser?:  {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    userSub: string,
    avatarUrl: string,
    countryCode: string,
    languageCode: string,
    userableId: string,
    userableType: UserableType,
    disabled: boolean,
    createdAt?: string | null,
    owner?: string | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    regularUser?:  {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    userSub: string,
    avatarUrl: string,
    countryCode: string,
    languageCode: string,
    userableId: string,
    userableType: UserableType,
    disabled: boolean,
    createdAt?: string | null,
    owner?: string | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    regularUser?:  {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateAdminMutationVariables = {
  input: CreateAdminInput,
  condition?: ModelAdminConditionInput | null,
};

export type CreateAdminMutation = {
  createAdmin?:  {
    __typename: "Admin",
    id: string,
    type: string,
    adminPermissions?: AdminPermissions | null,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateAdminMutationVariables = {
  input: UpdateAdminInput,
  condition?: ModelAdminConditionInput | null,
};

export type UpdateAdminMutation = {
  updateAdmin?:  {
    __typename: "Admin",
    id: string,
    type: string,
    adminPermissions?: AdminPermissions | null,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteAdminMutationVariables = {
  input: DeleteAdminInput,
  condition?: ModelAdminConditionInput | null,
};

export type DeleteAdminMutation = {
  deleteAdmin?:  {
    __typename: "Admin",
    id: string,
    type: string,
    adminPermissions?: AdminPermissions | null,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateRegularUserMutationVariables = {
  input: CreateRegularUserInput,
  condition?: ModelRegularUserConditionInput | null,
};

export type CreateRegularUserMutation = {
  createRegularUser?:  {
    __typename: "RegularUser",
    id: string,
    type: string,
    locker: string,
    addresses?:  Array< {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null > | null,
    phone?: string | null,
    unPackOnArrival: boolean,
    credit: number,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    packages?:  {
      __typename: "ModelPackageConnection",
      items:  Array< {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateRegularUserMutationVariables = {
  input: UpdateRegularUserInput,
  condition?: ModelRegularUserConditionInput | null,
};

export type UpdateRegularUserMutation = {
  updateRegularUser?:  {
    __typename: "RegularUser",
    id: string,
    type: string,
    locker: string,
    addresses?:  Array< {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null > | null,
    phone?: string | null,
    unPackOnArrival: boolean,
    credit: number,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    packages?:  {
      __typename: "ModelPackageConnection",
      items:  Array< {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteRegularUserMutationVariables = {
  input: DeleteRegularUserInput,
  condition?: ModelRegularUserConditionInput | null,
};

export type DeleteRegularUserMutation = {
  deleteRegularUser?:  {
    __typename: "RegularUser",
    id: string,
    type: string,
    locker: string,
    addresses?:  Array< {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null > | null,
    phone?: string | null,
    unPackOnArrival: boolean,
    credit: number,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    packages?:  {
      __typename: "ModelPackageConnection",
      items:  Array< {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateItemMutationVariables = {
  input: CreateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type CreateItemMutation = {
  createItem?:  {
    __typename: "Item",
    id: string,
    type: string,
    status: ItemStatus,
    description?:  Array< {
      __typename: "ItemDescription",
      description: string,
      price: number,
      quantity: number,
    } > | null,
    dimensions:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    },
    weight?: number | null,
    sender: string,
    carrier: string,
    lithiumBattery?: LithiumBattery | null,
    barcode: string,
    sub?: string | null,
    imageUrls: Array< string >,
    restriction?: string | null,
    options?:  {
      __typename: "ItemOptions",
      removeShoeBox?: boolean | null,
      bubbleWrap?: boolean | null,
    } | null,
    locker: string,
    note?: string | null,
    createdAt?: string | null,
    packageId?: string | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    package?:  {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateItemMutationVariables = {
  input: UpdateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type UpdateItemMutation = {
  updateItem?:  {
    __typename: "Item",
    id: string,
    type: string,
    status: ItemStatus,
    description?:  Array< {
      __typename: "ItemDescription",
      description: string,
      price: number,
      quantity: number,
    } > | null,
    dimensions:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    },
    weight?: number | null,
    sender: string,
    carrier: string,
    lithiumBattery?: LithiumBattery | null,
    barcode: string,
    sub?: string | null,
    imageUrls: Array< string >,
    restriction?: string | null,
    options?:  {
      __typename: "ItemOptions",
      removeShoeBox?: boolean | null,
      bubbleWrap?: boolean | null,
    } | null,
    locker: string,
    note?: string | null,
    createdAt?: string | null,
    packageId?: string | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    package?:  {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteItemMutationVariables = {
  input: DeleteItemInput,
  condition?: ModelItemConditionInput | null,
};

export type DeleteItemMutation = {
  deleteItem?:  {
    __typename: "Item",
    id: string,
    type: string,
    status: ItemStatus,
    description?:  Array< {
      __typename: "ItemDescription",
      description: string,
      price: number,
      quantity: number,
    } > | null,
    dimensions:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    },
    weight?: number | null,
    sender: string,
    carrier: string,
    lithiumBattery?: LithiumBattery | null,
    barcode: string,
    sub?: string | null,
    imageUrls: Array< string >,
    restriction?: string | null,
    options?:  {
      __typename: "ItemOptions",
      removeShoeBox?: boolean | null,
      bubbleWrap?: boolean | null,
    } | null,
    locker: string,
    note?: string | null,
    createdAt?: string | null,
    packageId?: string | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    package?:  {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreatePackageMutationVariables = {
  input: CreatePackageInput,
  condition?: ModelPackageConditionInput | null,
};

export type CreatePackageMutation = {
  createPackage?:  {
    __typename: "Package",
    id: string,
    type: string,
    status: PackageStatus,
    options?:  {
      __typename: "PackageOptions",
      removeBills?: boolean | null,
      plasticBag?: boolean | null,
      stretchWrap?: boolean | null,
      doubleWallBox?: boolean | null,
      priority?: boolean | null,
    } | null,
    requestedOn?: string | null,
    packedOn?: string | null,
    dimension?:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    } | null,
    weight?: number | null,
    imageUrls?: Array< string > | null,
    shippingMethod?:  {
      __typename: "ShippingRate",
      info?:  {
        __typename: "ShippingMethod",
        carrier?: string | null,
        deliveryTime?: string | null,
        dimensionalWeight?: boolean | null,
        frequencyOfDeparture?: string | null,
        insurable?: boolean | null,
        insurance?: string | null,
        maxSize?: string | null,
        maxWeight?: string | null,
        name?: string | null,
        recommended?: boolean | null,
        requiresDutiesInAdvance?: boolean | null,
        tracking?: boolean | null,
      } | null,
      rate?: number | null,
    } | null,
    description:  Array< {
      __typename: "PackageItemDescription",
      description: string,
      sender: string,
      price: number,
      quantity: number,
      barcode: string,
    } >,
    address?:  {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null,
    payment?:  {
      __typename: "PaymentMethod",
      id?: string | null,
      name: PaymentName,
      information?: string | null,
      userId?: string | null,
    } | null,
    trackingNumber?: string | null,
    locker: string,
    createdAt?: string | null,
    owner?: string | null,
    userId?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdatePackageMutationVariables = {
  input: UpdatePackageInput,
  condition?: ModelPackageConditionInput | null,
};

export type UpdatePackageMutation = {
  updatePackage?:  {
    __typename: "Package",
    id: string,
    type: string,
    status: PackageStatus,
    options?:  {
      __typename: "PackageOptions",
      removeBills?: boolean | null,
      plasticBag?: boolean | null,
      stretchWrap?: boolean | null,
      doubleWallBox?: boolean | null,
      priority?: boolean | null,
    } | null,
    requestedOn?: string | null,
    packedOn?: string | null,
    dimension?:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    } | null,
    weight?: number | null,
    imageUrls?: Array< string > | null,
    shippingMethod?:  {
      __typename: "ShippingRate",
      info?:  {
        __typename: "ShippingMethod",
        carrier?: string | null,
        deliveryTime?: string | null,
        dimensionalWeight?: boolean | null,
        frequencyOfDeparture?: string | null,
        insurable?: boolean | null,
        insurance?: string | null,
        maxSize?: string | null,
        maxWeight?: string | null,
        name?: string | null,
        recommended?: boolean | null,
        requiresDutiesInAdvance?: boolean | null,
        tracking?: boolean | null,
      } | null,
      rate?: number | null,
    } | null,
    description:  Array< {
      __typename: "PackageItemDescription",
      description: string,
      sender: string,
      price: number,
      quantity: number,
      barcode: string,
    } >,
    address?:  {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null,
    payment?:  {
      __typename: "PaymentMethod",
      id?: string | null,
      name: PaymentName,
      information?: string | null,
      userId?: string | null,
    } | null,
    trackingNumber?: string | null,
    locker: string,
    createdAt?: string | null,
    owner?: string | null,
    userId?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeletePackageMutationVariables = {
  input: DeletePackageInput,
  condition?: ModelPackageConditionInput | null,
};

export type DeletePackageMutation = {
  deletePackage?:  {
    __typename: "Package",
    id: string,
    type: string,
    status: PackageStatus,
    options?:  {
      __typename: "PackageOptions",
      removeBills?: boolean | null,
      plasticBag?: boolean | null,
      stretchWrap?: boolean | null,
      doubleWallBox?: boolean | null,
      priority?: boolean | null,
    } | null,
    requestedOn?: string | null,
    packedOn?: string | null,
    dimension?:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    } | null,
    weight?: number | null,
    imageUrls?: Array< string > | null,
    shippingMethod?:  {
      __typename: "ShippingRate",
      info?:  {
        __typename: "ShippingMethod",
        carrier?: string | null,
        deliveryTime?: string | null,
        dimensionalWeight?: boolean | null,
        frequencyOfDeparture?: string | null,
        insurable?: boolean | null,
        insurance?: string | null,
        maxSize?: string | null,
        maxWeight?: string | null,
        name?: string | null,
        recommended?: boolean | null,
        requiresDutiesInAdvance?: boolean | null,
        tracking?: boolean | null,
      } | null,
      rate?: number | null,
    } | null,
    description:  Array< {
      __typename: "PackageItemDescription",
      description: string,
      sender: string,
      price: number,
      quantity: number,
      barcode: string,
    } >,
    address?:  {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null,
    payment?:  {
      __typename: "PaymentMethod",
      id?: string | null,
      name: PaymentName,
      information?: string | null,
      userId?: string | null,
    } | null,
    trackingNumber?: string | null,
    locker: string,
    createdAt?: string | null,
    owner?: string | null,
    userId?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type GetLockerQuery = {
  getLocker?: string | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    userSub: string,
    avatarUrl: string,
    countryCode: string,
    languageCode: string,
    userableId: string,
    userableType: UserableType,
    disabled: boolean,
    createdAt?: string | null,
    owner?: string | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    regularUser?:  {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByDateQueryVariables = {
  type: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByDateQuery = {
  usersByDate?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdminQueryVariables = {
  id: string,
};

export type GetAdminQuery = {
  getAdmin?:  {
    __typename: "Admin",
    id: string,
    type: string,
    adminPermissions?: AdminPermissions | null,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListAdminsQueryVariables = {
  id?: string | null,
  filter?: ModelAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAdminsQuery = {
  listAdmins?:  {
    __typename: "ModelAdminConnection",
    items:  Array< {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AdminsByDateQueryVariables = {
  type: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminsByDateQuery = {
  adminsByDate?:  {
    __typename: "ModelAdminConnection",
    items:  Array< {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRegularUserQueryVariables = {
  id: string,
};

export type GetRegularUserQuery = {
  getRegularUser?:  {
    __typename: "RegularUser",
    id: string,
    type: string,
    locker: string,
    addresses?:  Array< {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null > | null,
    phone?: string | null,
    unPackOnArrival: boolean,
    credit: number,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    packages?:  {
      __typename: "ModelPackageConnection",
      items:  Array< {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListRegularUsersQueryVariables = {
  id?: string | null,
  filter?: ModelRegularUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRegularUsersQuery = {
  listRegularUsers?:  {
    __typename: "ModelRegularUserConnection",
    items:  Array< {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RegularUserByDateQueryVariables = {
  type: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularUserByDateQuery = {
  regularUserByDate?:  {
    __typename: "ModelRegularUserConnection",
    items:  Array< {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemQueryVariables = {
  id: string,
  locker: string,
};

export type GetItemQuery = {
  getItem?:  {
    __typename: "Item",
    id: string,
    type: string,
    status: ItemStatus,
    description?:  Array< {
      __typename: "ItemDescription",
      description: string,
      price: number,
      quantity: number,
    } > | null,
    dimensions:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    },
    weight?: number | null,
    sender: string,
    carrier: string,
    lithiumBattery?: LithiumBattery | null,
    barcode: string,
    sub?: string | null,
    imageUrls: Array< string >,
    restriction?: string | null,
    options?:  {
      __typename: "ItemOptions",
      removeShoeBox?: boolean | null,
      bubbleWrap?: boolean | null,
    } | null,
    locker: string,
    note?: string | null,
    createdAt?: string | null,
    packageId?: string | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    package?:  {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListItemsQueryVariables = {
  id?: string | null,
  locker?: ModelStringKeyConditionInput | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListItemsQuery = {
  listItems?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      type: string,
      status: ItemStatus,
      description?:  Array< {
        __typename: "ItemDescription",
        description: string,
        price: number,
        quantity: number,
      } > | null,
      dimensions:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      },
      weight?: number | null,
      sender: string,
      carrier: string,
      lithiumBattery?: LithiumBattery | null,
      barcode: string,
      sub?: string | null,
      imageUrls: Array< string >,
      restriction?: string | null,
      options?:  {
        __typename: "ItemOptions",
        removeShoeBox?: boolean | null,
        bubbleWrap?: boolean | null,
      } | null,
      locker: string,
      note?: string | null,
      createdAt?: string | null,
      packageId?: string | null,
      userId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      package?:  {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByDateQueryVariables = {
  type: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByDateQuery = {
  itemsByDate?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      type: string,
      status: ItemStatus,
      description?:  Array< {
        __typename: "ItemDescription",
        description: string,
        price: number,
        quantity: number,
      } > | null,
      dimensions:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      },
      weight?: number | null,
      sender: string,
      carrier: string,
      lithiumBattery?: LithiumBattery | null,
      barcode: string,
      sub?: string | null,
      imageUrls: Array< string >,
      restriction?: string | null,
      options?:  {
        __typename: "ItemOptions",
        removeShoeBox?: boolean | null,
        bubbleWrap?: boolean | null,
      } | null,
      locker: string,
      note?: string | null,
      createdAt?: string | null,
      packageId?: string | null,
      userId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      package?:  {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByPackageIdAndLockerQueryVariables = {
  packageId: string,
  locker?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByPackageIdAndLockerQuery = {
  itemsByPackageIdAndLocker?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      type: string,
      status: ItemStatus,
      description?:  Array< {
        __typename: "ItemDescription",
        description: string,
        price: number,
        quantity: number,
      } > | null,
      dimensions:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      },
      weight?: number | null,
      sender: string,
      carrier: string,
      lithiumBattery?: LithiumBattery | null,
      barcode: string,
      sub?: string | null,
      imageUrls: Array< string >,
      restriction?: string | null,
      options?:  {
        __typename: "ItemOptions",
        removeShoeBox?: boolean | null,
        bubbleWrap?: boolean | null,
      } | null,
      locker: string,
      note?: string | null,
      createdAt?: string | null,
      packageId?: string | null,
      userId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      package?:  {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByUserIdAndLockerQueryVariables = {
  userId: string,
  locker?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByUserIdAndLockerQuery = {
  itemsByUserIdAndLocker?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      type: string,
      status: ItemStatus,
      description?:  Array< {
        __typename: "ItemDescription",
        description: string,
        price: number,
        quantity: number,
      } > | null,
      dimensions:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      },
      weight?: number | null,
      sender: string,
      carrier: string,
      lithiumBattery?: LithiumBattery | null,
      barcode: string,
      sub?: string | null,
      imageUrls: Array< string >,
      restriction?: string | null,
      options?:  {
        __typename: "ItemOptions",
        removeShoeBox?: boolean | null,
        bubbleWrap?: boolean | null,
      } | null,
      locker: string,
      note?: string | null,
      createdAt?: string | null,
      packageId?: string | null,
      userId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      package?:  {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPackageQueryVariables = {
  id: string,
  locker: string,
};

export type GetPackageQuery = {
  getPackage?:  {
    __typename: "Package",
    id: string,
    type: string,
    status: PackageStatus,
    options?:  {
      __typename: "PackageOptions",
      removeBills?: boolean | null,
      plasticBag?: boolean | null,
      stretchWrap?: boolean | null,
      doubleWallBox?: boolean | null,
      priority?: boolean | null,
    } | null,
    requestedOn?: string | null,
    packedOn?: string | null,
    dimension?:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    } | null,
    weight?: number | null,
    imageUrls?: Array< string > | null,
    shippingMethod?:  {
      __typename: "ShippingRate",
      info?:  {
        __typename: "ShippingMethod",
        carrier?: string | null,
        deliveryTime?: string | null,
        dimensionalWeight?: boolean | null,
        frequencyOfDeparture?: string | null,
        insurable?: boolean | null,
        insurance?: string | null,
        maxSize?: string | null,
        maxWeight?: string | null,
        name?: string | null,
        recommended?: boolean | null,
        requiresDutiesInAdvance?: boolean | null,
        tracking?: boolean | null,
      } | null,
      rate?: number | null,
    } | null,
    description:  Array< {
      __typename: "PackageItemDescription",
      description: string,
      sender: string,
      price: number,
      quantity: number,
      barcode: string,
    } >,
    address?:  {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null,
    payment?:  {
      __typename: "PaymentMethod",
      id?: string | null,
      name: PaymentName,
      information?: string | null,
      userId?: string | null,
    } | null,
    trackingNumber?: string | null,
    locker: string,
    createdAt?: string | null,
    owner?: string | null,
    userId?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListPackagesQueryVariables = {
  id?: string | null,
  locker?: ModelStringKeyConditionInput | null,
  filter?: ModelPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPackagesQuery = {
  listPackages?:  {
    __typename: "ModelPackageConnection",
    items:  Array< {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PackagesByDateQueryVariables = {
  type: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PackagesByDateQuery = {
  packagesByDate?:  {
    __typename: "ModelPackageConnection",
    items:  Array< {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PackagesByUserIdAndLockerQueryVariables = {
  userId: string,
  locker?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PackagesByUserIdAndLockerQuery = {
  packagesByUserIdAndLocker?:  {
    __typename: "ModelPackageConnection",
    items:  Array< {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuoteQueryVariables = {
  args?: string | null,
};

export type GetQuoteQuery = {
  getQuote?:  {
    __typename: "getQuoteResponse",
    list?:  Array< {
      __typename: "ShippingRate",
      info?:  {
        __typename: "ShippingMethod",
        carrier?: string | null,
        deliveryTime?: string | null,
        dimensionalWeight?: boolean | null,
        frequencyOfDeparture?: string | null,
        insurable?: boolean | null,
        insurance?: string | null,
        maxSize?: string | null,
        maxWeight?: string | null,
        name?: string | null,
        recommended?: boolean | null,
        requiresDutiesInAdvance?: boolean | null,
        tracking?: boolean | null,
      } | null,
      rate?: number | null,
    } | null > | null,
  } | null,
};

export type ContactUsQueryVariables = {
  args?: string | null,
};

export type ContactUsQuery = {
  contactUs?:  {
    __typename: "EmailResponse",
    status?: number | null,
    response?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    userSub: string,
    avatarUrl: string,
    countryCode: string,
    languageCode: string,
    userableId: string,
    userableType: UserableType,
    disabled: boolean,
    createdAt?: string | null,
    owner?: string | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    regularUser?:  {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    userSub: string,
    avatarUrl: string,
    countryCode: string,
    languageCode: string,
    userableId: string,
    userableType: UserableType,
    disabled: boolean,
    createdAt?: string | null,
    owner?: string | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    regularUser?:  {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    userSub: string,
    avatarUrl: string,
    countryCode: string,
    languageCode: string,
    userableId: string,
    userableType: UserableType,
    disabled: boolean,
    createdAt?: string | null,
    owner?: string | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      type: string,
      adminPermissions?: AdminPermissions | null,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    regularUser?:  {
      __typename: "RegularUser",
      id: string,
      type: string,
      locker: string,
      addresses?:  Array< {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null > | null,
      phone?: string | null,
      unPackOnArrival: boolean,
      credit: number,
      userId?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      packages?:  {
        __typename: "ModelPackageConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAdminSubscriptionVariables = {
  filter?: ModelSubscriptionAdminFilterInput | null,
  owner?: string | null,
};

export type OnCreateAdminSubscription = {
  onCreateAdmin?:  {
    __typename: "Admin",
    id: string,
    type: string,
    adminPermissions?: AdminPermissions | null,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAdminSubscriptionVariables = {
  filter?: ModelSubscriptionAdminFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAdminSubscription = {
  onUpdateAdmin?:  {
    __typename: "Admin",
    id: string,
    type: string,
    adminPermissions?: AdminPermissions | null,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAdminSubscriptionVariables = {
  filter?: ModelSubscriptionAdminFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAdminSubscription = {
  onDeleteAdmin?:  {
    __typename: "Admin",
    id: string,
    type: string,
    adminPermissions?: AdminPermissions | null,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateRegularUserSubscriptionVariables = {
  filter?: ModelSubscriptionRegularUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateRegularUserSubscription = {
  onCreateRegularUser?:  {
    __typename: "RegularUser",
    id: string,
    type: string,
    locker: string,
    addresses?:  Array< {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null > | null,
    phone?: string | null,
    unPackOnArrival: boolean,
    credit: number,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    packages?:  {
      __typename: "ModelPackageConnection",
      items:  Array< {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateRegularUserSubscriptionVariables = {
  filter?: ModelSubscriptionRegularUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRegularUserSubscription = {
  onUpdateRegularUser?:  {
    __typename: "RegularUser",
    id: string,
    type: string,
    locker: string,
    addresses?:  Array< {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null > | null,
    phone?: string | null,
    unPackOnArrival: boolean,
    credit: number,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    packages?:  {
      __typename: "ModelPackageConnection",
      items:  Array< {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteRegularUserSubscriptionVariables = {
  filter?: ModelSubscriptionRegularUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRegularUserSubscription = {
  onDeleteRegularUser?:  {
    __typename: "RegularUser",
    id: string,
    type: string,
    locker: string,
    addresses?:  Array< {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null > | null,
    phone?: string | null,
    unPackOnArrival: boolean,
    credit: number,
    userId?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    packages?:  {
      __typename: "ModelPackageConnection",
      items:  Array< {
        __typename: "Package",
        id: string,
        type: string,
        status: PackageStatus,
        requestedOn?: string | null,
        packedOn?: string | null,
        weight?: number | null,
        imageUrls?: Array< string > | null,
        trackingNumber?: string | null,
        locker: string,
        createdAt?: string | null,
        owner?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  sub?: string | null,
};

export type OnCreateItemSubscription = {
  onCreateItem?:  {
    __typename: "Item",
    id: string,
    type: string,
    status: ItemStatus,
    description?:  Array< {
      __typename: "ItemDescription",
      description: string,
      price: number,
      quantity: number,
    } > | null,
    dimensions:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    },
    weight?: number | null,
    sender: string,
    carrier: string,
    lithiumBattery?: LithiumBattery | null,
    barcode: string,
    sub?: string | null,
    imageUrls: Array< string >,
    restriction?: string | null,
    options?:  {
      __typename: "ItemOptions",
      removeShoeBox?: boolean | null,
      bubbleWrap?: boolean | null,
    } | null,
    locker: string,
    note?: string | null,
    createdAt?: string | null,
    packageId?: string | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    package?:  {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  sub?: string | null,
};

export type OnUpdateItemSubscription = {
  onUpdateItem?:  {
    __typename: "Item",
    id: string,
    type: string,
    status: ItemStatus,
    description?:  Array< {
      __typename: "ItemDescription",
      description: string,
      price: number,
      quantity: number,
    } > | null,
    dimensions:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    },
    weight?: number | null,
    sender: string,
    carrier: string,
    lithiumBattery?: LithiumBattery | null,
    barcode: string,
    sub?: string | null,
    imageUrls: Array< string >,
    restriction?: string | null,
    options?:  {
      __typename: "ItemOptions",
      removeShoeBox?: boolean | null,
      bubbleWrap?: boolean | null,
    } | null,
    locker: string,
    note?: string | null,
    createdAt?: string | null,
    packageId?: string | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    package?:  {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  sub?: string | null,
};

export type OnDeleteItemSubscription = {
  onDeleteItem?:  {
    __typename: "Item",
    id: string,
    type: string,
    status: ItemStatus,
    description?:  Array< {
      __typename: "ItemDescription",
      description: string,
      price: number,
      quantity: number,
    } > | null,
    dimensions:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    },
    weight?: number | null,
    sender: string,
    carrier: string,
    lithiumBattery?: LithiumBattery | null,
    barcode: string,
    sub?: string | null,
    imageUrls: Array< string >,
    restriction?: string | null,
    options?:  {
      __typename: "ItemOptions",
      removeShoeBox?: boolean | null,
      bubbleWrap?: boolean | null,
    } | null,
    locker: string,
    note?: string | null,
    createdAt?: string | null,
    packageId?: string | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    package?:  {
      __typename: "Package",
      id: string,
      type: string,
      status: PackageStatus,
      options?:  {
        __typename: "PackageOptions",
        removeBills?: boolean | null,
        plasticBag?: boolean | null,
        stretchWrap?: boolean | null,
        doubleWallBox?: boolean | null,
        priority?: boolean | null,
      } | null,
      requestedOn?: string | null,
      packedOn?: string | null,
      dimension?:  {
        __typename: "Dimensions",
        length: number,
        width: number,
        height: number,
      } | null,
      weight?: number | null,
      imageUrls?: Array< string > | null,
      shippingMethod?:  {
        __typename: "ShippingRate",
        rate?: number | null,
      } | null,
      description:  Array< {
        __typename: "PackageItemDescription",
        description: string,
        sender: string,
        price: number,
        quantity: number,
        barcode: string,
      } >,
      address?:  {
        __typename: "Address",
        id: string,
        firstName: string,
        lastName: string,
        addressLine1: string,
        addressLine2?: string | null,
        city: string,
        state: string,
        zipCode: string,
        countryCode: string,
        phone: string,
        email: string,
      } | null,
      payment?:  {
        __typename: "PaymentMethod",
        id?: string | null,
        name: PaymentName,
        information?: string | null,
        userId?: string | null,
      } | null,
      trackingNumber?: string | null,
      locker: string,
      createdAt?: string | null,
      owner?: string | null,
      userId?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        email: string,
        userSub: string,
        avatarUrl: string,
        countryCode: string,
        languageCode: string,
        userableId: string,
        userableType: UserableType,
        disabled: boolean,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreatePackageSubscriptionVariables = {
  filter?: ModelSubscriptionPackageFilterInput | null,
  owner?: string | null,
};

export type OnCreatePackageSubscription = {
  onCreatePackage?:  {
    __typename: "Package",
    id: string,
    type: string,
    status: PackageStatus,
    options?:  {
      __typename: "PackageOptions",
      removeBills?: boolean | null,
      plasticBag?: boolean | null,
      stretchWrap?: boolean | null,
      doubleWallBox?: boolean | null,
      priority?: boolean | null,
    } | null,
    requestedOn?: string | null,
    packedOn?: string | null,
    dimension?:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    } | null,
    weight?: number | null,
    imageUrls?: Array< string > | null,
    shippingMethod?:  {
      __typename: "ShippingRate",
      info?:  {
        __typename: "ShippingMethod",
        carrier?: string | null,
        deliveryTime?: string | null,
        dimensionalWeight?: boolean | null,
        frequencyOfDeparture?: string | null,
        insurable?: boolean | null,
        insurance?: string | null,
        maxSize?: string | null,
        maxWeight?: string | null,
        name?: string | null,
        recommended?: boolean | null,
        requiresDutiesInAdvance?: boolean | null,
        tracking?: boolean | null,
      } | null,
      rate?: number | null,
    } | null,
    description:  Array< {
      __typename: "PackageItemDescription",
      description: string,
      sender: string,
      price: number,
      quantity: number,
      barcode: string,
    } >,
    address?:  {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null,
    payment?:  {
      __typename: "PaymentMethod",
      id?: string | null,
      name: PaymentName,
      information?: string | null,
      userId?: string | null,
    } | null,
    trackingNumber?: string | null,
    locker: string,
    createdAt?: string | null,
    owner?: string | null,
    userId?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdatePackageSubscriptionVariables = {
  filter?: ModelSubscriptionPackageFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePackageSubscription = {
  onUpdatePackage?:  {
    __typename: "Package",
    id: string,
    type: string,
    status: PackageStatus,
    options?:  {
      __typename: "PackageOptions",
      removeBills?: boolean | null,
      plasticBag?: boolean | null,
      stretchWrap?: boolean | null,
      doubleWallBox?: boolean | null,
      priority?: boolean | null,
    } | null,
    requestedOn?: string | null,
    packedOn?: string | null,
    dimension?:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    } | null,
    weight?: number | null,
    imageUrls?: Array< string > | null,
    shippingMethod?:  {
      __typename: "ShippingRate",
      info?:  {
        __typename: "ShippingMethod",
        carrier?: string | null,
        deliveryTime?: string | null,
        dimensionalWeight?: boolean | null,
        frequencyOfDeparture?: string | null,
        insurable?: boolean | null,
        insurance?: string | null,
        maxSize?: string | null,
        maxWeight?: string | null,
        name?: string | null,
        recommended?: boolean | null,
        requiresDutiesInAdvance?: boolean | null,
        tracking?: boolean | null,
      } | null,
      rate?: number | null,
    } | null,
    description:  Array< {
      __typename: "PackageItemDescription",
      description: string,
      sender: string,
      price: number,
      quantity: number,
      barcode: string,
    } >,
    address?:  {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null,
    payment?:  {
      __typename: "PaymentMethod",
      id?: string | null,
      name: PaymentName,
      information?: string | null,
      userId?: string | null,
    } | null,
    trackingNumber?: string | null,
    locker: string,
    createdAt?: string | null,
    owner?: string | null,
    userId?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeletePackageSubscriptionVariables = {
  filter?: ModelSubscriptionPackageFilterInput | null,
  owner?: string | null,
};

export type OnDeletePackageSubscription = {
  onDeletePackage?:  {
    __typename: "Package",
    id: string,
    type: string,
    status: PackageStatus,
    options?:  {
      __typename: "PackageOptions",
      removeBills?: boolean | null,
      plasticBag?: boolean | null,
      stretchWrap?: boolean | null,
      doubleWallBox?: boolean | null,
      priority?: boolean | null,
    } | null,
    requestedOn?: string | null,
    packedOn?: string | null,
    dimension?:  {
      __typename: "Dimensions",
      length: number,
      width: number,
      height: number,
    } | null,
    weight?: number | null,
    imageUrls?: Array< string > | null,
    shippingMethod?:  {
      __typename: "ShippingRate",
      info?:  {
        __typename: "ShippingMethod",
        carrier?: string | null,
        deliveryTime?: string | null,
        dimensionalWeight?: boolean | null,
        frequencyOfDeparture?: string | null,
        insurable?: boolean | null,
        insurance?: string | null,
        maxSize?: string | null,
        maxWeight?: string | null,
        name?: string | null,
        recommended?: boolean | null,
        requiresDutiesInAdvance?: boolean | null,
        tracking?: boolean | null,
      } | null,
      rate?: number | null,
    } | null,
    description:  Array< {
      __typename: "PackageItemDescription",
      description: string,
      sender: string,
      price: number,
      quantity: number,
      barcode: string,
    } >,
    address?:  {
      __typename: "Address",
      id: string,
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2?: string | null,
      city: string,
      state: string,
      zipCode: string,
      countryCode: string,
      phone: string,
      email: string,
    } | null,
    payment?:  {
      __typename: "PaymentMethod",
      id?: string | null,
      name: PaymentName,
      information?: string | null,
      userId?: string | null,
    } | null,
    trackingNumber?: string | null,
    locker: string,
    createdAt?: string | null,
    owner?: string | null,
    userId?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        type: string,
        status: ItemStatus,
        weight?: number | null,
        sender: string,
        carrier: string,
        lithiumBattery?: LithiumBattery | null,
        barcode: string,
        sub?: string | null,
        imageUrls: Array< string >,
        restriction?: string | null,
        locker: string,
        note?: string | null,
        createdAt?: string | null,
        packageId?: string | null,
        userId?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      email: string,
      userSub: string,
      avatarUrl: string,
      countryCode: string,
      languageCode: string,
      userableId: string,
      userableType: UserableType,
      disabled: boolean,
      createdAt?: string | null,
      owner?: string | null,
      admin?:  {
        __typename: "Admin",
        id: string,
        type: string,
        adminPermissions?: AdminPermissions | null,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      regularUser?:  {
        __typename: "RegularUser",
        id: string,
        type: string,
        locker: string,
        phone?: string | null,
        unPackOnArrival: boolean,
        credit: number,
        userId?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};
