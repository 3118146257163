import { useAppSelector } from '../app/hooks';

export interface UseAuthHookResponse {
  authorized: boolean;
}

const useAuth = (): UseAuthHookResponse => {
  const { authUser } = useAppSelector((state) => state.user);

  return { authorized: authUser.verified };
};

export default useAuth;
