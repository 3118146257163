import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { ListUsersQuery } from '../../types/Graphql';

import { UserStore } from '../../types/User';
import { mapGraphqlUserToStore } from '../mapGraphqlToStore';

export const listUsersQuery = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, nextToken: $nextToken) {
      items {
        firstName
        lastName
        avatarUrl
        countryCode
        disabled
        email
        id
        languageCode
        userableId
        userableType
        regularUser {
          unPackOnArrival
          phone
          userId
          locker
          credit
          id
          addresses {
            id
            addressLine1
            addressLine2
            city
            countryCode
            firstName
            lastName
            phone
            state
            zipCode
            email
          }
        }
      }
      nextToken
    }
  }
`;

type GetUser = (email: string) => Promise<UserStore>;

const getUser: GetUser = async (email) => {
  let nextToken = null;
  let result;
  do {
    result = (await listUsers(
      email,
      nextToken
    )) as GraphQLResult<ListUsersQuery>;
    nextToken =
      result?.data?.listUsers?.items?.length === 0
        ? result?.data?.listUsers?.nextToken
        : null;
  } while (nextToken);

  const user = result?.data?.listUsers?.items?.[0];

  return mapGraphqlUserToStore(user);
};

const listUsers = (email: string, nextToken: string | null) => {
  return API.graphql(
    graphqlOperation(listUsersQuery, {
      filter: {
        email: {
          eq: email,
        },
      },
      nextToken,
    })
  );
};

export default getUser;
