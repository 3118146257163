import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import { GetLockerQuery } from '../../graphql/API';

export const createLockerQuery = /* GraphQL */ `
  query GetLocker {
    getLocker
  }
`;

type CreateLocker = () => Promise<string>;

const createLocker: CreateLocker = async () => {
  const res = (await API.graphql(
    graphqlOperation(createLockerQuery)
  )) as GraphQLResult<GetLockerQuery>;

  return res.data?.getLocker || '';
};

export default createLocker;
