import { configureStore } from '@reduxjs/toolkit';

import itemsReducer from './features/itemsSlice';
import userReducer from './features/userSlice';
import packingReducer from './features/packingSlice';
import packagesReducer from './features/packagesSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    items: itemsReducer,
    packing: packingReducer,
    packages: packagesReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
