import logo from '../../library/asset/images/logo.svg';

const About = () => {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <div className="text-center">
          <div className="flex">
            <img src={logo} alt="Logo" className="h-24" />
            <h1 className="self-center ml-3 text-4xl font-bold text-gray-900 dark:text-white">
              Packeit
            </h1>
          </div>

          <div>Join</div>
        </div>
        <div className="mt-16 max-w-5xl mx-auto">
          <img src="https://via.placeholder.com/1200" alt="QR Code" />
        </div>
        <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 md:gap-16 md:mt-16">
          <div>
            <div>
              <h3 className="font-extrabold text-2xl m-0 text-gray-900 dark:text-white">
                Overview
              </h3>
              <p className="text-gray-900 dark:text-white font-normal text-lg mt-2 m-0">
                Welcome to our parcel forwarding company! We are a team of
                dedicated professionals who are passionate about helping
                customers ship their packages from one country to another with
                ease and convenience. Our company provides customers with a
                virtual address in the country where their packages will be
                shipped to, which they can use to have their packages delivered.
                Once we receive the packages, we take care of everything else,
                including package consolidation, repackaging, and shipment
                tracking. We understand that shipping items internationally can
                be a hassle, which is why we strive to make the process as
                simple and stress-free as possible for our customers. At our
                company, we pride ourselves on providing exceptional customer
                service. Our team is available to answer any questions or
                concerns that you may have, and we always go the extra mile to
                ensure that your packages are handled with care and delivered on
                time. Whether you&apos;re a frequent online shopper or need to
                send items to friends and family living abroad, we&apos;re here
                to help. Our goal is to make international shipping accessible
                and affordable for everyone, and we&apos;re committed to
                providing our customers with the best possible experience. Thank
                you for considering our parcel forwarding services. We look
                forward to helping you with all of your shipping needs!
              </p>
            </div>
          </div>
          <div>Background</div>
        </div>
      </div>
    </section>
  );
};

export default About;
