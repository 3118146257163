import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PayloadAction } from '@reduxjs/toolkit';

import SignUpForm, { Inputs } from '../../library/components/SignUpForm';
import { useAppDispatch } from '../../app/hooks';
import { signupUser, updateUser } from '../../app/features/userSlice';
import { Errors } from '../../library/services/User/signup';

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .email('Must be a valid email address')
      .lowercase()
      .trim()
      .required('Email is required'),
    password: yup.string().required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    country: yup.string().required('Country is required'),
    acceptTermAndPrivacy: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and conditions'),
  })
  .required();

const SignupStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    dispatch(
      updateUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        countryCode: data.country,
      })
    );

    const { payload }: PayloadAction<Errors | undefined | any> = await dispatch(
      signupUser({ email: data.email, password: data.password })
    );

    if (payload) {
      if (payload.email) {
        setError('email', { type: 'manual', message: payload.email });
      } else if (payload.password) {
        setError('password', {
          type: 'manual',
          message: payload.password,
        });
      }
    }
  };

  return (
    <SignUpForm
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      register={register}
      errors={errors}
    />
  );
};

export default SignupStep;
