import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import { ItemStatus } from '../../graphql/API';
import { GraphqlItem } from '../../types/Graphql';
import { ItemStore } from '../../types/Item';
import { mapGraphqlItemToStore } from '../mapGraphqlToStore';

export const listItemsQuery = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        locker
        barcode
        sender
        carrier
        weight
        createdAt
        dimensions {
          width
          height
          length
        }
        lithiumBattery
        options {
          removeShoeBox
          bubbleWrap
        }
        imageUrls
        note
        description {
          description
          price
          quantity
        }
      }
      nextToken
    }
  }
`;

type ListItemsQuery = {
  listItems: {
    items: GraphqlItem[];
    nextToken: string;
  };
};

type ListItems = (locker: string) => Promise<ItemStore[]>;

const listItems: ListItems = async (locker) => {
  let nextToken = null;
  let result;
  do {
    result = (await mapItems(
      locker,
      nextToken
    )) as GraphQLResult<ListItemsQuery>;
    nextToken =
      result?.data?.listItems?.items?.length === 0
        ? result?.data?.listItems?.nextToken
        : null;
  } while (nextToken);

  const items = result.data?.listItems?.items || [];

  return items.map((i) => mapGraphqlItemToStore(i));
};

const mapItems = (locker: string, nextToken: string | null) => {
  return API.graphql(
    graphqlOperation(listItemsQuery, {
      filter: {
        status: { eq: ItemStatus.InWarehouse },
        locker: { eq: locker },
      },
      nextToken,
    })
  );
};

export default listItems;
