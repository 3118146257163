import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ShippingMethod from '../../library/components/ShippingMethod';
import { GraphqlShippingRate } from '../../library/types/Graphql';

type ShippingRatesProps = {
  rates: GraphqlShippingRate[];
  quote: boolean;
  setName?: (name: string) => void;
};

const ShippingRates: React.FC<ShippingRatesProps> = ({
  rates,
  quote,
  setName = () => {},
}) => {
  const [selectedRate, setSelectedRate] = useState<string | undefined>(
    undefined
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRate(e.target.value);
    setName(e.target.value);
  };

  return (
    <div className="flex flex-col w-full">
      {rates.map((rate) => (
        <ShippingMethod
          key={uuidv4()}
          rate={rate}
          quote={quote}
          selectedRate={selectedRate}
          handleChange={handleChange}
        />
      ))}
    </div>
  );
};

export default ShippingRates;
