import { useTranslation } from 'react-i18next';

import { AddressStore } from '../../library/types/Address';

type AddressCardProps = {
  address: AddressStore;
};

const AddressPreviewCard: React.FC<AddressCardProps> = ({ address }) => {
  const { t } = useTranslation();

  return (
    <div className="my-3 p-2  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          {t('Address')}
        </h1>
      </div>
      <div className="p-4 space-y-4">
        <div className="grid grid-cols-6 gap-2">
          <div className="col-span-12 sm:col-span-6">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Name')}
              <input
                type="text"
                name="name"
                id="name"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={`${address.firstName} ${address.lastName}`}
                disabled
              />
            </label>
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label
              htmlFor="addressLineOne"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Address 1')}
              <input
                type="text"
                name="addressLineOne"
                id="addressLineOne"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={address.addressLine1}
                disabled
              />
            </label>
          </div>
          {address.addressLine2 && (
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="addressLineTwo"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {t('Address 2')}
                <input
                  type="text"
                  name="addressLineTwo"
                  id="addressLineTwo"
                  className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={address.addressLine2}
                  disabled
                />
              </label>
            </div>
          )}
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="City"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('City')}
              <input
                type="text"
                name="City"
                id="City"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={`${address.city} ${address.state}`}
                disabled
              />
            </label>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="postalCode"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Postal Code')}
              <input
                type="text"
                name="postalCode"
                id="postalCode"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={address.zipCode}
                disabled
              />
            </label>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="country"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Country')}
              <input
                type="text"
                name="country"
                id="country"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={address.countryCode}
                disabled
              />
            </label>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="phone"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Phone Number')}
              <input
                type="text"
                name="phone"
                id="phone"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={address.phone}
                disabled
              />
            </label>
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Email')}
              <input
                type="text"
                name="email"
                id="email"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={`${address.email}`}
                disabled
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressPreviewCard;
