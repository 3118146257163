import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { isEqual } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import AddressPreviewModal from './AddressPreviewModal';
import AddressPreviewCard from './AddressPreviewCard';
import { LithiumBattery } from '../../library/graphql/API';
import ItemsField from './ItemsField';
import { Description } from '.';
import { itemUpdate, nextStep } from '../../app/features/packingSlice';
import Alert from '../../library/components/Alert/Alert';

export type ItemsInput = {
  items: {
    itemId: string | undefined;
    description: { description: string; quantity: number; price: number }[];

    battery: LithiumBattery | undefined;
    shockProof: boolean | undefined;
    shoeBox: boolean | undefined;
  }[];
};

const schema = yup.object().shape({
  items: yup.array<ItemsInput>().of(
    yup.object().shape({
      itemId: yup.string(),
      description: yup.array<Description>().of(
        yup.object().shape({
          description: yup.string().required('Description is required'),
          quantity: yup
            .number()
            .moreThan(0, 'Must be greater than 0')
            .required('Quantity is required'),
          price: yup
            .number()
            .moreThan(0, 'Must be greater than 0')
            .required('Price is required'),
        })
      ),
      battery: yup.string().required('Battery is required'),
      shockProof: yup.boolean(),
      shoeBox: yup.boolean(),
    })
  ),
});

const PackageInformation = () => {
  const navigate = useNavigate();

  const {
    package: { items, address },
  } = useAppSelector((state) => state.packing);
  const dispatch = useAppDispatch();

  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);

  if (items === undefined || items.length === 0) {
    return null;
  }

  const defaultValues = {
    items: items.map((item) => ({
      itemId: item.id,
      description: item.description || [
        { description: '', quantity: 0, price: 0 },
      ],
      battery: item.lithiumBattery,
      shockProof: item.options?.bubbleWrap,
      shoeBox: item.options?.removeShoeBox,
    })),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm<ItemsInput>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleCloseModal = () => {
    setShowAddressModal(false);
  };

  if (items === undefined || items.length === 0) {
    return null;
  }

  const onSubmit: SubmitHandler<ItemsInput> = (data) => {
    if (!address || address?.id.length < 1) {
      setShowAlert(true);
      return;
    }
    data.items.forEach(async (item) => {
      const oldItem = items.find((i) => i.id === item.itemId);
      if (!oldItem) return;
      if (
        !isEqual(
          {
            description: oldItem.description,
            battery: oldItem.lithiumBattery,
            shockProof: oldItem.options?.bubbleWrap || false,
            shoeBox: oldItem.options?.removeShoeBox || false,
            itemId: oldItem.id,
          },
          item
        )
      ) {
        await dispatch(
          itemUpdate({
            ...oldItem,
            description: item.description,
            options: {
              bubbleWrap: item.shockProof,
              removeShoeBox: item.shoeBox,
            },
            lithiumBattery: item.battery,
          })
        );
      }
    });
    dispatch(nextStep());
  };

  return (
    <>
      <div className="mb-4 w-full rounded-lg bg-white px-3 py-4 shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0">
        <h4 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          Delivery Address
        </h4>
        <hr />
        {address && address.id.length > 0 ? (
          <>
            <AddressPreviewCard address={address} />
            <hr />
          </>
        ) : null}
        <div className="flex flex-col items-center justify-center">
          <button
            className="mt-4 inline-flex items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 sm:mt-6"
            type="button"
            onClick={() => setShowAddressModal(true)}
          >
            Please choose and Address
          </button>
        </div>
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <ItemsField
          control={control}
          register={register}
          errors={errors}
          defaultValues={defaultValues}
          setValue={setValue}
          getValues={getValues}
        />

        <div className="flex w-full flex-row items-center justify-center gap-2">
          <button
            className=" mt-4 w-1/2 items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 sm:mt-6"
            type="button"
            onClick={() => navigate(-1)}
          >
            Previous
          </button>
          <button
            className=" mt-4 w-1/2 items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 sm:mt-6"
            type="submit"
          >
            Next
          </button>
        </div>
      </form>
      <AddressPreviewModal show={showAddressModal} onClose={handleCloseModal} />
      <Alert type="info" show={showAlert} setShow={setShowAlert} timeout={3000}>
        You need to select an address before you continue.
      </Alert>
    </>
  );
};

export default PackageInformation;
