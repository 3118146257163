import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import Table from '../../library/components/Table';
import { PackageStore } from '../../library/types/Package';
import {
  getDate,
  getDimensions,
  getPackageItemsOptions,
  getPackageItemsOptionsFees,
  getPackageOptions,
  getPackageOptionsFees,
  lbsToKg,
} from '../../library/utils/helper';
import PackageAddress from './PackageAddress';
import ShippedPackageDetailsRow from './ShippedPackageDetailsRow';

export type PackageDetailsProps = {
  shipment: PackageStore;
};

type PackageCost = {
  description: string;
  value: string;
};

type ItemsReceived = {
  barcode: string;
  sender: string;
  quantity: number;
};

const costColumnHelper = createColumnHelper<PackageCost>();
const itemsColumnHelper = createColumnHelper<ItemsReceived>();

const ShippedPackageDetails: React.FC<PackageDetailsProps> = ({ shipment }) => {
  const { t } = useTranslation();

  const cost: {
    items: PackageCost[];
    total: number;
  } = useMemo(() => {
    let total = 0;
    const items: PackageCost[] = [];
    shipment?.items?.forEach((item) => {
      if (item.options) {
        items.push(...getPackageItemsOptions(item.options));
        total += getPackageItemsOptionsFees(item.options);
      }
    });

    const options = shipment?.options
      ? getPackageOptions(shipment?.options)
      : [];

    if (shipment?.shippingMethod?.rate) {
      items.push({
        description: `Shipping (${shipment?.shippingMethod.info?.name})`,
        value: `${shipment?.shippingMethod.rate.toFixed(2)}`,
      });
    }

    total += shipment?.shippingMethod?.rate || 0;

    total += shipment?.options ? getPackageOptionsFees(shipment?.options) : 0;
    return {
      items: [...options, ...items],
      total,
    };
  }, [shipment]);

  const items: ItemsReceived[] = useMemo(() => {
    return shipment.description.map((item) => ({
      barcode: item.barcode,
      sender: item.sender,
      quantity: item.quantity,
    }));
  }, [shipment]);

  const costColumns = [
    costColumnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => info.getValue(),
      header: 'Description',
      footer: 'Total value',
    }),
    costColumnHelper.accessor((row) => row.value, {
      id: 'price',
      cell: (info) => `$${info.getValue()}`,
      header: 'Price',
      footer: `$${cost.total.toFixed(2)}`,
    }),
  ];

  const itemsColumns = [
    itemsColumnHelper.accessor((row) => row.barcode, {
      id: 'barcode',
      cell: (info) => info.getValue(),
      header: 'Barcode',
    }),
    itemsColumnHelper.accessor((row) => row.sender, {
      id: 'store',
      cell: (info) => info.getValue(),
      header: 'Store',
    }),
    itemsColumnHelper.accessor((row) => row.quantity, {
      id: 'quantity',
      cell: (info) => info.getValue(),
      header: 'Quantity',
    }),
  ];

  return (
    <div className="mb-4 w-full rounded-lg bg-white px-3 py-4 shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0">
      <h4 className="mb-6 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
        {t('Package #', { id: shipment.id })}
      </h4>
      <ShippedPackageDetailsRow
        title="Shipped"
        value={shipment.requestedOn ? getDate(shipment.requestedOn) : ''}
      />
      <ShippedPackageDetailsRow title="Track Number">
        <p>Some Link</p>
      </ShippedPackageDetailsRow>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <div className="flex flex-col gap-y-2">
          <img
            className="w-80 rounded-lg"
            src="https://via.placeholder.com/150"
            alt="QR Code"
          />
          <div className="flex flex-row gap-x-1">
            <img
              className="w-24 rounded-lg"
              src="https://via.placeholder.com/150"
              alt=""
            />
            <img
              className="w-24 rounded-lg"
              src="https://via.placeholder.com/150"
              alt=""
            />
            <img
              className="w-24 rounded-lg"
              src="https://via.placeholder.com/150"
              alt=""
            />
          </div>
        </div>
        <div>
          {shipment.address && <PackageAddress address={shipment.address} />}

          <ShippedPackageDetailsRow
            title="Dimensions"
            value={shipment.dimension ? getDimensions(shipment.dimension) : ''}
          />

          <ShippedPackageDetailsRow
            title="Weight"
            value={shipment.weight ? lbsToKg(shipment.weight) : ''}
          />
          <ShippedPackageDetailsRow
            title="Ordered On"
            value={shipment.requestedOn ? getDate(shipment.requestedOn) : ''}
          />
          <ShippedPackageDetailsRow
            title="Packed On"
            value={shipment.packedOn ? getDate(shipment.packedOn) : ''}
          />

          <ShippedPackageDetailsRow
            title="Shipment method"
            value={shipment.shippingMethod?.info?.name}
          />

          <ShippedPackageDetailsRow title="Invoice">
            <p>{t('Download Link')}</p>
          </ShippedPackageDetailsRow>
          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 ">
            <p className="mb-2  font-bold tracking-tight text-gray-900 dark:text-white">
              {t('Custom Declaration')}
            </p>
            <p>{t('Download Link')}</p>
          </div>
        </div>
      </div>
      <hr className="my-2" />
      <div className="mb-4 grid gap-4 sm:grid-cols-3">
        <h2 className=" text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {t('Cost')}
        </h2>
        <div className="col-span-2">
          <Table data={cost.items} columns={costColumns} showFooter />
        </div>
      </div>
      <hr className="my-2" />
      <div className="mb-4 grid gap-4 sm:grid-cols-3">
        <h2 className=" text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {t('Items')}
        </h2>
        <div className="col-span-2">
          <Table data={items} columns={itemsColumns} />
        </div>
      </div>
    </div>
  );
};

export default ShippedPackageDetails;
