import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import ShippingMethodDescriptionRow from './ShippingMethodDescriptionRow';

type ShippingMethodBodyProps = {
  deliveryTime: string;
  maxWeight: string;
  dimensionalWeight: boolean;
  maxSize: string;
  tracking: boolean;
  frequencyOfDeparture: string;
};

const ShippingMethodBody: React.FC<ShippingMethodBodyProps> = ({
  deliveryTime,
  maxWeight,
  dimensionalWeight,
  maxSize,
  tracking,
  frequencyOfDeparture,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ShippingMethodDescriptionRow
        title={t('Estimated delivery time')}
        description={deliveryTime}
      />
      <ShippingMethodDescriptionRow
        title={t('Maximum weight')}
        description={maxWeight}
      />
      <ShippingMethodDescriptionRow
        title={t('Dimensional weight')}
        description={dimensionalWeight ? t('Applicable') : t('Not applicable')}
      />
      <ShippingMethodDescriptionRow
        title={t('Maximum Size')}
        description={maxSize || t('Not restricted')}
      />

      <div className="mx-2 grid p-2 sm:grid-cols-2 sm:gap-6 border-b mb-2">
        <p className="tracking-tight font-bold text-gray-900 dark:text-white">
          {t('Tracking')}
        </p>
        {tracking ? (
          <CheckIcon className=" text-green-700 dark:text-green-300 w-6 h-6 sm:justify-self-end" />
        ) : (
          <XMarkIcon className=" text-red-700 dark:text-red-300 w-6 h-6 sm:justify-self-end" />
        )}
      </div>

      <ShippingMethodDescriptionRow
        title={t('Frequency of departure')}
        description={frequencyOfDeparture}
      />
    </>
  );
};

export default ShippingMethodBody;
