import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import { CreatePackageInput, CreatePackageMutation } from '../../graphql/API';
import { PackageStore } from '../../types/Package';

export const createPackageQuery = /* GraphQL */ `
  mutation CreatePackage(
    $input: CreatePackageInput!
    $condition: ModelPackageConditionInput
  ) {
    createPackage(input: $input, condition: $condition) {
      id
    }
  }
`;

type MapPackageToInput = (p: PackageStore) => CreatePackageInput;

const mapPackageToInput: MapPackageToInput = (p) => ({
  status: p.status,
  type: 'Package',
  options: p.options,
  requestedOn: p.requestedOn,
  address: p.address,
  locker: p.locker,
  userId: p.userId,
  description: p.description,
});

type CreatePackage = (p: PackageStore) => Promise<string>;

const createPackage: CreatePackage = async (p: PackageStore) => {
  const res = (await API.graphql(
    graphqlOperation(createPackageQuery, { input: mapPackageToInput(p) })
  )) as GraphQLResult<CreatePackageMutation>;

  return res.data?.createPackage?.id || '';
};

export default createPackage;
