import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import {
  PaymentName,
  UpdatePackageInput,
  UpdatePackageMutation,
} from '../../graphql/API';
import { PackageStore } from '../../types/Package';

export const updatePackageQuery = /* GraphQL */ `
  mutation UpdatePackage(
    $input: UpdatePackageInput!
    $condition: ModelPackageConditionInput
  ) {
    updatePackage(input: $input, condition: $condition) {
      id
    }
  }
`;

type MapPackageToInput = (p: PackageStore) => UpdatePackageInput;

const mapPackageToInput: MapPackageToInput = (p) => ({
  id: p.id || '',
  status: p.status,
  shippingMethod: p.shippingMethod,
  address: p.address,
  locker: p.locker,
  payment: {
    id: p.payment?.id || '',
    name: p.payment?.name || PaymentName.PayPal,
    information: JSON.stringify(p.payment?.information),
    userId: p.payment?.userId || '',
  },
});

type PayPackage = (p: PackageStore) => Promise<string>;

const payPackage: PayPackage = async (p: PackageStore) => {
  const res = (await API.graphql(
    graphqlOperation(updatePackageQuery, { input: mapPackageToInput(p) })
  )) as GraphQLResult<UpdatePackageMutation>;

  return res.data?.updatePackage?.id || '';
};

export default payPackage;
