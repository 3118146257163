import { FieldErrorsImpl } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Inputs } from './index';
import { listAllAcceptedCountries } from '../../services/Countries';
import logo from '../../asset/images/logo.svg'

type SignupProps = {
  handleSubmit: (callback: (data: any) => void) => (e: any) => void;
  onSubmit: (data: any) => void;
  register: UseFormRegister<Inputs>;
  errors: FieldErrorsImpl<Inputs>;
};

const countries = listAllAcceptedCountries('en');

export const CountriesList: React.FC = () => {
  return (
    <>
      {Object.keys(countries).map((item) => (
        <option key={item} value={item}>
          {/** @ts-ignore:next-line */}
          {countries[item]}
        </option>
      ))}
    </>
  );
};

const SignUpForm: React.FC<SignupProps> = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-auto lg:py-8">
        <Link
          to="/"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            className="w-10 h-10 mr-2"
            src={logo}
            alt="logo"
          />
          Packeit
        </Link>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              {t('Create an account')}
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {t('First Name')}
                    <input
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="John"
                      {...register('firstName')}
                    />
                    {errors.firstName && (
                      <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                        <span className=" font-medium">
                          {t(`${errors.firstName?.message}`)}
                        </span>
                      </p>
                    )}
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {t('Last Name')}
                    <input
                      type="text"
                      id="last_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Doe"
                      {...register('lastName')}
                    />
                    {errors.lastName && (
                      <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                        <span className=" font-medium">
                          {t(`${errors.lastName?.message}`)}
                        </span>
                      </p>
                    )}
                  </label>
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {t('Your email')}
                  <input
                    type="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="john.doe@company.com"
                    {...register('email')}
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.email?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {t('Password')}
                  <input
                    type="password"
                    id="password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="•••••••••"
                    {...register('password')}
                  />
                  {errors.password && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.password?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="confirm_password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {t('Confirm password')}
                  <input
                    type="password"
                    id="confirm_password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="•••••••••"
                    {...register('confirmPassword')}
                  />
                  {errors.confirmPassword && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.confirmPassword?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  {t('Country of Residence')}
                  <select
                    id="countries"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('country')}
                  >
                    <option value="" hidden>
                      {t('Choose a country')}
                    </option>
                    <CountriesList />
                  </select>
                  {errors.country && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.country?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>
              <div className="flex items-start mb-6">
                <div className="flex items-center h-5">
                  <input
                    id="acceptTermAndPrivacy"
                    type="checkbox"
                    {...register('acceptTermAndPrivacy')}
                    className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  />
                </div>
                <label
                  htmlFor="acceptTermAndPrivacy"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  {t(
                    'By signing up, you are creating a Packeit account, and you agree to Packeit'
                  )}
                  <Link
                    to="/terms"
                    className="text-blue-600 hover:underline dark:text-blue-500 px-1"
                  >
                    {t('Terms of Use')}
                  </Link>
                  {t('and')}
                  <Link
                    to="/privacy"
                    className="text-blue-600 hover:underline dark:text-blue-500 pl-1"
                  >
                    {t('Privacy Policy')}
                  </Link>
                  .
                </label>
              </div>
              {errors.acceptTermAndPrivacy && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t(`${errors.acceptTermAndPrivacy?.message}`)}
                  </span>
                </p>
              )}
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {t('Create an account')}
              </button>
            </form>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              {t('Already have an account?')}
              <Link
                to="/login"
                className="font-medium text-primary-600 hover:underline dark:text-primary-500 px-1"
              >
                {t('Login here')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUpForm;
