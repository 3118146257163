import React, { useEffect } from 'react';
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import ItemInfoCard from '../../library/components/ItemInfoCard';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { lbsToKg, getDate } from '../../library/utils/helper';
import { update } from '../../app/features/itemsSlice';
import { ItemStatus, LithiumBattery } from '../../library/graphql/API';

type ModalProps = {
  onClose: () => void;
  show: boolean;
  id: string;
};

export type Input = {
  description: { description: string; quantity: number; price: number }[];
  battery: string;
};

const schema = yup.object().shape({
  description: yup.array().of(
    yup.object().shape({
      description: yup.string().required('Description is required'),
      quantity: yup
        .number()
        .moreThan(0, 'Must be greater than 0')
        .required('Quantity is required'),
      price: yup
        .number()
        .moreThan(0, 'Must be greater than 0')
        .required('Price is required'),
    })
  ),
  battery: yup.string().required('Battery is required'),
});

const ItemPreviewModal: React.FC<ModalProps> = ({ show, onClose, id }) => {
  const { items } = useAppSelector((state) => state.items);
  const dispatch = useAppDispatch();

  const item = items.find((i) => i.id === id);

  useEffect(() => {
    reset({
      description: item?.description?.length
        ? item.description
        : [{ description: '', quantity: 0, price: 0 }],
      battery: item?.lithiumBattery,
    });
  }, [item?.description]);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Input>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'description',
    control,
  });

  const onSubmit: SubmitHandler<Input> = async (data) => {
    const newItem = {
      ...item,
      description: data.description,
      lithiumBattery: LithiumBattery[data.battery as LithiumBattery],
    };
    await dispatch(update(newItem));
    onClose();
  };

  const onUnbox = async () => {
    const newItem = {
      ...item,
      status: ItemStatus.RequestedToBeUnPacked,
    };
    await dispatch(update(newItem));
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose} position="center">
      <Modal.Header className="bg-white dark:bg-gray-800">
        {t('Received Item #', { barcode: item?.barcode })}
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="bg-white h-96 overflow-y-auto dark:bg-gray-800">
          <div className="grid grid-cols-4 gap-12 mb-4">
            {item?.imageUrls.map((url) => (
              <div
                key={uuidv4()}
                className=" relative rounded-lg p-2 h-36 w-36 bg-gray-50 dark:bg-gray-700"
              >
                <img
                  src={url}
                  alt="item"
                  className="absolute inset-0 w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
          </div>
          <dl className="grid grid-cols-3 mb-5 gap-4">
            <ItemInfoCard title="Carrier" description={item?.carrier || ''} />
            <ItemInfoCard
              title="Date"
              description={item?.createdAt ? getDate(item?.createdAt) : ''}
            />
            <ItemInfoCard
              title="Weight"
              description={lbsToKg(item?.weight || 0)}
            />
            <ItemInfoCard title="Barcode" description={item?.barcode || ''} />
          </dl>
          <div className="mb-4">
            <label
              htmlFor="battery"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Does it contain lithium batteries?')}
              <select
                id="battery"
                className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                {...register('battery')}
              >
                <option value={LithiumBattery.NO}>
                  {t('No, it does not')}
                </option>
                <option value={LithiumBattery.YES}>
                  {t('Yes, battery backup, power bank or loose battery')}
                </option>
                <option value={LithiumBattery.YES_REMOVABLE}>
                  {t('Yes, batteries are removable, but attached to the item')}
                </option>
                <option value={LithiumBattery.YES_PERMANENT}>
                  {t('Yes, batteries are permanently inside device')}
                </option>
              </select>
            </label>
          </div>

          {fields.map((field, index) => (
            <div className="flex flex-row space-x-4 mb-4" key={field.id}>
              <div>
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {t('Description')}
                  <input
                    type="text"
                    className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Type product name"
                    {...register(`description.${index}.description` as const)}
                  />
                  {errors.description?.[index]?.description && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(
                          `${errors.description?.[index]?.description?.message}`
                        )}
                      </span>
                    </p>
                  )}
                </label>
              </div>
              <div>
                <label
                  htmlFor="quantity"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {t('Quantity')}
                  <input
                    type="number"
                    className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Product brand"
                    {...register(`description.${index}.quantity` as const, {
                      valueAsNumber: true,
                    })}
                  />
                  {errors.description?.[index]?.quantity && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.description?.[index]?.quantity?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {t('Total Price')}
                  <div className="flex mt-2">
                    <span className="inline-flex items-center px-2 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      $
                    </span>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-none rounded-r-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="$2999"
                      {...register(`description.${index}.price` as const, {
                        valueAsNumber: true,
                      })}
                    />
                  </div>
                  {errors.description?.[index]?.price && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.description?.[index]?.price?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>
              {index !== 0 ? (
                <button type="button" onClick={() => remove(index)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="mr-1 -ml-1 w-6 h-6 text-red-600 focus:ring-4 focus:outline-none focus:ring-red-200 font-medium  dark:focus:ring-red-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              ) : null}
            </div>
          ))}
          <div>
            <button
              type="button"
              className="mt-4 inline-flex items-center justify-center px-5 py-2.5 border border-transparent text-base font-medium rounded-lg text-white bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 focus:ring-offset-2 focus:ring-offset-gray-50 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              onClick={() =>
                append({
                  description: '',
                  quantity: 0,
                  price: 0,
                })
              }
            >
              {t('Add a Line')}
            </button>
          </div>
        </Modal.Body>

        <Modal.Footer className="bg-white dark:bg-gray-800">
          <div className="w-full flex justify-between items-center">
            <button
              type="submit"
              className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              <svg
                aria-hidden="true"
                className="mr-1 -ml-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                <path
                  fillRule="evenodd"
                  d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                  clipRule="evenodd"
                />
              </svg>
              {t('Save')}
            </button>
            <div className="flex items-center space-x-3 sm:space-x-4">
              {/* TODO: fix return */}
              {/* <button
                type="button"
                className="inline-flex items-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="mr-1 -ml-1 w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                  />
                </svg>
                Return
              </button> */}
              <button
                type="button"
                className="inline-flex items-center text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-700"
                onClick={onUnbox}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mr-1.5 -ml-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>
                {t('Unbox Item')}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ItemPreviewModal;
