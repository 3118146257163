import React from 'react';

type ItemInfoCardProps = {
  title: string;
  description: string;
};

const ItemInfoCard: React.FC<ItemInfoCardProps> = ({ title, description }) => {
  return (
    <div className="p-3 rounded-lg bg-gray-50 dark:bg-gray-700">
      <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
        {title}
      </dt>
      <dd className="font-light text-gray-500 dark:text-gray-400">
        {description}
      </dd>
    </div>
  );
};

export default ItemInfoCard;
