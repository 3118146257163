import { useState, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { load } from '../../app/features/itemsSlice';
import ItemCard, { ItemCardSkeleton } from '../../library/components/ItemCard';
import ItemPreviewModal from './ItemPreviewModal';
import { ItemStatus } from '../../library/graphql/API';
import { packageItems } from '../../app/features/packingSlice';
import { PACK_ROUTE } from '../../routes';
import Alert from '../../library/components/Alert';

const Items: React.FC = () => {
  const { items, loading } = useAppSelector((state) => state.items);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [showItemInfo, setShowItemInfo] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>('');

  const [packaging, setPackaging] = useState<string[]>([]);

  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const currentItems = items.filter(
    (item) => item.status === ItemStatus.InWarehouse
  );

  useEffect(() => {
    const loadItems = async () => {
      await dispatch(load());
    };
    loadItems();
  }, []);

  const handlePreview = (id: string) => {
    setSelectedItem(id);
    setShowItemInfo(true);
  };

  const handleCloseModal = () => {
    setSelectedItem('');
    setShowItemInfo(false);
  };

  const handleAddAllToPackage = () => {
    setPackaging((prevValue) =>
      isEqual(
        prevValue,
        currentItems.map((item) => item.id || '')
      )
        ? []
        : currentItems.map((item) => item.id || '')
    );
  };

  const handlePackItems = () => {
    if (packaging.length === 0) {
      setShowAlert(true);
      return;
    }

    dispatch(
      packageItems(items.filter((item) => packaging.includes(item.id || '')))
    );
    navigate(`/${PACK_ROUTE}`);
  };

  const handleAddToPackage = (id: string) => {
    setPackaging((prevValue) =>
      prevValue.includes(id)
        ? prevValue.filter((i) => i !== id)
        : [...prevValue, id]
    );
  };

  if (loading === 'failed') return <div>Failed to load items</div>;

  return (
    <section className="flex-grow bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-auto lg:py-8">
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0 xl:p-0">
          <div className=" flex flex-row items-baseline justify-between p-4 sm:p-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              {t('Received Items')}
            </h1>
            <div className="flex">
              <div className="mx-4 flex items-center">
                <input
                  id="selectAll"
                  onChange={handleAddAllToPackage}
                  type="checkbox"
                  checked={isEqual(
                    packaging,
                    currentItems.map((item) => item.id || '')
                  )}
                  className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-800"
                />
                <label
                  htmlFor="selectAll"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {isEqual(
                    packaging,
                    currentItems.map((item) => item.id || '')
                  )
                    ? t('Remove All Items')
                    : t('Add All Items')}
                </label>
              </div>
              <button
                disabled={packaging.length === 0}
                onClick={handlePackItems}
                type="button"
                className="rounded-lg bg-primary-600 px-8 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {t('Pack')}
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-row flex-wrap justify-around md:items-start">
          <Suspense fallback={<ItemCardSkeleton />}>
            {currentItems.map((item) => (
              <ItemCard
                key={item.id}
                handlePreview={handlePreview}
                image={item.imageUrls[0]}
                id={item.id || ''}
                handleAdd={handleAddToPackage}
                selected={packaging.includes(item.id || '')}
              />
            ))}
          </Suspense>
        </div>
      </div>
      <ItemPreviewModal
        show={showItemInfo}
        onClose={handleCloseModal}
        id={selectedItem}
      />
      <Alert type="info" show={showAlert} setShow={setShowAlert} timeout={3000}>
        {t('No items are selected to be packed')}
      </Alert>
    </section>
  );
};

export default Items;
