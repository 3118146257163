import {
  FieldErrorsImpl,
  useFieldArray,
  UseFieldArrayProps,
  UseFormRegister,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { ItemsInput } from './PackageInformation';

type ItemDescriptionProps = {
  itemIndex: number;
  control: UseFieldArrayProps<ItemsInput>['control'];
  register: UseFormRegister<ItemsInput>;
  errors: FieldErrorsImpl<ItemsInput>;
};

const ItemDescription: React.FC<ItemDescriptionProps> = ({
  itemIndex,
  control,
  register,
  errors,
}) => {
  const { t } = useTranslation();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `items.${itemIndex}.description` as const,
  });

  return (
    <>
      {fields.map((_, index) => {
        return (
          <div className="flex flex-row space-x-4 mb-4" key={uuidv4()}>
            <div>
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {t('Description')}
                <input
                  type="text"
                  id="description"
                  className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type product name"
                  {...register(
                    `items.${itemIndex}.description.${index}.description` as const
                  )}
                />
                {errors.items?.[itemIndex]?.description?.[index]
                  ?.description && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    <span className=" font-medium">
                      {t(
                        `${errors.items?.[itemIndex]?.description?.[index]?.description?.message}`
                      )}
                    </span>
                  </p>
                )}
              </label>
            </div>
            <div>
              <label
                htmlFor="quantity"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {t('Quantity')}
                <input
                  type="number"
                  id="quantity"
                  className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Product brand"
                  {...register(
                    `items.${itemIndex}.description.${index}.quantity` as const,
                    {
                      valueAsNumber: true,
                    }
                  )}
                />
                {errors.items?.[itemIndex]?.description?.[index]?.quantity && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    <span className=" font-medium">
                      {t(
                        `${errors.items?.[itemIndex]?.description?.[index]?.quantity?.message}`
                      )}
                    </span>
                  </p>
                )}
              </label>
            </div>
            <div>
              <label
                htmlFor="price"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {t('Total Price')}
                <input
                  type="number"
                  id="price"
                  className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="$2999"
                  {...register(
                    `items.${itemIndex}.description.${index}.price` as const,
                    {
                      valueAsNumber: true,
                    }
                  )}
                />
                {errors.items?.[itemIndex]?.description?.[index]?.price && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    <span className=" font-medium">
                      {t(
                        `${errors.items?.[itemIndex]?.description?.[index]?.price?.message}`
                      )}
                    </span>
                  </p>
                )}
              </label>
            </div>
            {index !== 0 ? (
              <button type="button" onClick={() => remove(index)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="mr-1 -ml-1 w-6 h-6 text-red-600 focus:ring-4 focus:outline-none focus:ring-red-200 font-medium  dark:focus:ring-red-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            ) : null}
          </div>
        );
      })}
      <div>
        <button
          type="button"
          className="mt-4 inline-flex items-center justify-center px-5 py-2.5 border border-transparent text-base font-medium rounded-lg text-white bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 focus:ring-offset-2 focus:ring-offset-gray-50 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          onClick={() =>
            append({
              description: '',
              quantity: 0,
              price: 0,
            })
          }
        >
          {t('Add a Line')}
        </button>
      </div>
    </>
  );
};

export default ItemDescription;
