import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import {
  UpdateRegularUserInput,
  UpdateRegularUserMutation,
} from '../../graphql/API';
import { RegularUserStore } from '../../types/User';

export const updateRegularUserQuery = /* GraphQL */ `
  mutation UpdateRegularUser(
    $input: UpdateRegularUserInput!
    $condition: ModelRegularUserConditionInput
  ) {
    updateRegularUser(input: $input, condition: $condition) {
      id
    }
  }
`;

type MapStoreRegularUserToGraphql = (
  r: RegularUserStore
) => UpdateRegularUserInput;

const mapStoreRegularUserToGraphql: MapStoreRegularUserToGraphql = (r) => ({
  id: r.id || '',
  userId: r.userId,
  phone: r.phone,
  addresses: r.addresses,
  credit: r.credit,
  unPackOnArrival: r.unPackOnArrival,
  locker: r.locker,
});

type UpdateRegularUser = (r: RegularUserStore) => Promise<string>;

const updateRegularUser: UpdateRegularUser = async (
  regularUser: RegularUserStore
) => {
  const result = (await API.graphql(
    graphqlOperation(updateRegularUserQuery, {
      input: mapStoreRegularUserToGraphql(regularUser),
    })
  )) as GraphQLResult<UpdateRegularUserMutation>;
  return result.data?.updateRegularUser?.id || '';
};

export default updateRegularUser;
