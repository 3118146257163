import { useTranslation } from 'react-i18next';

import { AddressStore } from '../../types/Address';

type AddressCardProps = {
  address: AddressStore;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
};

const AddressCard: React.FC<AddressCardProps> = ({
  address,
  onDelete,
  onEdit,
}) => {
  const { t } = useTranslation();

  return (
    <div className=" my-6 p-4  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
          {t('Address')}
        </h3>
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={() => onDelete(address.id)}
        >
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenoddshadow-sm"
            />
          </svg>
        </button>
      </div>
      <div className="p-6 space-y-6">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Name')}
              <input
                type="text"
                name="name"
                id="name"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={`${address.firstName} ${address.lastName}`}
                disabled
              />
            </label>
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label
              htmlFor="addressLineOne"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Address 1')}
              <input
                type="text"
                name="addressLineOne"
                id="addressLineOne"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={address.addressLine1}
                disabled
              />
            </label>
          </div>
          {address.addressLine2 && (
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="addressLineTwo"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {t('Address 2')}
                <input
                  type="text"
                  name="addressLineTwo"
                  id="addressLineTwo"
                  className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={address.addressLine2}
                  disabled
                />
              </label>
            </div>
          )}
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="City"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('City')}
              <input
                type="text"
                name="City"
                id="City"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={`${address.city} ${address.state}`}
                disabled
              />
            </label>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="postalCode"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Postal Code')}
              <input
                type="text"
                name="postalCode"
                id="postalCode"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={address.zipCode}
                disabled
              />
            </label>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="country"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Country')}
              <input
                type="text"
                name="country"
                id="country"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={address.countryCode}
                disabled
              />
            </label>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="phone"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Phone Number')}
              <input
                type="text"
                name="phone"
                id="phone"
                className="shadow-sm mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={address.phone}
                disabled
              />
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={() => onEdit(address.id)}
        >
          {t('Edit')}
        </button>
      </div>
    </div>
  );
};

export default AddressCard;
