import { Trans, useTranslation } from 'react-i18next';
import { FieldErrorsImpl } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import { VerificationInputs } from './index';
import { State } from '../../types/User';
import logo from '../../asset/images/logo.svg';

type VerifySignupFormProps = {
  handleSubmit: (callback: (data: any) => void) => (e: any) => void;
  onResendVerificationCode: () => void;
  onSubmit: (data: any) => void;
  register: UseFormRegister<VerificationInputs>;
  errors: FieldErrorsImpl<VerificationInputs>;
  state: State;
};

const VerifySignupForm: React.FC<VerifySignupFormProps> = ({
  handleSubmit,
  onResendVerificationCode,
  onSubmit,
  register,
  errors,
  state,
}) => {
  const { t } = useTranslation();
  const { user, authUser } = state;
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-auto lg:py-8">
        <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-10 h-10 mr-2" src={logo} alt="logo" />
          Packeit
        </div>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              {t('Verification Code')}
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-6">
                <label
                  htmlFor="code"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  <Trans
                    i18nKey="Enter the verification code sent to {{email}}."
                    values={{
                      email: user.email ? user.email : authUser?.email,
                    }}
                  />
                  <input
                    type="text"
                    id="code"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="######"
                    {...register('code')}
                  />
                  {errors.code && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.code?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>

              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {t('Verify')}
              </button>
            </form>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              {t('Didnt receive the code?')}
              <span
                onClick={onResendVerificationCode}
                role="button"
                onKeyDown={onResendVerificationCode}
                tabIndex={0}
                className="font-medium text-primary-600 hover:underline dark:text-primary-500 px-1"
              >
                {t('Resend verification Code')}
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifySignupForm;
