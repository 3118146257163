import { useMemo } from 'react';
import { Accordion } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

import { Topic } from './index';

type PolicyContentProp = Pick<Topic, 'title'> & {
  content: string;
};

const Privacy: React.FC = () => {
  const { t } = useTranslation();

  const privacyPolicy: Topic[] = useMemo(
    () =>
      t('[privacy-policy-points]', { returnObjects: true, defaultValue: [] }),
    [t]
  );

  const privacyPolicyContent: React.FC<PolicyContentProp> = ({
    title,
    content,
  }) => {
    return (
      <li key={title}>
        <p className="mb-2 text-gray-500 dark:text-gray-400">{content}</p>
      </li>
    );
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          {t('Privacy Policy')}
        </h2>
        {privacyPolicy.map((topic) => (
          <Accordion flush alwaysOpen>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-lg font-semibold">{topic.title}</p>
              </Accordion.Title>
              <Accordion.Content>
                <ul className="list-disc pl-5 text-gray-500 dark:text-gray-400">
                  {topic.topics.map((item) => {
                    return privacyPolicyContent({
                      title: topic.title,
                      content: item,
                    });
                  })}
                </ul>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        ))}
      </div>
    </section>
  );
};

export default Privacy;
