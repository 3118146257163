import countries from 'i18n-iso-countries';
import _ from 'lodash';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const unSupportedCountriesCodes = [
  'AQ',
  'BQ',
  'CC',
  'CX',
  'HM',
  'IR',
  'NF',
  'SS',
  'TF',
  'UM',
  'YT',
  'AX',
  'BV',
  'CU',
  'EH',
  'IM',
  'KP',
  'SD',
  'SX',
  'TK',
  'XK',
  'BL',
  'BY',
  'CW',
  'GS',
  'IO',
  'MF',
  'SJ',
  'SY',
  'ua-43',
  'YE',
];

export function listAllAcceptedCountries(language: string) {
  const countriesList = countries.getNames(language, { select: 'official' });
  return _.omit(countriesList, unSupportedCountriesCodes);
}

export function listAllUnAcceptedCountries(language: string): string[] {
  const countriesList = countries.getNames(language, { select: 'official' });
  return Object.values(_.pick(countriesList, unSupportedCountriesCodes));
}
