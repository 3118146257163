import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import { GraphqlPackage } from '../../types/Graphql';
import { PackageStore } from '../../types/Package';
import { mapGraphqlPackageToStore } from '../mapGraphqlToStore';

export const listPackagesQuery = /* GraphQL */ `
  query ListPackages(
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address {
          addressLine1
          addressLine2
          city
          countryCode
          email
          firstName
          id
          lastName
          phone
          state
          zipCode
        }
        description {
          description
          price
          quantity
          sender
          barcode
        }
        dimension {
          width
          height
          length
        }
        items {
          items {
            id
            createdAt
            options {
              bubbleWrap
            }
          }
        }
        imageUrls
        status
        options {
          removeBills
          plasticBag
          stretchWrap
          doubleWallBox
          priority
        }
        shippingMethod {
          info {
            name
          }
          rate
        }
        locker
        userId
        requestedOn
        packedOn
        weight
      }
      nextToken
    }
  }
`;

type ListPackagesQuery = {
  listPackages: {
    items: GraphqlPackage[];
    nextToken: string;
  };
};

type ListPackages = (locker: string) => Promise<PackageStore[]>;

const listPackages: ListPackages = async (locker) => {
  let nextToken = null;
  let result;
  do {
    result = (await mapPackages(
      locker,
      nextToken
    )) as GraphQLResult<ListPackagesQuery>;
    nextToken =
      result?.data?.listPackages?.items?.length === 0
        ? result?.data?.listPackages?.nextToken
        : null;
  } while (nextToken);

  const packages = result?.data?.listPackages?.items || [];

  return packages.map((p) => mapGraphqlPackageToStore(p));
};

const mapPackages = (locker: string, nextToken: string | null) => {
  return API.graphql(
    graphqlOperation(listPackagesQuery, {
      filter: { locker: { eq: locker } },
      nextToken,
    })
  );
};

export default listPackages;
