import { FieldErrorsImpl } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { CountriesList } from '../../library/components/SignUpForm';
import { QuoteInput } from '../../pages/Home/Home';

type QuoteProps = {
  handleSubmit: (callback: (data: any) => void) => (e: any) => void;
  onSubmit: (data: any) => void;
  register: UseFormRegister<QuoteInput>;
  errors: FieldErrorsImpl<QuoteInput>;
  unitOfMeasure: string;
};

const Quote: React.FC<QuoteProps> = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  unitOfMeasure,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col content-center border rounded-lg p-4 dark:border-gray-600">
      <div className="flex justify-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          {t('Shipping Cost Calculator')}
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <div>
            <label
              htmlFor="country"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Country')}
              <select
                id="country"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                {...register('country')}
              >
                <option value="" hidden>
                  {t('Choose a country')}
                </option>
                <CountriesList />
              </select>
              {errors.country && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t(`${errors.country?.message}`)}
                  </span>
                </p>
              )}
            </label>
          </div>
          <div>
            <label
              htmlFor="city"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('City')}
              <input
                type="text"
                id="city"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="ex: New York"
                {...register('city')}
              />
              {errors.city && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t(`${errors.city?.message}`)}
                  </span>
                </p>
              )}
            </label>
          </div>
          <div>
            <label
              htmlFor="unitOfMeasure"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Units of measurement')}
              <select
                id="unitOfMeasure"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                {...register('unitOfMeasure')}
              >
                <option value="imperialUnits">{t('Imperial units')}</option>
                <option value="metricUnits">{t('Metric units')}</option>
              </select>
              {errors.unitOfMeasure && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t(`${errors.unitOfMeasure?.message}`)}
                  </span>
                </p>
              )}
            </label>
          </div>
          <div>
            <label
              htmlFor="weight"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Weight')}
              <div className="flex">
                <input
                  type="text"
                  min="0"
                  id="weight"
                  className="rounded-none rounded-l-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  {...register('weight')}
                />
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  {unitOfMeasure === 'imperialUnits' ? 'lbs' : 'kg'}
                </span>
              </div>
              {errors.weight && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t(`${errors.weight?.message}`)}
                  </span>
                </p>
              )}
            </label>
          </div>
          <div>
            <label
              htmlFor="width"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Width')}
              <div className="flex">
                <input
                  type="text"
                  min="0"
                  id="width"
                  className="rounded-none rounded-l-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="10"
                  {...register('width')}
                />
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  {unitOfMeasure === 'imperialUnits' ? 'in' : 'cm'}
                </span>
              </div>
              {errors.width && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t(`${errors.width?.message}`)}
                  </span>
                </p>
              )}
            </label>
          </div>
          <div>
            <label
              htmlFor="depth"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Depth')}
              <div className="flex">
                <input
                  type="text"
                  min="0"
                  id="depth"
                  className="rounded-none rounded-l-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="10"
                  {...register('depth')}
                />
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  {unitOfMeasure === 'imperialUnits' ? 'in' : 'cm'}
                </span>
              </div>
              {errors.depth && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t(`${errors.depth?.message}`)}
                  </span>
                </p>
              )}
            </label>
          </div>
          <div>
            <label
              htmlFor="height"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t('Height')}
              <div className="flex">
                <input
                  type="text"
                  min="0"
                  id="height"
                  className="rounded-none rounded-l-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="10"
                  {...register('height')}
                />
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  {unitOfMeasure === 'imperialUnits' ? 'in' : 'cm'}
                </span>
              </div>
              {errors.height && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t(`${errors.height?.message}`)}
                  </span>
                </p>
              )}
            </label>
          </div>
          <button
            type="submit"
            className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            {t('Get Shipping Rates')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Quote;
