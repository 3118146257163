import { useEffect, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import {
  OnApproveData,
  OnApproveActions,
} from '@paypal/paypal-js/types/components/buttons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks';
import { PackageStore } from '../../library/types/Package';
import { payment } from '../../app/features/packagesSlice';
import { PackageStatus, PaymentName } from '../../library/graphql/API';
import Alert, { AlertState } from '../../library/components/Alert';
import { PACKAGES_ROUTE } from '../../routes';

const style = {
  layout: 'horizontal',
  color: 'gold',
  shape: 'rect',
  label: 'paypal',
};

const PayPalButtonWrapper: React.FC<{
  currency: string;
  showSpinner: boolean;
  total: number;
  currentPackage: PackageStore;
}> = ({ currency, showSpinner, total, currentPackage }) => {
  const dispatch = useAppDispatch();
  const [{ options, isPending }, payPalDispatch] = usePayPalScriptReducer();

  const [showAlert, setShowAlert] = useState(false);
  const [alertState, setAlertState] = useState<AlertState>({
    type: 'info',
    message: '',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const amount = total.toString();

  useEffect(() => {
    payPalDispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency,
      },
    });
  }, [currency]);

  const handleSuccess = async (
    data: OnApproveData,
    actions: OnApproveActions
  ) => {
    try {
      const action = await actions.order?.capture();
      if (action?.status === 'COMPLETED') {
        dispatch(
          payment({
            ...currentPackage,
            status: PackageStatus.Paid,
            payment: {
              id: action.id,
              name: PaymentName.PayPal,
              information: {
                purchase_units: action.purchase_units,
                status: action.status,
                createdAt: action.create_time,
                payer: action.payer,
              },
            },
          })
        );
        setAlertState({
          type: 'success',
          message: 'Your Payment has been successfully completed',
        });
        setShowAlert(true);
        setTimeout(() => {
          navigate(`/${PACKAGES_ROUTE}`);
        }, 3000);
      }
    } catch (error) {
      setAlertState({
        type: 'warning',
        message: 'There was an issue processing your payment, try again later.',
      });
      setShowAlert(true);
    }
  };

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={{
          layout: 'horizontal',
          color: 'gold',
          shape: 'rect',
          label: 'paypal',
          tagline: false,
        }}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={async (_data, actions) => {
          const orderId: any = await actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: currency,
                  value: amount,
                },
              },
            ],
          });
          return orderId;
        }}
        onApprove={handleSuccess}
      />
      <Alert
        type={alertState.type}
        show={showAlert}
        setShow={setShowAlert}
        timeout={3000}
      >
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {t(`${alertState.message}`)}
        </p>
      </Alert>
    </>
  );
};

export default PayPalButtonWrapper;
