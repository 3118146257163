import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import listItems from '../../library/services/Item/listItems';
import { ItemsState, ItemStore } from '../../library/types/Item';
import { State } from '../../library/types/User';
import updateItem from '../../library/services/Item/updateItem';

const initialState: ItemsState = {
  loading: 'idle',
  items: [],
  error: null,
};

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      load.fulfilled,
      (state, action: PayloadAction<ItemStore[]>) => {
        state.loading = 'succeeded';
        state.items = [...action.payload];
      }
    );
    builder.addCase(load.rejected, (state) => {
      state.loading = 'failed';
    });
    builder.addCase(load.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(update.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(update.rejected, (state) => {
      state.loading = 'failed';
    });
    builder.addCase(
      update.fulfilled,
      (state, action: PayloadAction<Partial<ItemStore>>) => {
        state.loading = 'succeeded';
        state.items = state.items.map((i) => {
          if (i.id === action.payload.id) {
            return {
              ...i,
              ...action.payload,
            };
          }
          return i;
        });
      }
    );
  },
});

export const load = createAsyncThunk(
  'items/load',
  async (_, { rejectWithValue, getState }) => {
    const { user } = getState() as { user: State };
    try {
      const items = await listItems(user.user.regularUser?.locker || '');
      return items;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const update = createAsyncThunk(
  'items/update',
  async (item: Partial<ItemStore>, { rejectWithValue }) => {
    try {
      await updateItem(item);
      return item;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default itemsSlice.reducer;
