import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  ForgotPasswordEmailForm,
  ForgotPasswordSubmitForm,
  Input,
} from '../../library/components/ForgotPasswordForm';
import forgotPassword from '../../library/services/User/forgotPassword';
import forgotPasswordSubmit from '../../library/services/User/forgotPasswordSubmit';
import { LOGIN_ROUTE } from '../../routes';
import Alert from '../../library/components/Alert';
import logo from '../../library/asset/images/logo.svg';

const schema = yup.object({
  code: yup.string().required('Code is required'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

type AlertState = {
  type: 'error' | 'info' | 'warning' | 'success';
  message: string;
};

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [alertState, setAlertState] = useState<AlertState>({
    type: 'info',
    message: '',
  });
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Input> = async (data) => {
    const { error } = await forgotPasswordSubmit(
      email,
      data.code,
      data.password
    );

    if (!error) {
      navigate(`/${LOGIN_ROUTE}`);
    } else {
      setAlertState({
        type: 'error',
        message: error,
      });
      setShowAlert(true);
    }
  };

  const handleEmailSubmit = async () => {
    const { error } = await forgotPassword(email.toLocaleLowerCase());

    if (!error) {
      setSubmitted(true);
      setAlertState({
        type: 'success',
        message:
          'We have sent you an email with a code, please check your inbox.',
      });
      setShowAlert(true);
    } else {
      setAlertState({
        type: 'error',
        message: error,
      });
      setShowAlert(true);
    }
  };

  return (
    <section className="flex-grow bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
        <Link
          to="/"
          className="mb-6 flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="mr-2 h-10 w-10" src={logo} alt="logo" />
          Packeit
        </Link>
        <div className="w-full rounded-lg bg-white p-6 shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md sm:p-8 md:mt-0">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
            {t('Forgot your password?')}
          </h2>
          <p className="font-light text-gray-500 dark:text-gray-400">
            {t(
              'Dont fret! Just type in your email and we will send you a code to reset your password!'
            )}
          </p>
          {submitted ? (
            <ForgotPasswordSubmitForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
            />
          ) : (
            <ForgotPasswordEmailForm
              onSubmit={handleEmailSubmit}
              email={email}
              setEmail={setEmail}
            />
          )}
        </div>
      </div>
      <Alert
        type={alertState.type}
        show={showAlert}
        setShow={setShowAlert}
        timeout={3000}
      >
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {t(`${alertState.message}`)}
        </p>
      </Alert>
    </section>
  );
};

export default ForgotPassword;
