import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Topic } from '../Privacy';

type SubTopic = string | Topic;

type SubTopicsProps = {
  subTopics: SubTopic[];
};

const Terms: React.FC = () => {
  const { t } = useTranslation();

  const termOfServices: Topic[] = useMemo(
    () =>
      t(`[terms-of-service]`, {
        returnObjects: true,
        defaultValue: [],
      }),
    [t]
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const SubTopics: React.FC<SubTopicsProps> = ({ subTopics }) => {
    return (
      <>
        {subTopics.map((subTopic) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {typeof subTopic === 'string' ? (
              <li>{subTopic} </li>
            ) : (
              <li>
                {subTopic.title}
                <ul className="list-disc pl-5 text-gray-500 dark:text-gray-400">
                  <SubTopics subTopics={subTopic.topics} />
                </ul>
              </li>
            )}
          </>
        ))}
      </>
    );
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          {t('Terms')}
        </h2>
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          {t(
            'By using the packeit.com site, you are agreeing to be bound by the following terms and conditions (“Terms of Service”).'
          )}
        </p>
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          {t(
            'Packeit LLC (“We”) reserves the right to change the Terms of Services from time to time without notice. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute your consent to such changes.'
          )}
        </p>
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          {t(
            'Violation of any of the terms below will result in the termination of your Account.'
          )}
        </p>

        {termOfServices.map((topic) => (
          <div>
            <h3 className="mt-8 mb-2 text-2xl font-bold text-gray-900 dark:text-white">
              {topic.title}
            </h3>
            <ol className="list-decimal pl-5 text-gray-500 dark:text-gray-400">
              <SubTopics subTopics={topic.topics} />
            </ol>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Terms;
