import {
  AdminPermissions,
  ItemStatus,
  LithiumBattery,
  PackageStatus,
  PaymentName,
  UserableType,
} from '../graphql/API';

export type GraphqlUser = {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  avatarUrl: string;
  countryCode: string;
  languageCode: string;
  userSub?: string;
  userableId: string;
  userableType: UserableType;
  disabled: boolean;
  admin?: GraphqlAdmin;
  regularUser?: GraphqlRegularUser;
};

export type GraphqlRegularUser = {
  id: string;
  locker: string;
  addresses?: GraphqlAddress[];
  phone?: string;
  unPackOnArrival: boolean;
  credit: number;
  userId?: string;
  items?: GraphqlItem[];
  packages?: GraphqlPackage[];
  user?: GraphqlUser;
};

export type GraphqlAddress = {
  id: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  phone: string;
  email: string;
};

export type GraphqlAdmin = {
  id: string;
  adminPermissions?: AdminPermissions;
  userId?: string;
  user?: GraphqlUser;
};

export type GraphqlItem = {
  id: string;
  status: ItemStatus;
  description?: GraphqlItemDescription[];
  dimensions?: GraphqlDimensions;
  weight?: number;
  arrivedOn?: string;
  userSub?: string;
  sender: string;
  carrier: string;
  barcode: string;
  imageUrls: string[];
  restriction?: string;
  createdAt?: string;
  options: GraphqlItemOptions;
  locker: string;
  note?: string;
  packageId?: string;
  userId?: string;
  user?: GraphqlUser;
  package?: GraphqlPackage;
  lithiumBattery?: LithiumBattery;
};

export type GraphqlDimensions = {
  length: number;
  width: number;
  height: number;
};

export type GraphqlItemDescription = {
  description: string;
  price: number;
  quantity: number;
};

export type GraphqlPackageItemDescription = {
  description: string;
  price: number;
  sender: string;
  quantity: number;
  barcode: string;
};

export type GraphqlItemOptions = {
  removeShoeBox?: boolean;
  bubbleWrap?: boolean;
};

export type GraphqlPackage = {
  id: string;
  status: PackageStatus;
  options?: GraphqlPackageOptions;
  requestedOn?: string;
  packedOn?: string;
  dimension?: GraphqlDimensions;
  weight?: number;
  imageUrls: string[];
  shippingMethod?: GraphqlShippingRate;
  address?: GraphqlAddress;
  trackingNumber?: string;
  locker: string;
  payment?: GraphqlPaymentMethod;
  userId?: string;
  createdAt?: string;
  items?: {
    items?: GraphqlItem[];
  };
  user?: GraphqlUser;
  description: GraphqlPackageItemDescription[];
};

export type GraphqlPackageOptions = {
  removeBills?: boolean;
  plasticBag?: boolean;
  stretchWrap?: boolean;
  doubleWallBox?: boolean;
  priority?: boolean;
};

export type ListUsersQuery = {
  listUsers?: {
    items?: GraphqlUser[];
    nextToken?: string;
  };
};

export type ListRegularUsersQuery = {
  listUsers?: {
    items?: GraphqlRegularUser[];
    nextToken?: string;
  };
};

export type GraphqlShippingMethod = {
  carrier?: string;
  deliveryTime?: string;
  dimensionalWeight?: boolean;
  frequencyOfDeparture?: string;
  insurable?: boolean;
  insurance?: string;
  maxSize?: string;
  maxWeight?: string;
  name?: string;
  recommended?: boolean;
  requiresDutiesInAdvance?: boolean;
  tracking?: boolean;
};

export type GraphqlShippingRate = {
  info?: GraphqlShippingMethod;
  rate?: number;
};

export type GraphqlPackageDimension = {
  length?: Number;
  width?: Number;
  height?: Number;
  weight?: Number;
};

export type GraphqlShippingQuote = {
  city?: String;
  countryCode?: String;
  facilityCode?: Number;
  package?: GraphqlPackageDimension;
  postalCode?: String;
};

export type GraphqlPaymentMethod = {
  id?: string;
  name?: PaymentName;
  information?: Object;
  userId?: string;
};

export type EmailResponse = {
  status: number;
  response: string;
};

export const ItemOptionsTitle = {
  removeShoeBox: 'Remove Shoe Box',
  bubbleWrap: 'Bubble Wrap',
};

export const ItemOptionsPrices = {
  removeShoeBox: 0.0,
  bubbleWrap: 5.0,
};

export const PackageOptionsTitle = {
  removeBills: 'Remove Bills',
  plasticBag: 'Plastic Bag',
  stretchWrap: 'Stretch Wrap',
  doubleWallBox: 'Double Wall Box',
  priority: 'Priority',
};

export const PackageOptionsPrices = {
  removeBills: 0.0,
  plasticBag: 5.0,
  stretchWrap: 7.0,
  doubleWallBox: 5.0,
  priority: 5.0,
};
