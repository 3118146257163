import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, Auth } from 'aws-amplify';

import { EmailResponse } from '../../types/Graphql';

const contactUsQuery = /* GraphQL */ `
  query ContactUs($args: String) {
    contactUs(args: $args) {
      status
      response
    }
  }
`;

type GetContactResponse = {
  contactUs: EmailResponse;
};

type ContactUs = (args: string) => Promise<EmailResponse>;

const contactUs: ContactUs = async (args) => {
  let isLoggedIn;
  try {
    isLoggedIn = await Auth.currentAuthenticatedUser();
  } catch (error) {
    isLoggedIn = false;
  }

  const response = (await API.graphql({
    query: contactUsQuery,
    variables: { args },
    authMode: isLoggedIn ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
  })) as GraphQLResult<GetContactResponse>;

  return {
    status: response.data?.contactUs.status || 404,
    response: response.data?.contactUs.response || 'No response',
  };
};

export default contactUs;
