const FIRST_PART_OF_PATH_REGEX: RegExp = /^(?:\/)?(.+?)(?:\/|$)/;
function compareFirstPartOfPaths(path1: string, path2: string) {
  const firstPartOfPath1 = FIRST_PART_OF_PATH_REGEX.exec(path1) ?? '';
  const firstPartOfPath2 = FIRST_PART_OF_PATH_REGEX.exec(path2) ?? '';
  return firstPartOfPath1[1] === firstPartOfPath2[1];
}

export type LocationType = {
  from: {
    pathname: string;
  };
};

export default compareFirstPartOfPaths;
