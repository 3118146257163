import React from 'react';

type ItemImageProps = {
  src: string;
  alt: string;
};

const ItemImage: React.FC<ItemImageProps> = ({ src, alt }) => {
  return (
    <div className="rounded-lg mb-4 p-2 w-80 h-52 max-w-xs bg-gray-50 dark:bg-gray-700">
      <img src={src} alt={alt} className="w-full h-full object-cover" />
    </div>
  );
};

export default ItemImage;
