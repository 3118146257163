import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty, merge } from 'lodash';

import {
  AuthUser,
  Errors,
  UserableType,
  UserStore,
  State,
  RegularUserStore,
} from '../../library/types/User';
import { AddressStore } from '../../library/types/Address';
import {
  completeSignup,
  signup,
  SignupUser,
  verifySignup,
} from '../../library/services/User/signup';
import createRegularUser from '../../library/services/User/createRegularUser';
import login, { Result } from '../../library/services/User/login';
import updateRegularUser from '../../library/services/User/updateRegularUser';
import load, { LoadUserResult } from '../../library/services/User/load';
import createLocker from '../../library/services/regularUser/createLocker';

const initialState: State = {
  isLoading: true,
  status: 'pending_signup',
  errors: undefined,
  user: {
    id: '',
    isLoggedIn: false,
    firstName: '',
    lastName: '',
    email: '',
    avatarUrl: '',
    countryCode: '',
    languageCode: '',
    password: '',
    userableId: '',
    userableType: UserableType.RegularUser,
    disabled: false,
    regularUser: {
      id: '',
      locker: '',
      addresses: [],
      phone: '',
      unPackOnArrival: false,
      credit: 0,
      items: [],
      packages: [],
    },
  },
  authUser: {
    email: '',
    verified: false,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Partial<UserStore>>) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    },
    updateRegularUserAddresses: (
      state,
      action: PayloadAction<AddressStore>
    ) => {
      return {
        ...state,
        user: {
          ...state.user,
          regularUser: {
            ...state.user.regularUser,
            addresses: merge(state.user.regularUser?.addresses, action.payload),
          },
        },
      };
    },
    updateState: (
      state,
      action: PayloadAction<{
        user: Partial<UserStore> | null;
        authUser: Partial<AuthUser> | null;
      }>
    ) => {
      const { user, authUser } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          ...user,
        },
        authUser: {
          ...state.authUser,
          ...authUser,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      signupUser.fulfilled,
      (state, { payload }: PayloadAction<Errors | string | undefined>) => {
        if (typeof payload === 'string') {
          return {
            ...state,
            isLoading: false,
            status: 'pending_verify',
            user: {
              ...state.user,
              userSub: payload,
            },
          };
        }
        return {
          ...state,
          isLoading: false,
          status: payload ? 'pending_signup' : 'pending_verify',
          errors: payload,
        };
      }
    );
    builder.addCase(signupUser.rejected, (state: State) => {
      return {
        ...state,
        isLoading: false,
        status: 'pending_signup',
        errors: undefined,
      };
    });
    builder.addCase(signupUser.pending, (state) => {
      return {
        ...state,
        status: 'signing_up',
        errors: undefined,
      };
    });
    builder.addCase(verifyUser.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        status: 'verifying',
        errors: undefined,
      };
    });
    builder.addCase(verifyUser.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        status: 'pending_verify',
        errors: undefined,
      };
    });
    builder.addCase(
      verifyUser.fulfilled,
      (state, { payload }: PayloadAction<Errors | undefined>) => {
        return {
          ...state,
          isLoading: false,
          status: payload ? 'pending_verify' : 'pending_info_setup',
          errors: payload,
          authUser: {
            email: state.user.email,
            verified: !payload,
          },
        };
      }
    );
    builder.addCase(createRegular.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        errors: undefined,
      };
    });
    builder.addCase(createRegular.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        errors: undefined,
      };
    });
    builder.addCase(
      createRegular.fulfilled,
      (
        state,
        {
          payload: { id, locker },
        }: PayloadAction<{ id: string; locker: string }>
      ) => {
        return {
          ...state,
          isLoading: false,
          errors: undefined,
          user: {
            ...state.user,
            regularUser: {
              ...state.user.regularUser,
              id,
              locker,
            },
          },
        };
      }
    );
    builder.addCase(createUser.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        errors: undefined,
      };
    });
    builder.addCase(createUser.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        errors: undefined,
      };
    });
    builder.addCase(
      createUser.fulfilled,
      (state, { payload }: PayloadAction<string>) => {
        return {
          ...state,
          isLoading: false,
          errors: undefined,
          user: {
            ...state.user,
            id: payload,
            isLoggedIn: true,
          },
        };
      }
    );
    builder.addCase(loginUser.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        errors: undefined,
      };
    });
    builder.addCase(
      loginUser.fulfilled,
      (state, { payload }: PayloadAction<Result | undefined>) => {
        return {
          ...state,
          isLoading: false,
          status: payload?.authUser?.verified
            ? 'pending_info_setup'
            : 'pending_verify',
          errors: undefined,
          user: {
            ...state.user,
            ...payload?.user,
            isLoggedIn: payload?.authUser?.verified,
          },
          authUser: {
            ...state.authUser,
            ...payload?.authUser,
          },
        };
      }
    );
    builder.addCase(loadUser.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        errors: undefined,
      };
    });
    builder.addCase(loadUser.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        errors: undefined,
      };
    });
    builder.addCase(
      loadUser.fulfilled,
      (state, { payload }: PayloadAction<LoadUserResult | undefined>) => {
        return {
          ...state,
          isLoading: false,
          errors: undefined,
          user: {
            ...state.user,
            ...payload?.user,
            isLoggedIn: !isEmpty(payload?.user),
          },
          authUser: {
            ...state.authUser,
            ...payload?.authUser,
          },
        };
      }
    );
    builder.addCase(updateRegular.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        errors: undefined,
      };
    });
    builder.addCase(updateRegular.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        errors: undefined,
      };
    });
    builder.addCase(
      updateRegular.fulfilled,
      (state, { payload }: PayloadAction<RegularUserStore | any>) => {
        return {
          ...state,
          isLoading: false,
          errors: undefined,
          user: {
            ...state.user,
            regularUser: {
              ...state.user.regularUser,
              ...payload,
            },
          },
        };
      }
    );
  },
});

export const signupUser = createAsyncThunk(
  'user/signup',
  async (user: SignupUser, { rejectWithValue }) => {
    try {
      const response = await signup(user);
      return response;
    } catch (error) {
      return rejectWithValue(error as string);
    }
  }
);

export const verifyUser = createAsyncThunk(
  'user/verify',
  async (code: string, { rejectWithValue, getState }) => {
    const { user } = getState() as { user: State };
    try {
      const response = await verifySignup(user.user, code);
      return response;
    } catch (error) {
      return rejectWithValue(error as string);
    }
  }
);

export const createUser = createAsyncThunk(
  'user/create',
  async (_, { rejectWithValue, getState }) => {
    const { user } = getState() as { user: State };
    try {
      const id = await completeSignup(
        user.user,
        user.user.regularUser?.id || ''
      );
      return id;
    } catch (error) {
      return rejectWithValue(error as string);
    }
  }
);

export const createRegular = createAsyncThunk(
  'user/createRegular',
  async (_, { rejectWithValue, getState }) => {
    const { user } = getState() as { user: State };
    const regularUser = user.user.regularUser || {};
    try {
      const locker = await createLocker();
      const id = await createRegularUser({
        ...regularUser,
        locker,
      });
      return {
        id,
        locker,
      };
    } catch (error) {
      return rejectWithValue(error as string);
    }
  }
);

export const loginUser = createAsyncThunk(
  'user/login',
  async (user: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await login(user.email, user.password);
      return response;
    } catch (error) {
      return rejectWithValue(error as string);
    }
  }
);

export const updateRegular = createAsyncThunk(
  'user/updateRegular',
  async (regularUser: RegularUserStore, { rejectWithValue }) => {
    try {
      await updateRegularUser(regularUser || {});
      return regularUser;
    } catch (error) {
      return rejectWithValue(error as string);
    }
  }
);

export const loadUser = createAsyncThunk(
  'user/load',
  async (_, { rejectWithValue }) => {
    try {
      const response = await load();
      return response;
    } catch (error) {
      return rejectWithValue(error as string);
    }
  }
);

export default userSlice.reducer;
export const { updateUser, updateRegularUserAddresses, updateState } =
  userSlice.actions;
