/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  UseFieldArrayProps,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ItemsInput } from './PackageInformation';
import ItemInfoCard from '../../library/components/ItemInfoCard';
import ItemImage from '../../library/components/ItemImage';
import { getDate, lbsToKg } from '../../library/utils/helper';
import { LithiumBattery } from '../../library/graphql/API';
import ItemDescription from './ItemDescription';
import { removeItem as removeItemFromPackage } from '../../app/features/packingSlice';
import { ItemOptionsPrices } from '../../library/types/Graphql';

type ItemFieldProps = {
  control: UseFieldArrayProps<ItemsInput>['control'];
  register: UseFormRegister<ItemsInput>;
  errors: FieldErrorsImpl<ItemsInput>;
  defaultValues: ItemsInput;
  setValue: UseFormSetValue<ItemsInput>;
  getValues: UseFormSetValue<ItemsInput>;
};

const ItemsField: React.FC<ItemFieldProps> = ({
  control,
  register,
  errors,
}) => {
  const {
    package: { items },
  } = useAppSelector((state) => state.packing);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  if (items === undefined || items.length === 0) {
    return null;
  }

  const { fields, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const removeItem = (index: number, id: string | undefined) => {
    remove(index);
    if (id === undefined) return;
    dispatch(removeItemFromPackage(id));
  };

  return (
    <>
      {fields.map((item, index) => {
        const currentItem = items.find((i) => i.id === item.itemId);
        if (currentItem === undefined) {
          return null;
        }

        return (
          <div
            key={uuidv4()}
            className="mb-4 w-full rounded-lg bg-white px-3 py-4 shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0"
          >
            <div className="mb-4 grid grid-cols-4 gap-4">
              {currentItem.imageUrls.map((image: string) => (
                <ItemImage key={uuidv4()} src={image} alt="item" />
              ))}
            </div>
            <dl className="mb-5 grid grid-cols-3 gap-4">
              <ItemInfoCard title="Carrier" description={currentItem.carrier} />
              <ItemInfoCard
                title="Date"
                description={
                  currentItem.createdAt ? getDate(currentItem?.createdAt) : ''
                }
              />
              <ItemInfoCard
                title="Weight"
                description={lbsToKg(currentItem?.weight || 0)}
              />
              <ItemInfoCard title="Barcode" description={currentItem.barcode} />
            </dl>
            <div className="mb-4">
              <label
                htmlFor="battery"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
              >
                {t('Does it contain lithium batteries?')}
                <select
                  id="battery"
                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                  {...register(`items.${index}.battery`)}
                >
                  <option value={LithiumBattery.NO}>
                    {t('No, it does not')}
                  </option>
                  <option value={LithiumBattery.YES}>
                    {t('Yes, battery backup, power bank or loose battery')}
                  </option>
                  <option value={LithiumBattery.YES_REMOVABLE}>
                    {t(
                      'Yes, batteries are removable, but attached to the item'
                    )}
                  </option>
                  <option value={LithiumBattery.YES_PERMANENT}>
                    {t('Yes, batteries are permanently inside device')}
                  </option>
                </select>
              </label>
            </div>
            <ItemDescription
              itemIndex={index}
              control={control}
              register={register}
              errors={errors}
            />
            <div className="mt-4">
              <div className="mb-6 flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="addShockProof"
                    type="checkbox"
                    className="focus:ring-3 h-4 w-4 rounded border border-gray-300 bg-gray-50 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    {...register(`items.${index}.shockProof`)}
                  />
                </div>
                <label
                  htmlFor="addShockProof"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  {t('Add shockproof packaging for fragile products', {
                    cost: ItemOptionsPrices.bubbleWrap.toFixed(2),
                  })}
                </label>
              </div>
              <div className="mb-6 flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="removeShoeBox"
                    type="checkbox"
                    className="focus:ring-3 h-4 w-4 rounded border border-gray-300 bg-gray-50 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    {...register(`items.${index}.shoeBox`)}
                  />
                </div>
                <label
                  htmlFor="removeShoeBox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  {t('Remove shoe boxes - Free')}
                </label>
              </div>
              <hr />
              <button
                type="button"
                className=" mt-4 inline-flex items-center rounded-lg bg-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                onClick={() => removeItem(index, currentItem.id)}
              >
                <svg
                  aria-hidden="true"
                  className="mr-1.5 -ml-1 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                {t('Delete Item')}
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ItemsField;
