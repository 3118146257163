import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getDate, getDimensions, lbsToKg } from '../../library/utils/helper';
import PackageAddress from './PackageAddress';
import { PackageDetailsProps } from './ShippedPackageDetails';
import ShippedPackageDetailsRow from './ShippedPackageDetailsRow';
import ShippingRates from '../ShippingRates';
import { GraphqlShippingRate } from '../../library/types/Graphql';
import getQuote from '../../library/services/Quote/getQuote';
import Loading from '../../library/components/Loading';
import Alert from '../../library/components/Alert';
import { useAppDispatch } from '../../app/hooks';
import { addShippingMethod } from '../../app/features/packagesSlice';
import { SHIPMENT_ROUTE } from '../../routes';

type Rates = {
  isLoading: boolean;
  rates: GraphqlShippingRate[];
  error: string | null;
};

type ShipmentRatesProps = Rates & {
  shipmentId: string;
};

const ShipmentRates: React.FC<ShipmentRatesProps> = ({
  rates,
  isLoading,
  error,
  shipmentId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState(false);

  const [selectedRate, setSelectedRate] = useState<string | undefined>(
    undefined
  );

  const handlePackageShipment = () => {
    const rate = rates.find(
      ({ info }) => info?.name?.toLocaleLowerCase() === selectedRate
    );

    if (!rate) return setShowAlert(true);
    dispatch(addShippingMethod({ id: shipmentId, shippingMethod: rate }));

    navigate(`${SHIPMENT_ROUTE}/${shipmentId}`);

    return null;
  };

  if (isLoading) return <Loading />;
  if (error)
    return <p className="my-4 text-center text-lg text-red-500">{error}</p>;
  if (rates.length === 0)
    return (
      <p className="text-gray-500 my-4 text-center text-lg">
        {t('No rates available for this shipment!')}
      </p>
    );
  return (
    <>
      <Alert
        type="warning"
        show={showAlert}
        setShow={setShowAlert}
        timeout={3000}
      >
        {t('You need to select a shipping method before you continue!')}
      </Alert>
      <div className="mt-4">
        <ShippingRates rates={rates} quote={false} setName={setSelectedRate} />
        <button
          type="button"
          className="text-white mt-4 w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={handlePackageShipment}
        >
          {t('Ship Package')}
        </button>
      </div>
    </>
  );
};

const PackedPackagesDetail: React.FC<PackageDetailsProps> = ({ shipment }) => {
  const [rates, setRates] = useState<Rates>({
    isLoading: false,
    rates: [],
    error: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setRates((prevValue) => ({ ...prevValue, isLoading: true }));
    const loadRates = async () => {
      const quote = {
        city: shipment.address?.city,
        countryCode: shipment.address?.countryCode,
        package: {
          weight: shipment.weight,
          width: shipment.dimension?.width,
          height: shipment.dimension?.height,
          length: shipment.dimension?.length,
        },
        postalCode: shipment.address?.zipCode || '00000',
      };
      try {
        const res = await getQuote(JSON.stringify(quote));
        setRates((prevValue) => ({
          ...prevValue,
          isLoading: false,
          rates: res,
        }));
      } catch (error: any) {
        setRates((prevValue) => ({
          ...prevValue,
          rates: [],
          isLoading: false,
          error: error.message,
        }));
      }
    };
    loadRates();
  }, []);

  return (
    <div className="w-full bg-white px-3 mb-4 py-4 rounded-lg shadow dark:border md:mt-0  dark:bg-gray-800 dark:border-gray-700">
      <h4 className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white">
        {t('Package #', { id: shipment.id })}
      </h4>
      <hr className="mb-4" />
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <div className="flex flex-col gap-y-2">
          <img
            className="w-80 rounded-lg"
            src="https://via.placeholder.com/150"
            alt="QR Code"
          />
          <div className="flex flex-row gap-x-1">
            <img
              className="w-24 rounded-lg"
              src="https://via.placeholder.com/150"
              alt=""
            />
            <img
              className="w-24 rounded-lg"
              src="https://via.placeholder.com/150"
              alt=""
            />
            <img
              className="w-24 rounded-lg"
              src="https://via.placeholder.com/150"
              alt=""
            />
          </div>
        </div>
        <div>
          {shipment.address && <PackageAddress address={shipment.address} />}

          <ShippedPackageDetailsRow
            title="Dimensions"
            value={shipment.dimension ? getDimensions(shipment.dimension) : ''}
          />

          <ShippedPackageDetailsRow
            title="Weight"
            value={shipment.weight ? lbsToKg(shipment.weight) : ''}
          />
          <ShippedPackageDetailsRow
            title="Ordered On"
            value={shipment.requestedOn ? getDate(shipment.requestedOn) : ''}
          />
          <ShippedPackageDetailsRow
            title="Packed On"
            value={shipment.packedOn ? getDate(shipment.packedOn) : ''}
          />
        </div>
      </div>
      <ShipmentRates {...rates} shipmentId={shipment.id} />
    </div>
  );
};

export default PackedPackagesDetail;
