import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import {
  CreateRegularUserInput,
  CreateRegularUserMutation,
} from '../../graphql/API';
import { RegularUserStore } from '../../types/User';

export const createRegularUserQuery = /* GraphQL */ `
  mutation CreateRegularUser(
    $input: CreateRegularUserInput!
    $condition: ModelRegularUserConditionInput
  ) {
    createRegularUser(input: $input, condition: $condition) {
      id
    }
  }
`;

type MapStoreRegularUserToGraphql = (
  r: RegularUserStore
) => CreateRegularUserInput;

const mapStoreRegularUserToGraphql: MapStoreRegularUserToGraphql = (r) => ({
  phone: r.phone,
  type: 'RegularUser',
  addresses: r.addresses,
  credit: r.credit || 0,
  unPackOnArrival: r.unPackOnArrival || false,
  locker: r.locker || '',
});

type CreateRegularUser = (r: RegularUserStore) => Promise<string>;

const createRegularUser: CreateRegularUser = async (
  regularUser: RegularUserStore
) => {
  const result = (await API.graphql(
    graphqlOperation(createRegularUserQuery, {
      input: mapStoreRegularUserToGraphql(regularUser),
    })
  )) as GraphQLResult<CreateRegularUserMutation>;
  return result.data?.createRegularUser?.id || '';
};

export default createRegularUser;
