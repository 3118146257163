import { ChevronDownIcon } from '@heroicons/react/20/solid';

import ShippingLogos from './ShippingLogos';

type ShippingMethodHeaderProps = {
  carrier: string;
  deliveryTime: string;
  price: number;
  name: string;
};

const ShippingMethodHeader: React.FC<ShippingMethodHeaderProps> = ({
  carrier,
  deliveryTime,
  price,
  name,
}) => {
  return (
    <>
      <div className="flex items-baseline w-full">
        <ShippingLogos carrier={carrier} className="w-16 mx-2" />
        <p className="text-lg capitalize font-semibold text-gray-900 dark:text-white">
          {name}
        </p>
      </div>
      <div className="flex w-full justify-between items-center">
        <p className="text-lg font-light mx-2">{deliveryTime}</p>
        <div className="flex">
          <p className="text-lg font-semibold text-gray-900 dark:text-white">
            {`$${price.toFixed(2)}`}
          </p>
          <ChevronDownIcon className="ui-open:rotate-180 ui-open:transform w-6 h-6 mx-2 text-black dark:text-white" />
        </div>
      </div>
    </>
  );
};

export default ShippingMethodHeader;
