import { Link } from 'react-router-dom';
import { FieldErrorsImpl } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { Input } from './index';
import logo from '../../asset/images/logo.svg';

type LoginProps = {
  handleSubmit: (callback: (data: any) => void) => (e: any) => void;
  onSubmit: (data: any) => void;
  register: UseFormRegister<Input>;
  errors: FieldErrorsImpl<Input>;
  signupError: boolean;
  showSignup?: boolean;
};

const LoginForm: React.FC<LoginProps> = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  signupError,
  showSignup = true,
}) => {
  const { t } = useTranslation();

  return (
    <section className="flex-grow bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
        <Link
          to="/"
          className="mb-6 flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="mr-2 h-10 w-10" src={logo} alt="logo" />
          Packeit
        </Link>
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
          <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              {t('Sign in to your account')}
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label
                  htmlFor="email"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {t('Your email')}
                  <input
                    type="email"
                    id="email"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    placeholder="name@email.com"
                    {...register('email')}
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.email?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {t('Password')}
                  <input
                    type="password"
                    id="password"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    placeholder="••••••••"
                    {...register('password')}
                  />
                  {errors.password && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className=" font-medium">
                        {t(`${errors.password?.message}`)}
                      </span>
                    </p>
                  )}
                </label>
              </div>
              {signupError && (
                <p className="mt-0 text-sm text-red-600 dark:text-red-500">
                  <span className=" font-medium">
                    {t('Incorrect email or password.')}
                  </span>
                </p>
              )}
              <div className="mt-0 flex items-center justify-between">
                {/* <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <Checkbox id="remember" aria-describedby="remember" />
                  </div>
                  <div className="ml-3 text-sm">
                    <Label htmlFor="remember">{t('Remember me')}</Label>
                  </div>
                </div> */}

                <Link
                  to="/restore"
                  className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  {t('Forgot password?')}
                </Link>
              </div>
              <button
                type="submit"
                className="w-full rounded-lg bg-primary-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {t('Sign in')}
              </button>
              {showSignup ? (
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  {t('Dont have an account yet?')}
                  <Link
                    to="/signup"
                    className="px-1 font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    {t('Sign up')}
                  </Link>
                </p>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
