import moment from 'moment';

import { DATE_FORMAT, DATE_TIME_FORMAT } from './constants';
import {
  GraphqlDimensions,
  GraphqlItemOptions,
  GraphqlPackageOptions,
  ItemOptionsPrices,
  PackageOptionsPrices,
  PackageOptionsTitle,
  ItemOptionsTitle,
} from '../types/Graphql';

type OptionsValues = {
  description: string;
  value: string;
};

export function lbsToKg(lbs: number): string {
  return `${lbs} lbs (${(lbs * 0.453592).toFixed(2)} kg)`;
}

export function getDate(date: string): string {
  return moment(date).format(DATE_FORMAT);
}

export function getDateTime(date: string): string {
  return moment(date).format(DATE_TIME_FORMAT);
}

export function getDimensions(dimensions: GraphqlDimensions): string {
  return `${dimensions.length} x ${dimensions.width} x ${dimensions.height} inches`;
}

export function kgToLbs(kg: number): number {
  return kg * 2.20462;
}

export function cmToIn(cm: number): number {
  return cm * 0.393701;
}

export function getPackageOptionsFees(options: GraphqlPackageOptions) {
  let fees = 0;
  Object.keys(options).forEach((key) => {
    if (options[key as keyof typeof PackageOptionsPrices]) {
      fees += PackageOptionsPrices[key as keyof typeof PackageOptionsPrices];
    }
  });
  return fees;
}

export function getPackageOptions(
  options: GraphqlPackageOptions
): OptionsValues[] {
  const optionsArray: OptionsValues[] = [];
  Object.keys(options).forEach((key) => {
    if (options[key as keyof typeof PackageOptionsPrices]) {
      optionsArray.push({
        description:
          PackageOptionsTitle[key as keyof typeof PackageOptionsTitle],
        value: `${
          PackageOptionsPrices[key as keyof typeof PackageOptionsPrices]
        }`,
      });
    }
  });
  return optionsArray;
}

export function getPackageItemsOptions(
  options: GraphqlItemOptions
): OptionsValues[] {
  const optionsArray: OptionsValues[] = [];
  Object.keys(options).forEach((key) => {
    if (options[key as keyof typeof ItemOptionsTitle]) {
      optionsArray.push({
        description: `Item ${
          ItemOptionsTitle[key as keyof typeof ItemOptionsTitle]
        }`,
        value: `${ItemOptionsPrices[
          key as keyof typeof ItemOptionsPrices
        ].toFixed(2)}`,
      });
    }
  });
  return optionsArray;
}

export function getPackageItemsOptionsFees(options: GraphqlItemOptions) {
  let fees = 0;
  Object.keys(options).forEach((key) => {
    if (options[key as keyof typeof ItemOptionsPrices]) {
      fees += ItemOptionsPrices[key as keyof typeof ItemOptionsPrices];
    }
  });
  return fees;
}
