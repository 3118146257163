import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ItemImage from '../../library/components/ItemImage';
import Table from '../../library/components/Table';
import { lbsToKg } from '../../library/utils/helper';
import { previousStep, create } from '../../app/features/packingSlice';
import Alert from '../../library/components/Alert';

type PackageDescription = {
  description: string;
  quantity: number;
  weight: string;
  price: number;
};

type AlertState = {
  type: 'error' | 'info' | 'warning' | 'success';
  message: string;
};

const PackageConfirmation = () => {
  const {
    package: { items },
  } = useAppSelector((state) => state.packing);
  const dispatch = useAppDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [alertState, setAlertState] = useState<AlertState>({
    type: 'info',
    message: '',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (items === undefined || items.length === 0) return null;

  const data: {
    items: PackageDescription[];
    images: string[];
    total: number;
  } = useMemo(() => {
    const result: PackageDescription[] = [];
    const images: string[] = [];
    let total = 0;
    items.forEach((item) => {
      images.push(...item.imageUrls);
      if (item.description === undefined || item.description.length <= 0)
        return;
      item.description.forEach((description) => {
        total += description.price || 0;
        result.push({
          description: description.description,
          quantity: description.quantity,
          weight: lbsToKg(item.weight || 0),
          price: description.price,
        });
      });
    });
    return {
      items: [...result.flat()],
      images,
      total,
    };
  }, [items]);

  const handleSubmit = async () => {
    try {
      await dispatch(create());

      setAlertState({
        type: 'success',
        message: 'Package created successfully',
      });
      setShowAlert(true);

      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      setAlertState({
        type: 'warning',
        message: 'There was an error, try again later.',
      });
      setShowAlert(true);
    }
  };

  const columnHelper = createColumnHelper<PackageDescription>();

  const columns = [
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => info.getValue(),
      header: 'Description',
      footer: 'Total announced value',
    }),
    columnHelper.accessor((row) => row.quantity, {
      id: 'quantity',
      cell: (info) => info.getValue(),
      header: 'Quantity',
    }),
    columnHelper.accessor((row) => row.weight, {
      id: 'weight',
      cell: (info) => info.getValue(),
      header: 'Weight',
    }),
    columnHelper.accessor((row) => row.price, {
      id: 'price',
      cell: (info) => info.getValue(),
      header: 'Price',
      footer: `$${data.total}`,
    }),
  ];

  return (
    <>
      <div className="mb-4 w-full rounded-lg bg-white px-3 py-4 shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0">
        <h4 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {t('Customs Declaration')}
        </h4>
        <hr className="mb-4" />
        <Table data={data.items} columns={columns} showFooter />
        <h4 className="mb-2 mt-4 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {t('Package Content')}
        </h4>
        <hr className="my-4" />
        <div className="flex w-full flex-row flex-wrap justify-around md:items-start">
          {data.images.map((image) => (
            <ItemImage key={uuidv4()} src={image} alt="item" />
          ))}
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-center gap-2">
        <button
          className=" mt-4 w-1/2 items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 sm:mt-6"
          type="button"
          onClick={() => dispatch(previousStep())}
        >
          {t('Previous')}
        </button>
        <button
          className=" mt-4 w-1/2 items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 sm:mt-6"
          type="button"
          onClick={handleSubmit}
        >
          {t('Confirm Packing')}
        </button>
      </div>
      <Alert
        type={alertState.type}
        show={showAlert}
        setShow={setShowAlert}
        timeout={3000}
      >
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {t(`${alertState.message}`)}
        </p>
      </Alert>
    </>
  );
};

export default PackageConfirmation;
