import { FieldErrorsImpl } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { Input } from './index';

type ForgotPasswordSubmitProps = {
  handleSubmit: (callback: (data: any) => void) => (e: any) => void;
  onSubmit: (data: any) => void;
  register: UseFormRegister<Input>;
  errors: FieldErrorsImpl<Input>;
};

const ForgotPasswordSubmitForm: React.FC<ForgotPasswordSubmitProps> = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <form
      className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <label
          htmlFor="code"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {t('Code')}
          <input
            type="text"
            id="code"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="######"
            {...register('code')}
          />
          {errors.code && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.code?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>
      <div>
        <label
          htmlFor="password"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {t('New Password')}
          <input
            type="password"
            id="password"
            placeholder="••••••••"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register('password')}
          />
          {errors.password && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.password?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>
      <div>
        <label
          htmlFor="confirm-password"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {t('Confirm password')}
          <input
            type="password"
            id="confirmPassword"
            placeholder="••••••••"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register('confirmPassword')}
          />
          {errors.confirmPassword && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.confirmPassword?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="newsletter"
            aria-describedby="newsletter"
            type="checkbox"
            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
          />
        </div>
      </div>
      <button
        type="submit"
        className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
      >
        {t('Reset password')}
      </button>
    </form>
  );
};

export default ForgotPasswordSubmitForm;
