import { Auth } from 'aws-amplify';

import { AuthUser, UserStore } from '../../types/User';
import getUser from './getUser';

export type LoadUserResult = {
  authUser?: Partial<AuthUser>;
  user?: Partial<UserStore>;
};

const load = async (): Promise<LoadUserResult> => {
  const result = {
    authUser: {},
    user: {},
  };

  const { authenticated } = await Auth.currentCredentials();

  if (!authenticated) {
    return result;
  }

  const { attributes } = await Auth.currentUserInfo();
  result.authUser = {
    email: attributes.email,
    verified: attributes.email_verified,
  };

  result.user = await getUser(attributes.email);

  return result;
};

export default load;
