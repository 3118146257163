import { useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import Table from '../../library/components/Table';
import { useAppSelector } from '../../app/hooks';
import { PACKAGES_ROUTE, PAYMENT_ROUTE, SHIPMENT_ROUTE } from '../../routes';

type ShipmentDescription = {
  description: string;
  value: string;
};

const Shipment = () => {
  const { packages } = useAppSelector((state) => state.packages);

  const { id } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const currentPackage = packages.find((p) => p.id === id);

  useEffect(() => {
    if (packages.length === 0) navigate(`/${PACKAGES_ROUTE}`);
  }, [packages]);

  const data: {
    items: ShipmentDescription[];
    total: number;
  } = useMemo(() => {
    const result: ShipmentDescription[] = [];
    let total = 0;
    currentPackage?.description.forEach((d) => {
      result.push({
        description: d.description,
        value: `$${d.price}`,
      });
      total += d.price;
    });
    return {
      items: [...result.flat()],
      total,
    };
  }, []);

  const columnHelper = createColumnHelper<ShipmentDescription>();

  const columns = [
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => info.getValue(),
      header: 'Description',
      footer: 'Total',
    }),
    columnHelper.accessor((row) => row.value, {
      id: 'value',
      cell: (info) => info.getValue(),
      header: 'Announced Value',
      footer: `$${data.total}`,
    }),
  ];

  return (
    <section className="flex-grow bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-auto lg:py-8">
        <div className="w-full rounded-lg bg-white p-4 shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0">
          <h2 className="mb-4 text-xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            {t('Customs declaration')}
          </h2>

          <Table data={data.items} columns={columns} showFooter />
          <button
            type="button"
            onClick={() =>
              navigate(
                `/${PACKAGES_ROUTE}/${SHIPMENT_ROUTE}/${PAYMENT_ROUTE}/${id}`
              )
            }
            className="mt-4 mr-2 mb-2 w-full rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {t('Next')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Shipment;
