import { Spinner } from 'flowbite-react';

type LoadingProps = {
  className?: string;
};

const Loading: React.FC<LoadingProps> = ({ className = '' }) => {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <Spinner aria-label="loading..." size="xl" />
    </div>
  );
};

export default Loading;
