import React from 'react';

type ShippedPackageDetailsRowProps = {
  title: string;
  value?: string | null;
  children?: React.ReactNode | null;
};

const ShippedPackageDetailsRow: React.FC<ShippedPackageDetailsRowProps> = ({
  title,
  value,
  children,
}) => {
  return (
    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 border-b mb-2">
      <p className="mb-2  tracking-tight font-bold text-gray-900 dark:text-white">
        {title}
      </p>
      {children || (
        <p className="font-light text-gray-500 dark:text-gray-400">{value}</p>
      )}
    </div>
  );
};

ShippedPackageDetailsRow.defaultProps = {
  children: null,
  value: null,
};

export default ShippedPackageDetailsRow;
