import { useState } from 'react';
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import AddressCard from '../../library/components/AddressCard';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { EDIT_ADDRESS_ROUTE, NEW_ADDRESS_ROUTE } from '../../routes';
import { RegularUserStore } from '../../library/types/User';
import { updateRegular } from '../../app/features/userSlice';

const Address: React.FC = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('');

  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEdit = (id: string) => {
    navigate(`${EDIT_ADDRESS_ROUTE}/${id}`);
  };

  const handleDelete = (id: string) => {
    setSelectedAddress(id);
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteAddress = async () => {
    if (!selectedAddress) return;
    const regularUser: RegularUserStore = cloneDeep(user.regularUser || {});
    const address = regularUser.addresses?.filter(
      (a) => a.id !== selectedAddress
    );
    regularUser.addresses = address;
    await dispatch(updateRegular(regularUser));
    setShowDeleteModal(false);
  };

  return (
    <section className="flex-grow bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-auto lg:py-8">
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0 xl:p-0">
          <div className=" flex flex-row items-baseline justify-between p-4 sm:p-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              {t('Address')}
            </h1>
            <button
              onClick={() => navigate(`${NEW_ADDRESS_ROUTE}`)}
              type="button"
              className="rounded-lg bg-primary-600 px-12 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {t('Add Address')}
            </button>
          </div>
        </div>
        <div className="flex w-full flex-row flex-wrap justify-around md:items-start">
          {user.regularUser?.addresses &&
            user.regularUser?.addresses?.length === 0 && <p>No address</p>}

          {user.regularUser?.addresses?.map((address) => (
            <AddressCard
              key={address.id}
              address={address}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>

      <Modal size="xl" show={showDeleteModal} onClose={handleCloseModal}>
        <Modal.Header>{t('Delete address?')}</Modal.Header>
        <Modal.Body>
          <div className="p-6 text-center">
            <svg
              aria-hidden="true"
              className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {t('Are you sure you want to delete this Address?')}
            </h3>
            <button
              data-modal-toggle="popup-modal"
              type="button"
              className="mr-2 inline-flex items-center rounded-lg bg-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
              onClick={handleDeleteAddress}
            >
              {t('Yes, Im sure')}
            </button>
            <button
              data-modal-toggle="popup-modal"
              type="button"
              className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
              onClick={handleCloseModal}
            >
              {t('No, cancel')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Address;
