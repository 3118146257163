import { Suspense, useEffect } from 'react';
import { Flowbite } from 'flowbite-react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import NavBar from './library/components/NavBar';
import Home from './pages/Home/Home';
import { NAVBAR_ITEMS, USER_NAVBAR_ITEMS } from './constants';
import NotFound from './library/components/NotFound';
import Footer from './library/components/Footer';
import SignupFlow from './containers/SignupFlow';
import Login from './containers/Login';
import Privacy from './library/components/Privacy';
import Terms from './library/components/Terms';
import Address from './containers/Address';
import NewAddress from './containers/NewAddress';
import ForgotPassword from './containers/ForgotPassword';
import Items from './containers/Items';
import Pack from './containers/Pack';
import RequireAuth from './containers/RequireAuth';
import {
  HOME_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
  ADDRESS_ROUTE,
  NEW_ADDRESS_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  ITEMS_ROUTE,
  PACK_ROUTE,
  EDIT_ADDRESS_ROUTE,
  PACKAGES_ROUTE,
  SHIPMENT_ROUTE,
  PAYMENT_ROUTE,
  ABOUT_ROUTE,
  CONTACT_ROUTE,
} from './routes';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { loadUser } from './app/features/userSlice';
import Packages from './containers/packages';
import Shipment from './containers/Shipment/Shipment';
import Payment from './containers/Shipment/Payment';
import Loading from './library/components/Loading';
import About from './pages/About';
import Contact from './pages/Contact';

const Layout: React.FC = () => {
  const { isLoading, user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const load = async () => {
      await dispatch(loadUser());
    };
    load();
  }, []);

  if (isLoading) return <Loading className="h-screen" />;

  return (
    <>
      <Suspense fallback={<Loading />}>
        <NavBar
          user={user}
          items={user.isLoggedIn ? USER_NAVBAR_ITEMS : NAVBAR_ITEMS}
        />
      </Suspense>
      <Outlet />
      <Footer />
    </>
  );
};

const RoutesComponent: React.FC = () => {
  return (
    <Routes>
      <Route path={HOME_ROUTE} element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={SIGNUP_ROUTE} element={<SignupFlow />} />
        <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
        <Route path={LOGIN_ROUTE} element={<Login />} />
        <Route path={PRIVACY_ROUTE} element={<Privacy />} />
        <Route path={TERMS_ROUTE} element={<Terms />} />
        <Route path={ABOUT_ROUTE} element={<About />} />
        <Route path={CONTACT_ROUTE} element={<Contact />} />

        <Route element={<RequireAuth />}>
          <Route path={ADDRESS_ROUTE}>
            <Route index element={<Address />} />
            <Route path={NEW_ADDRESS_ROUTE} element={<NewAddress />} />
            <Route path={`${NEW_ADDRESS_ROUTE}`} element={<NewAddress />} />
            <Route
              path={`${EDIT_ADDRESS_ROUTE}/:id`}
              element={<NewAddress />}
            />
          </Route>
          <Route path={ITEMS_ROUTE} element={<Items />} />
          <Route path={PACK_ROUTE} element={<Pack />} />
          <Route path={PACKAGES_ROUTE}>
            <Route index element={<Packages />} />
            <Route path={`${SHIPMENT_ROUTE}/:id`} element={<Shipment />} />
            <Route
              path={`${SHIPMENT_ROUTE}/${PAYMENT_ROUTE}/:id`}
              element={<Payment />}
            />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Packeit | Parcel Forwarding</title>
        <link rel="canonical" href="http://packeit.com" />
      </Helmet>
      <BrowserRouter>
        <Flowbite>
          <RoutesComponent />
        </Flowbite>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
