import {
  Avatar,
  Button,
  DarkThemeToggle,
  Dropdown,
  Navbar,
} from 'flowbite-react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MapPinIcon } from '@heroicons/react/20/solid';

import compareFirstPartOfPaths from '../../utils/ReactRouter';
import { UserStore } from '../../types/User';
import signOut from '../../services/User/logout';
import logo from '../../asset/images/logo.svg';

type Item = {
  title: string;
  path: string;
};

type NavBarProps = {
  items: Array<Item>;
  user: UserStore;
  showAddress?: boolean;
};

const NavBar: React.FC<NavBarProps> = ({ items, user, showAddress = true }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const logout = async () => {
    await signOut();
    window.location.reload();
  };

  return (
    <Navbar fluid>
      <Navbar.Brand href="https://packeit.com/">
        <img src={logo} className="mr-3 h-6 sm:h-9" alt="Packeit Logo" />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Packeit
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        {showAddress
          ? user.isLoggedIn && (
              <Dropdown
                className="w-72"
                arrowIcon={false}
                inline
                label={
                  <span className="rounded-lg p-2.5 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-700">
                    <MapPinIcon className="h-6 w-6" />
                  </span>
                }
              >
                <Dropdown.Header>
                  <span className="py-8 font-semibold text-gray-900 dark:text-white">
                    {t('Your locker address')}
                  </span>
                </Dropdown.Header>
                <Dropdown.Header className="flex flex-col items-baseline gap-1">
                  <h2 className="block text-base">{t('Name')}</h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {`${user.firstName} ${user.lastName}`}
                  </span>
                  <h2 className="block text-base">
                    {t('Address Line', { number: 1 })}
                  </h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    117 PATRIOT DR Suite NUM B
                  </span>
                  <h2 className="block text-base">
                    {t('Address Line', { number: 2 })}
                  </h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {`${user.regularUser?.locker}`} packeit
                  </span>
                  <h2 className="block text-base">{t('City')}</h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Middletown
                  </span>
                  <h2 className="block text-base">{t('State')}</h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    DE (Delaware)
                  </span>
                  <h2 className="block text-base">{t('ZIP')}</h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    19709
                  </span>
                  <h2 className="block text-base">{t('Phone')}</h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    (000) 000-0000
                  </span>
                </Dropdown.Header>
              </Dropdown>
            )
          : null}

        <DarkThemeToggle />

        {user.isLoggedIn ? (
          <Dropdown
            arrowIcon={false}
            inline
            label={<Avatar alt="User settings" img={user.avatarUrl} rounded />}
          >
            <Dropdown.Header>
              <span className="block text-sm">{`${user.firstName} ${user.lastName}`}</span>
              <span className="block truncate text-sm font-medium">
                {`${user.email}`}
              </span>
            </Dropdown.Header>
            <NavLink to="/address">
              <Dropdown.Item>{t('Address')}</Dropdown.Item>
            </NavLink>
            <NavLink to="/items">
              <Dropdown.Item>{t('Items')}</Dropdown.Item>
            </NavLink>
            <NavLink to="/packages">
              <Dropdown.Item>{t('Packages')}</Dropdown.Item>
            </NavLink>
            <NavLink to="/pricing">
              <Dropdown.Item>{t('Shipping Calculator')}</Dropdown.Item>
            </NavLink>
            <Dropdown.Divider />
            <Dropdown.Item onClick={logout}>{t('Sign out')}</Dropdown.Item>
          </Dropdown>
        ) : (
          <Link to="login">
            <Button>{t('Log in')}</Button>
          </Link>
        )}
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        {items.map(({ path, title }) => {
          const isActive = compareFirstPartOfPaths(pathname, path);
          const classes = isActive
            ? 'block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white'
            : 'block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent';
          return (
            <NavLink className={classes} key={title} to={path}>
              {title}
            </NavLink>
          );
        })}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
