import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import listPackages from '../../library/services/package/listPackage';
import { PackagesState, PackageStore } from '../../library/types/Package';
import { State } from '../../library/types/User';
import { GraphqlShippingRate } from '../../library/types/Graphql';
import payPackage from '../../library/services/package/payPackage';

const initialState: PackagesState = {
  loading: 'idle',
  packages: [],
};

type AddShippingMethod = {
  id: string;
  shippingMethod: GraphqlShippingRate;
};

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    addShippingMethod(state, action: PayloadAction<AddShippingMethod>) {
      const { id, shippingMethod } = action.payload;
      return {
        ...state,
        packages: state.packages.map((p) => {
          if (p.id === id) {
            return {
              ...p,
              shippingMethod,
            };
          }
          return p;
        }),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(load.rejected, (state) => {
      state.loading = 'failed';
    });
    builder.addCase(load.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(
      load.fulfilled,
      (state, action: PayloadAction<PackageStore[]>) => {
        state.loading = 'succeeded';
        state.packages = [...action.payload];
      }
    );
    builder.addCase(payment.rejected, (state) => {
      state.loading = 'failed';
    });
    builder.addCase(
      payment.fulfilled,
      (state, action: PayloadAction<PackageStore>) => {
        state.loading = 'succeeded';
        state.packages = state.packages.map((p) => {
          if (p.id === action.payload.id) {
            return action.payload;
          }
          return p;
        });
      }
    );
  },
});

export const load = createAsyncThunk(
  'packages/load',
  async (_, { rejectWithValue, getState }) => {
    const { user } = getState() as { user: State };
    try {
      const packages = await listPackages(user.user.regularUser?.locker || '');
      return packages;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const payment = createAsyncThunk(
  'packages/payment',
  async (selectedPackage: PackageStore, { rejectWithValue, getState }) => {
    const { user } = getState() as { user: State };
    try {
      await payPackage({
        ...selectedPackage,
        locker: user.user.regularUser?.locker || '',
        payment: {
          ...selectedPackage.payment,
          userId: user.user.id,
        },
      });
      return selectedPackage;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default packagesSlice.reducer;
export const { addShippingMethod } = packagesSlice.actions;
