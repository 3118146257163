import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ContactForm, { FormInputs } from '../../library/components/ContactForm';
import contactUs from '../../library/services/Contact/contactUs';
import Alert from '../../library/components/Alert';

const schema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required'),
  subject: yup.string().required('Subject is required'),
  message: yup.string().required('Message is required'),
});

type EmailState = {
  type: 'error' | 'info' | 'warning' | 'success';
  message: string;
};

const Contact = () => {
  const [state, setState] = useState<EmailState>({
    type: 'error',
    message: '',
  });

  const [showAlert, setShowAlert] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      const res = await contactUs(
        JSON.stringify({
          senderEmail: data.email,
          subject: data.subject,
          message: data.message,
        })
      );
      setState({
        type: res.status === 200 ? 'success' : 'error',
        message:
          res.status === 200
            ? 'Your email has been sent'
            : 'Something wrong happened, try again later',
      });
    } catch (error) {
      setState({
        type: 'error',
        message: 'Something wrong happened, try again later',
      });
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-md py-8 px-4 lg:py-16">
        <h2 className="mb-4 text-center text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {t('Contact Us')}
        </h2>
        <p className="mb-8 text-center font-light text-gray-500 dark:text-gray-400 sm:text-xl lg:mb-16">
          {t('Contact us description')}
        </p>
        <ContactForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          register={register}
          errors={errors}
        />
      </div>
      <Alert
        type={state.type}
        show={showAlert}
        setShow={setShowAlert}
        timeout={5000}
      >
        {t(`${state.message}`)}
      </Alert>
    </section>
  );
};

export default Contact;
