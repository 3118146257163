import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  nextStep,
  previousStep,
  packageOptions,
} from '../../app/features/packingSlice';
import { useAppDispatch } from '../../app/hooks';
import PackageOptionCard from '../../library/components/PackageOptionCard';
import { PackageOptionsPrices } from '../../library/types/Graphql';

type PackageOptionType = {
  removeBills: boolean;
  plasticBag: boolean;
  stretchWrap: boolean;
  doubleWallBox: boolean;
};

const PackageOption = () => {
  const dispatch = useAppDispatch();

  const {t} = useTranslation();

  const [selectedOption, setSelectedOption] = useState<PackageOptionType>({
    removeBills: false,
    plasticBag: false,
    stretchWrap: false,
    doubleWallBox: false,
  });

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setSelectedOption((prevState) => ({ ...prevState, [id]: checked }));
  };

  const handleSubmit = () => {
    dispatch(packageOptions(selectedOption));
    dispatch(nextStep());
  };

  return (
    <>
      <div className="mb-4 w-full rounded-lg bg-white px-3 py-4 shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0">
        <h4 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {t('Package Option')}
        </h4>
        <p className="mb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
          {t('Package Option Description')}
        </p>
        <hr />
        <div>
          <PackageOptionCard
            id="removeBills"
            src="https://flowbite.com/docs/images/blog/image-1.jpg"
            price={`${PackageOptionsPrices.removeBills.toFixed(2)}`}
            title="Remove Bills"
            description="We will remove all the bills from your package"
            value={selectedOption.removeBills}
            onChange={handleOptionChange}
          />
          <PackageOptionCard
            id="plasticBag"
            src="https://picsum.photos/200/300"
            price={`${PackageOptionsPrices.plasticBag.toFixed(2)}`}
            title="Plastic Bag"
            description="We will put all the package content in a plastic page to prevent smells and liquid"
            value={selectedOption.plasticBag}
            onChange={handleOptionChange}
          />
          <PackageOptionCard
            id="stretchWrap"
            src="https://picsum.photos/200/300"
            price={`${PackageOptionsPrices.stretchWrap.toFixed(2)}`}
            title="Plastic Wrapper for the Package Box"
            description="We will wrap the whole package in a plastic wrapper"
            value={selectedOption.stretchWrap}
            onChange={handleOptionChange}
          />
          <PackageOptionCard
            id="doubleWallBox"
            src="https://picsum.photos/200/300"
            price={`${PackageOptionsPrices.doubleWallBox.toFixed(2)}`}
            title="Double Wall Box"
            description="We will use a double wall box to protect your package"
            value={selectedOption.doubleWallBox}
            onChange={handleOptionChange}
          />
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-center gap-2">
        <button
          className=" mt-4 w-1/2 items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 sm:mt-6"
          type="button"
          onClick={() => dispatch(previousStep())}
        >
          {t('Previous')}
        </button>
        <button
          className=" mt-4 w-1/2 items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 sm:mt-6"
          type="button"
          onClick={handleSubmit}
        >
          {t('Next')}
        </button>
      </div>
    </>
  );
};

export default PackageOption;
