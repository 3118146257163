import { Auth } from 'aws-amplify';
import i18next from 'i18next';

type Result = {
  error: string | null;
};

export type ForgotPassword = (email: string) => Promise<Result>;

const forgotPassword: ForgotPassword = async (email) => {
  const result: Result = {
    error: null,
  };

  try {
    await Auth.forgotPassword(email);
  } catch (err: any) {
    if (err.code === 'UserNotFoundException') {
      result.error = i18next.t('The provided email does not match any account');
    } else {
      result.error = err.code;
    }
  }

  return result;
};

export default forgotPassword;
