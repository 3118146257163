export const HOME_ROUTE = "/";
export const SIGNUP_ROUTE = 'signup';
export const FORGOT_PASSWORD_ROUTE = 'restore';
export const PRICING_ROUTE = 'pricing';
export const ABOUT_ROUTE = 'about';
export const CONTACT_ROUTE = 'contact';
export const HELP_ROUTE = 'help';
export const LOGIN_ROUTE = 'login';
export const PRIVACY_ROUTE = 'privacy';
export const TERMS_ROUTE = 'terms';
export const ADDRESS_ROUTE = 'address';
export const ITEMS_ROUTE = 'items';
export const PACK_ROUTE = 'pack';
export const SHIPMENT_ROUTE = 'shipment';
export const PAYMENT_ROUTE = 'payment';
export const PACKAGES_ROUTE = 'packages';
export const NEW_ADDRESS_ROUTE = 'new';
export const EDIT_ADDRESS_ROUTE = 'edit';