import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import { CreateUserInput, CreateUserMutation } from '../../graphql/API';
import { UserStore } from '../../types/User';

export const createUserQuery = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
    }
  }
`;

type MapStoreUserToGraphql = (
  u: UserStore,
  userableId: string
) => CreateUserInput;

const mapStoreUserToGraqhql: MapStoreUserToGraphql = (user, userableId) => ({
  email: user.email,
  type: 'User',
  avatarUrl: user.avatarUrl,
  languageCode: user.languageCode,
  firstName: user.firstName,
  lastName: user.lastName,
  countryCode: user.countryCode,
  userableId,
  userSub: user.userSub || '',
  userableType: user.userableType,
  disabled: user.disabled,
});

type CreateUser = (user: UserStore, userableId: string) => Promise<string>;

const createUser: CreateUser = async (user, userableId) => {
  const result = (await API.graphql(
    graphqlOperation(createUserQuery, {
      input: mapStoreUserToGraqhql(user, userableId),
    })
  )) as GraphQLResult<CreateUserMutation>;
  return result.data?.createUser?.id || '';
};

export default createUser;
