import { useEffect, useMemo } from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useNavigate, useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import ShippedPackageDetailsRow from '../packages/ShippedPackageDetailsRow';
import { useAppSelector } from '../../app/hooks';
import { PACKAGES_ROUTE } from '../../routes';
import PackageAddress from '../packages/PackageAddress';
import {
  getDate,
  getDimensions,
  getPackageItemsOptions,
  getPackageItemsOptionsFees,
  getPackageOptions,
  getPackageOptionsFees,
  lbsToKg,
} from '../../library/utils/helper';
import PayPalButtonWrapper from './PayPalButtonWrapper';
import Table from '../../library/components/Table';

type PackageCost = {
  description: string;
  value: string;
};

const currency = 'USD';

const costColumnHelper = createColumnHelper<PackageCost>();

const Payment = () => {
  const { packages } = useAppSelector((state) => state.packages);

  const { id } = useParams();
  const navigate = useNavigate();

  const {t} = useTranslation();

  const currentPackage = packages.find((p) => p.id === id);

  useEffect(() => {
    if (packages.length === 0) navigate(`/${PACKAGES_ROUTE}`);
  }, [packages]);

  const cost: {
    items: PackageCost[];
    total: number;
  } = useMemo(() => {
    let total = 0;
    const items: PackageCost[] = [];
    currentPackage?.items?.forEach((item) => {
      if (item.options) {
        items.push(...getPackageItemsOptions(item.options));
        total += getPackageItemsOptionsFees(item.options);
      }
    });

    const options = currentPackage?.options
      ? getPackageOptions(currentPackage?.options)
      : [];
 
    if (currentPackage?.shippingMethod?.rate) {
      items.push({
        description: `Shipping (${currentPackage?.shippingMethod.info?.name})`,
        value: `${currentPackage?.shippingMethod.rate.toFixed(2)}`,
      });
    }

    total += currentPackage?.shippingMethod?.rate || 0;

    total += currentPackage?.options
      ? getPackageOptionsFees(currentPackage?.options)
      : 0;
    return {
      items: [...options, ...items],
      total,
    };
  }, [currentPackage]);

  const costColumns = [
    costColumnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => info.getValue(),
      header: 'Description',
      footer: 'Total cost',
    }),
    costColumnHelper.accessor((row) => row.value, {
      id: 'price',
      cell: (info) => `$${info.getValue()}`,
      header: 'Price',
      footer: `$${cost.total.toFixed(2)}`,
    }),
  ];

  if (!currentPackage) return null;

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-auto lg:py-8">
        <div className="mb-4 w-full rounded-lg bg-white px-3 py-4 shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0">
          <h4 className="mb-6 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            {t('Package #', { id: currentPackage?.id })}
          </h4>

          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="flex flex-col gap-y-2">
              <img
                className="w-80 rounded-lg"
                src="https://via.placeholder.com/150"
                alt="QR Code"
              />
              <div className="flex flex-row gap-x-1">
                <img
                  className="w-24 rounded-lg"
                  src="https://via.placeholder.com/150"
                  alt=""
                />
                <img
                  className="w-24 rounded-lg"
                  src="https://via.placeholder.com/150"
                  alt=""
                />
                <img
                  className="w-24 rounded-lg"
                  src="https://via.placeholder.com/150"
                  alt=""
                />
              </div>
            </div>
            <div>
              {currentPackage?.address && (
                <PackageAddress address={currentPackage.address} />
              )}

              <ShippedPackageDetailsRow
                title="Dimensions"
                value={
                  currentPackage.dimension
                    ? getDimensions(currentPackage.dimension)
                    : ''
                }
              />

              <ShippedPackageDetailsRow
                title="Weight"
                value={
                  currentPackage.weight ? lbsToKg(currentPackage.weight) : ''
                }
              />
              <ShippedPackageDetailsRow
                title="Ordered On"
                value={
                  currentPackage.requestedOn
                    ? getDate(currentPackage.requestedOn)
                    : ''
                }
              />
              <ShippedPackageDetailsRow
                title="Packed On"
                value={
                  currentPackage.packedOn
                    ? getDate(currentPackage.packedOn)
                    : ''
                }
              />

              <ShippedPackageDetailsRow
                title="Shipment method"
                value={currentPackage.shippingMethod?.info?.carrier}
              />
            </div>
          </div>
          <hr className="my-2" />
          <div className="mb-4 grid gap-4 sm:grid-cols-3">
            <h2 className=" text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              {t('Cost')}
            </h2>
            <div className="col-span-2">
              <Table data={cost.items} columns={costColumns} showFooter />
            </div>
          </div>
          <hr className="my-2" />
          <div>
            <h2 className=" font-light text-gray-500 dark:text-gray-400">
              {t('You need to pay')}
              <span className="mx-1 text-base font-extrabold tracking-tight text-gray-900 dark:text-white">
                ${cost.total}
              </span>
              {t('to complete this shipment')}
            </h2>
            <div className="mt-4 w-64">
              {cost.total > 0 && (
                <PayPalScriptProvider
                  options={{
                    'client-id':
                      'AWDDcqRMaGxtEftErznRfRxqlQqwBh9p9kkyFu0qfj39ArwpgG4fe-9kIEhIIJOa8pAS8ANYXi-CkKJl',
                    components: 'buttons',
                    currency: 'USD',
                  }}
                >
                  <PayPalButtonWrapper
                    currentPackage={currentPackage}
                    currency={currency}
                    total={cost.total}
                    showSpinner
                  />
                </PayPalScriptProvider>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Payment;
