import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { PayloadAction } from '@reduxjs/toolkit';

import LoginForm, { Input } from '../../library/components/LoginForm';
import { Result } from '../../library/services/User/login';
import { UserableType } from '../../library/types/User';
import SignOut from '../../library/services/User/logout';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { loginUser } from '../../app/features/userSlice';
import { SIGNUP_ROUTE } from '../../routes';
import { LocationType } from '../../library/utils/ReactRouter';

const schema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

const Login: React.FC = () => {
  const { isLoggedIn } = useAppSelector((state) => state.user.user);

  const [signupError, setSignupError] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationType;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Input> = async (data) => {
    const { payload }: PayloadAction<Result | undefined | any> = await dispatch(
      loginUser({ email: data.email, password: data.password })
    );

    if (
      payload?.error === 'NotAuthorizedException' ||
      payload?.error === 'UserNotFoundException'
    ) {
      setSignupError(true);
      return;
    }

    if (payload.user?.userableType === UserableType.Admin) {
      // TODO: throw an error in the createAsyncThunk
      SignOut();
      window.location.reload();
      return;
    }
    if (!payload.authUser?.verified) {
      navigate(`/${SIGNUP_ROUTE}`);
      return;
    }

    if (payload.authUser && payload.user) {
      navigate(state?.from?.pathname || '/');
    }
  };

  if (isLoggedIn)
    return <Navigate to={{ pathname: state?.from?.pathname || '/' }} />;

  return (
    <LoginForm
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      register={register}
      errors={errors}
      signupError={signupError}
    />
  );
};

export default Login;
