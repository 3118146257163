import {
  HOME_ROUTE,
  PRICING_ROUTE,
  ABOUT_ROUTE,
  HELP_ROUTE,
  ITEMS_ROUTE,
  PACKAGES_ROUTE,
} from './routes';

export const NAVBAR_ITEMS = [
  {
    title: 'Home',
    path: `${HOME_ROUTE}`,
  },
  {
    title: 'Pricing',
    path: `${PRICING_ROUTE}`,
  },
  {
    title: 'About',
    path: `${ABOUT_ROUTE}`,
  },
  {
    title: 'Help',
    path: `${HELP_ROUTE}`,
  },
];

export const USER_NAVBAR_ITEMS = [
  {
    title: 'Home',
    path: `${HOME_ROUTE}`,
  },
  {
    title: 'Items',
    path: `${ITEMS_ROUTE}`,
  },
  {
    title: 'Packages',
    path: `${PACKAGES_ROUTE}`,
  },
];
