import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import Table from '../../library/components/Table';
import { AddressStore } from '../../library/types/Address';
import {
  GraphqlPackageOptions,
  PackageOptionsTitle,
  PackageOptionsPrices,
} from '../../library/types/Graphql';
import PackageAddress from './PackageAddress';

type RequestedPackageDetailProps = {
  address: AddressStore;
  options: GraphqlPackageOptions;
};

type PackageOptions = {
  description: string;
  price: number;
};

const columnHelper = createColumnHelper<PackageOptions>();

const RequestedPackageDetail: React.FC<RequestedPackageDetailProps> = ({
  address,
  options,
}) => {
  const data: {
    items: PackageOptions[];
    total: number;
  } = useMemo(() => {
    const result = {
      items: [] as PackageOptions[],
      total: 0,
    };
    Object.keys(options).forEach((key) => {
      if (options[key as keyof typeof options]) {
        result.total +=
          PackageOptionsPrices[key as keyof typeof PackageOptionsPrices];
        result.items.push({
          description:
            PackageOptionsTitle[key as keyof typeof PackageOptionsTitle],
          price: PackageOptionsPrices[key as keyof typeof PackageOptionsPrices],
        });
      }
    });

    return result;
  }, [options]);

  const { t } = useTranslation();

  const columns = [
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => info.getValue(),
      header: 'Description',
      footer: 'Total optionals value',
    }),
    columnHelper.accessor((row) => row.price, {
      id: 'price',
      cell: (info) => `$${info.getValue().toFixed(2)}`,
      header: 'Price',
      footer: `$${data.total.toFixed(2)}`,
    }),
  ];

  return (
    <div className="w-full bg-white px-3 mb-4 py-4 rounded-lg shadow dark:border md:mt-0  dark:bg-gray-800 dark:border-gray-700">
      <h4 className="mb-2 text-lg tracking-tight font-extrabold text-gray-900 dark:text-white">
        {t('Package #', { id: 1234 })}
      </h4>
      <hr className="my-4" />
      <PackageAddress address={address} />
      <hr className="my-4" />
      <div className="grid gap-4 mb-4 sm:grid-cols-3">
        <h5 className="text-lg tracking-tight font-extrabold text-gray-900 dark:text-white">
          {t('Package Options')}
        </h5>
        <div className="col-span-2">
          <Table data={data.items} columns={columns} showFooter />
        </div>
      </div>
    </div>
  );
};

export default RequestedPackageDetail;
