import { FieldErrorsImpl } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { CountriesList } from '../SignUpForm';
import { AddressInput } from './index';

type AddressProps = {
  handleSubmit: (callback: (data: any) => void) => (e: any) => void;
  onSubmit: (data: any) => void;
  register: UseFormRegister<AddressInput>;
  errors: FieldErrorsImpl<AddressInput>;
  onCancel: () => void;
};

const AddressForm: React.FC<AddressProps> = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label
            htmlFor="firstName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {t('First Name')}
            <input
              type="text"
              id="firstName"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              {...register('firstName')}
            />
            {errors.firstName && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                <span className=" font-medium">
                  {t(`${errors.firstName?.message}`)}
                </span>
              </p>
            )}
          </label>
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {t('Last Name')}
            <input
              type="text"
              id="lastName"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              {...register('lastName')}
            />
            {errors.lastName && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                <span className=" font-medium">
                  {t(`${errors.lastName?.message}`)}
                </span>
              </p>
            )}
          </label>
        </div>
      </div>

      <div className="mb-6">
        <label
          htmlFor="country"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
        >
          {t('Country')}
          <select
            id="country"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register('country')}
          >
            <option value="" hidden>
              {t('Choose a country')}
            </option>
            <CountriesList />
          </select>
          {errors.country && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.country?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>
      <div className="mb-6">
        <label
          htmlFor="addressLineOne"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {t('Address 1')}
          <input
            type="text"
            id="addressLineOne"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register('addressLineOne')}
          />
          {errors.addressLineOne && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.addressLineOne?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>

      <div className="mb-6">
        <label
          htmlFor="addressLineTwo"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {t('Address 2')}
          <input
            type="text"
            id="addressLineTwo"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register('addressLineTwo')}
          />
          {errors.addressLineTwo && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.addressLineTwo?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>

      <div className="mb-6">
        <label
          htmlFor="city"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {t('City')}
          <input
            type="text"
            id="city"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register('city')}
          />
          {errors.city && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className=" font-medium">
                {t(`${errors.city?.message}`)}
              </span>
            </p>
          )}
        </label>
      </div>

      <div className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label
            htmlFor="state"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {t('State / Province')}
            <input
              type="text"
              id="state"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              {...register('state')}
            />
            {errors.state && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                <span className=" font-medium">
                  {t(`${errors.state?.message}`)}
                </span>
              </p>
            )}
          </label>
        </div>
        <div>
          <label
            htmlFor="zipCode"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {t('Postal Code')}
            <input
              type="text"
              id="zipCode"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              {...register('zipCode')}
            />
            {errors.zipCode && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                <span className=" font-medium">
                  {t(`${errors.zipCode?.message}`)}
                </span>
              </p>
            )}
          </label>
        </div>
      </div>

      <div className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label
            htmlFor="phoneNumber"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {t('Phone Number')}
            <input
              type="phone"
              id="phoneNumber"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              {...register('phoneNumber')}
            />
            {errors.phoneNumber && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                <span className=" font-medium">
                  {t(`${errors.phoneNumber?.message}`)}
                </span>
              </p>
            )}
          </label>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {t('Email')}
            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              {...register('email')}
            />
            {errors.email && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                <span className=" font-medium">
                  {t(`${errors.email?.message}`)}
                </span>
              </p>
            )}
          </label>
        </div>
      </div>
      <div className="grid gap-6 mb-6 md:grid-cols-2">
        <button
          type="button"
          className="w-full text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          onClick={onCancel}
        >
          {t('Cancel')}
        </button>
        <button
          type="submit"
          className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        >
          {t('Save')}
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
