import React from 'react';
import { Table as FlowbiteTable } from 'flowbite-react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { v4 as uuidv4 } from 'uuid';

type Props<T> = {
  data: T[];
  columns: any[];
  showFooter?: boolean;
};

const Table = <T extends object>(props: Props<T>) => {
  const { data, columns, showFooter } = props;
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <FlowbiteTable hoverable>
      <FlowbiteTable.Head>
        {table.getHeaderGroups().map((headerGroup) => (
          <React.Fragment key={uuidv4()}>
            {headerGroup.headers.map((header) => (
              <FlowbiteTable.HeadCell key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </FlowbiteTable.HeadCell>
            ))}
          </React.Fragment>
        ))}
      </FlowbiteTable.Head>

      <FlowbiteTable.Body className="divide-y">
        {table.getRowModel().rows.map((row) => (
          <FlowbiteTable.Row
            key={row.id}
            className="bg-white dark:border-gray-700 dark:bg-gray-800"
          >
            {row.getVisibleCells().map((cell) => (
              <FlowbiteTable.Cell
                key={cell.id}
                className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </FlowbiteTable.Cell>
            ))}
          </FlowbiteTable.Row>
        ))}
      </FlowbiteTable.Body>
      {showFooter ? (
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <FlowbiteTable.Row key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <FlowbiteTable.HeadCell key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </FlowbiteTable.HeadCell>
              ))}
            </FlowbiteTable.Row>
          ))}
        </tfoot>
      ) : null}
    </FlowbiteTable>
  );
};

Table.defaultProps = {
  showFooter: false,
};

export default Table;
