import { Breadcrumb } from 'flowbite-react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../app/hooks';
import PackageConfirmation from './PackageConfirmation';
import PackageInformation from './PackageInformation';
import PackageOption from './PackageOption';
import { ITEMS_ROUTE } from '../../routes';

const getStep = (step: number) => {
  switch (step) {
    case 1: {
      return <PackageInformation />;
    }
    case 2: {
      return <PackageOption />;
    }
    case 3: {
      return <PackageConfirmation />;
    }
    default: {
      return null;
    }
  }
};

const Pack = () => {
  const {
    step,
    package: { items },
  } = useAppSelector((state) => state.packing);

  const { t } = useTranslation();

  if (items === undefined || items.length === 0)
    return <Navigate to={{ pathname: `/${ITEMS_ROUTE}` }} />;

  return (
    <section className="flex-grow bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-auto lg:py-8">
        <div className="mb-4 w-full rounded-lg bg-white shadow dark:border dark:border-gray-700  dark:bg-gray-800 md:mt-0 xl:p-0">
          <Breadcrumb
            aria-label="Solid background breadcrumb example"
            className="rounded-lg bg-gray-50 py-3 px-5 dark:bg-gray-900"
          >
            <Breadcrumb.Item>
              <span className="text-gray-900 dark:text-white">
                {t('Current Package')}
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="text-gray-900 dark:text-white">Statement</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span
                className={
                  step > 1 ? 'text-gray-900 dark:text-white' : undefined
                }
              >
                {t('Package Options')}
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span
                className={
                  step > 2 ? 'text-gray-900 dark:text-white' : undefined
                }
              >
                {t('Confirmation')}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {getStep(step)}
      </div>
    </section>
  );
};

export default Pack;
